import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';

const SinglePage = (props) => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar /> 
            </section>
            {/* <!-- ends: .intro-area --> */}
            <section className="event-details-intro p-top-110 p-bottom-110 bgimage biz_overlay overlay--dark2">
                <div className="bg_image_holder">
                    <img src="./assets/img/event-details.jpg" alt="" />
                </div>
                <div className="container content_above">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                        <span className="subtext">{props.pageTitle.category}</span>
                        <h1 className="color-light">{props.pageTitle.title}</h1>
                        <ul className="countdown" />
                        </div>
                    </div>
                </div>
            </section>                            
        </Fragment>       
                      
    )   
}
export default SinglePage;
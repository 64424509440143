import React from 'react';
import { IconBoxFour } from '../element/iconBox/iconBox';
const ServiceIcon4 = () => {
    return (
        <section className="section-bg p-top-100 p-bottom-40">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-bottom-50">
                            <div className="divider text-center">
                                <h1>Our Services Include</h1>
                                <p className="mx-auto"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <IconBoxFour />
        </section>
    )
}

export default ServiceIcon4;
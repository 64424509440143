import React from 'react';
import {NavLink} from 'react-router-dom';
import FooterItem from './footerItem';
const noAction = (e) => {
    e.preventDefault();
}

const FooterCurvedLight = () => {
    return (
        <footer className="footer6 footer--light-gradient">
            <div className="footer__big">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="widget text_widget">
                                <img className="footer_logo" src="./assets/img/logoAnthea.png" alt="logo" style={{height: '80px'}} />
                                <p>
                                    <a href="mailto:info@antheapro.com" className="mail">Info@antheapro.com</a>
                                    <span className="address">Telford, PA</span>
                                </p>
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}

                        <FooterItem />

                    </div>
                </div>
            </div>{/*<!-- ends: footer__big -->*/}
            <div className="footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer__bottom-content">
                                <p>&copy; 2019 Anthea, LLC. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> {/*<!-- ends: footer__small -->*/}
        </footer>
    )
}
export default FooterCurvedLight;

import React from 'react';

const TestimonialFourCon = (props) => {
    const {img, name, content} = props;
    return (
        <div className="carousel-single text-center">
            <img src={img} alt="" className="rounded-circle" />
            <p className="author-spec"><strong>{name},</strong> <span>Aazztech</span></p>
            <div className="author-text">
                <p>{content}</p>
            </div>
        </div> 
        // <!-- end: .carousel-single -->
    )
}
export default TestimonialFourCon;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import Map1 from '../../content/element/map/map-1';
import Map2 from '../../content/element/map/map-2';
import Map3 from '../../content/element/map/index';


const Maps = (props) => {

      return (
        <Fragment>

            <Header pageTitle="Maps" />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Map - 1</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Map1 />
                {/* Map - 1 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Map - 2</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Map2 styles={{height: '400px', overflow: 'hidden', position: 'relative'}} />
                </div>
                {/* Map - 2 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Map - 3</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Map3 styles={{height: '400px', overflow: 'hidden', position: 'relative'}} />
                {/* Map - 3 */}

            <Footer />
        </Fragment>
      );
}

export default Maps
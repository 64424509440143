import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const IconBox = (props) => {

      return (
        <Fragment>

        <Header pageTitle="Lists" />
        <div className="p-bottom-110">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Arrow List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <ul className="arrow--list1">
                    <li className="list-item arrow-list1 color-dark"> Purus euhy dolor tempor fringilla</li>
                    <li className="list-item arrow-list1 color-dark"> Curabitur ullam corper wegete kyel</li>
                    <li className="list-item arrow-list1 color-dark"> Quisque euismd pellents massa</li>
                    <li className="list-item arrow-list1 color-dark"> Purus euhy dolor tempor fringilla</li>
                  </ul>{/* ends: .arrow--list1 */}
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-4 col-md-6">
                  <ul className="arrow--list2">
                    <li className="list-item arrow-list2 d-flex align-items-center"><span><i className="la la-angle-right" /></span> Professional delivers solutions</li>
                    <li className="list-item arrow-list2 d-flex align-items-center"><span><i className="la la-angle-right" /></span> Business human capital research</li>
                    <li className="list-item arrow-list2 d-flex align-items-center"><span><i className="la la-angle-right" /></span> Services complex problems bringing</li>
                    <li className="list-item arrow-list2 d-flex align-items-center"><span><i className="la la-angle-right" /></span> Strategy works with senior executives</li>
                  </ul>{/* ends: .arrow--list2 */}
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-4 col-md-6">
                  <ul className="arrow--list3">
                    <li className="list-item arrow-list3"><span><i className="la la-angle-right" /></span> Develop a sustainable growth</li>
                    <li className="list-item arrow-list3"><span><i className="la la-angle-right" /></span> Business human capital research</li>
                    <li className="list-item arrow-list3"><span><i className="la la-angle-right" /></span> Strategy works with executives</li>
                  </ul>{/* ends: .arrow--list3 */}
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-4 col-md-6">
                  <ul className="arrow--list4">
                    <li className="list-item arrow-list4"> Professional delivers solutions</li>
                    <li className="list-item arrow-list4"> Business human capital research</li>
                    <li className="list-item arrow-list4"> Services complex problems bringing</li>
                    <li className="list-item arrow-list4"> Strategy works with senior executives</li>
                  </ul>{/* ends: .arrow--list4 */}
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-4 col-md-6">
                  <ul className="arrow--list2">
                    <li className="list-item arrow-list5 d-flex align-content-center color-dark"><span><i className="la la-angle-right" /></span> Professional delivers solutions</li>
                    <li className="list-item arrow-list5 d-flex align-content-center color-dark"><span><i className="la la-angle-right" /></span> Business human capital research</li>
                    <li className="list-item arrow-list5 d-flex align-content-center color-dark"><span><i className="la la-angle-right" /></span> Services complex problems bringing</li>
                    <li className="list-item arrow-list5 d-flex align-content-center color-dark"><span><i className="la la-angle-right" /></span> Strategy works with senior executives</li>
                  </ul>{/* ends: .arrow--list2 */}
                </div>{/* ends: .col-lg-4 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Content List With Icon</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <ul className="content-list--one">
                    <li className="content-list d-flex">
                      <div className="icon color-secondary"><i className="la la-bar-chart" /></div>
                      <div className="contents">
                        <h6 className="m-bottom-10">Increase Sales</h6>
                        <p>Claritas est etiam pro cessus dynamicus, quisequ mutationem consue tudium dynamicus.</p>
                      </div>
                    </li>
                    <li className="content-list d-flex">
                      <div className="icon color-secondary"><i className="la la-area-chart" /></div>
                      <div className="contents">
                        <h6 className="m-bottom-10">Real Time Analysis</h6>
                        <p>Claritas est etiam pro cessus dynamicus, quisequ mutationem consue tudium dynamicus.</p>
                      </div>
                    </li>
                  </ul>{/* ends: .content-list--one */}
                </div>{/* ends: .col-lg-6 */}
                <div className="col-lg-6 col-md-6">
                  <ul className="icon-dark content-list--two">
                    <li className="content-list d-flex">
                      <div className="icon icon-circle"><span><i className="la la-cog" /></span></div>
                      <div className="contents">
                        <h6 className="color-dark">Reliability</h6>
                        <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes.</p>
                      </div>
                    </li>
                    <li className="content-list d-flex">
                      <div className="icon icon-circle"><span><i className="la la-thumbs-up" /></span></div>
                      <div className="contents">
                        <h6 className="color-dark">Industry Experience</h6>
                        <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes.</p>
                      </div>
                    </li>
                  </ul>{/* ends: .content-list--two */}
                </div>{/* ends: .col-lg-6 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Text List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <ul className="text--list1">
                    <li className="text-list">
                      <h6>Increase Sales</h6>
                      <p>Claritas est etiam pro cessus dynamicus, quisequ mutationem consue tudium dynamicus tiam pro cessus.</p>
                    </li>
                    <li className="text-list">
                      <h6>Real Time Analysis</h6>
                      <p>Claritas est etiam pro cessus dynamicus, quisequ mutationem consue tudium dynamicus tiam pro cessus.</p>
                    </li>
                  </ul>{/* ends: .text--list1 */}
                </div>{/* ends: .col-lg-6 */}
                <div className="col-lg-6 col-md-6">
                  <ul className="text--list2">
                    <li className="text-list">
                      <span className="sub-text color-secondary">2010 - 2014</span>
                      <h6>BBA Erasmus University</h6>
                      <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                    </li>
                    <li className="text-list">
                      <span className="sub-text color-secondary">2015-2016</span>
                      <h6>MBA Erasmus University</h6>
                      <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                    </li>
                  </ul>{/* ends: .text--list2 */}
                </div>{/* ends: .col-lg-6 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Image List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <ul className="image--list">
                    <li className="image-list d-flex">
                      <div className="img"><img src="img/list1.jpg" alt="" /></div>
                      <div className="contents">
                        <div className="heading">
                          <h6>Jupiter Jhones</h6>
                          <span className="sub-text">Dec 08, 2018</span>
                        </div>
                        <p>Business and Finance Sequitur mutatin was onem consue ktudium. Investiga tiones demonstr aver unt lectores legere me lius quod kqua legunt saepius.</p>
                      </div>
                    </li>
                    <li className="image-list d-flex">
                      <div className="img"><img src="img/list2.jpg" alt="" /></div>
                      <div className="contents">
                        <div className="heading">
                          <h6>Jeff Collins</h6>
                          <span className="sub-text">Feb 12, 2018</span>
                        </div>
                        <p>Business and Finance Sequitur mutatin was onem consue ktudium. Investiga tiones demonstr aver unt lectores legere me lius quod kqua legunt saepius.</p>
                      </div>
                    </li>
                  </ul>{/* ends: .image--list */}
                </div>
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Bullet List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <ul className="bullet--list1">
                    <li className="bullet_list"><strong className="color-dark">Professional -</strong> Delivers solutions that help drive</li>
                    <li className="bullet_list"><strong className="color-dark">Business -</strong> Human capital research analytics</li>
                    <li className="bullet_list"><strong className="color-dark">Services -</strong> Complex problems bringing an approach</li>
                    <li className="bullet_list"><strong className="color-dark">Strategy -</strong> Works with senior executives to help them</li>
                  </ul>{/* ends: .bullet--list1 */}
                </div>{/* ends: .col-lg-6 */}
                <div className="col-lg-6 col-md-6 m-bottom-50">
                  <ul className="bullet--list2">
                    <li className="bullet_list">Build next-generation web applications with a focus on the client</li>
                    <li className="bullet_list">Redesign UI’s, implement new UI’s, and pick up Java as necessary.</li>
                    <li className="bullet_list">Explore and design dynamic compelling consumer experiences.</li>
                    <li className="bullet_list">Design and build scalable framework for web applications.</li>
                  </ul>{/* ends: .bullet--list2 */}
                </div>{/* ends: .col-lg-6 */}
                <div className="col-lg-6 col-md-6">
                  <ul className="bullet--list3">
                    <li className="bullet_list bullet_list--three d-flex flex-column"><strong className="color-dark m-bottom-10">Improve sales and operations planning</strong>
                      <p>Ketiam pro cessus dynamicus, qui sequitur mutationem consu etudium. Sed imperdiet, ante in porta ultrices.</p>
                    </li>
                    <li className="bullet_list bullet_list--three d-flex flex-column"><strong className="color-dark m-bottom-10">Improve sales and operations planning</strong>
                      <p>Ketiam pro cessus dynamicus, qui sequitur mutationem consu etudium. Sed imperdiet, ante in porta ultrices.</p>
                    </li>
                    <li className="bullet_list bullet_list--three d-flex flex-column"><strong className="color-dark m-bottom-10">Improve sales and operations planning</strong>
                      <p>Ketiam pro cessus dynamicus, qui sequitur mutationem consu etudium. Sed imperdiet, ante in porta ultrices.</p>
                    </li>
                  </ul>{/* ends: .bullet--list3 */}
                </div>{/* ends: .col-lg-6 */}
              </div>
            </div>
          </section>{/* ends: .col-lg-6 */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Check List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <ul className="check-list--one">
                    <li className="list-item checklist icon-secondary">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      Purus euhy dolor tempor fringilla quam
                    </li>
                    <li className="list-item checklist icon-secondary">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      Curabitur ullam corper wegete
                    </li>
                    <li className="list-item checklist icon-secondary">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      Quisque euismd pellents massa conuat
                    </li>
                    <li className="list-item checklist icon-secondary">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      Quisque euismod ellentesque massa
                    </li>
                  </ul>{/* ends .check-list--one */}
                </div>{/* ends: .col-lg-6 */}
                <div className="col-lg-6 col-md-6">
                  <ul className="check-list--three">
                    <li className="list-item checklist-three ">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      <span>Purus euhy dolor tempor fringilla quam dolor</span>
                    </li>
                    <li className="list-item checklist-three ">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      <span>Curabitur ullam corper ultricies wegete kyel</span>
                    </li>
                    <li className="list-item checklist-three ">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      <span>Quisque euismd pellents massa conuat shy</span>
                    </li>
                    <li className="list-item checklist-three ">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      <span>Quisque euismod ellentesque massa</span>
                    </li>
                  </ul>{/* ends: .check-list--three */}
                </div>{/* ends: .col-lg-6 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Icon List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <ul className="icon-list--one">
                    <li className="list-item icon-list"><span className="color-secondary"><i className="la la-calendar-check-o" /></span> <strong>Date:</strong> Wednesday, 20 May - Friday, 30 May, 2018</li>
                    <li className="list-item icon-list"><span className="color-secondary"><i className="la la-clock-o" /></span> <strong>Time:</strong> 8:00 AM - 5:00 PM</li>
                    <li className="list-item icon-list"><span className="color-secondary"><i className="la la-map-marker" /></span> <strong>Venue:</strong> Wst. Conference Center, San Francisco, CA</li>
                    <li className="list-item icon-list"><span className="color-secondary"><i className="la la-mobile" /></span> <strong>Contact:</strong> 800 567.890.576</li>
                  </ul>{/* ends .icon-list--one */}
                </div>{/* ends: .col-lg-5 */}
                <div className="col-lg-4 col-md-6">
                  <ul className="icon-list--two">
                    <li className="list-item icon-list"><span className="color-primary"><i className="la la-calendar-check-o" /></span> Wednesday, 20 May - Friday, 30 May, 2018</li>
                    <li className="list-item icon-list"><span className="color-primary"><i className="la la-clock-o" /></span> 8:00 AM - 5:00 PM</li>
                    <li className="list-item icon-list"><span className="color-primary"><i className="la la-map-marker" /></span> Wst. Conference Center, San Francisco, CA</li>
                    <li className="list-item icon-list"><span className="color-primary"><i className="la la-mobile" /></span> 800 567.890.576</li>
                  </ul>{/* ends .icon-list--one */}
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-3 col-md-6">
                  <ul className="icon-list--three">
                    <li className="icon-list2 d-flex">
                      <div className="icon"><i className="la la-headphones" /></div>
                      <div className="contents">
                        <h6>800 567.890.576</h6>
                        <span className="sub-text">Mon-Sat 8am - 18pm</span>
                      </div>
                    </li>
                    <li className="icon-list2 d-flex">
                      <div className="icon"><i className="la la-envelope" /></div>
                      <div className="contents">
                        <h6>support@Tejarat.com</h6>
                        <span className="sub-text">Free enquiry</span>
                      </div>
                    </li>
                    <li className="icon-list2 d-flex">
                      <div className="icon"><i className="la la-map-marker" /></div>
                      <div className="contents">
                        <h6>Melbourne, Australia</h6>
                        <span className="sub-text">95 South Park Avenue</span>
                      </div>
                    </li>
                  </ul>{/* ends .iconListThree */}
                </div>{/* ends: .col-lg-3 */}
                <div className="col-lg-5 col-md-6">
                  <ul className="icon-list--four">
                    <li className="list-item icon-list"><span className="color-dark"><i className="la la-calendar-check-o" /></span> <strong>Date:</strong> Wednesday, 20 May - Friday, 30 May, 2018</li>
                    <li className="list-item icon-list"><span className="color-dark"><i className="la la-clock-o" /></span> <strong>Time:</strong> 8:00 AM - 5:00 PM</li>
                    <li className="list-item icon-list"><span className="color-dark"><i className="la la-map-marker" /></span> <strong>Venue:</strong> Wst. Conference Center, San Francisco, CA</li>
                    <li className="list-item icon-list"><span className="color-dark"><i className="la la-mobile" /></span> <strong>Contact:</strong> 800 567.890.576</li>
                  </ul>{/* ends .icon-list--four*/}
                </div>{/* ends: .col-lg-5 */}
                <div className="col-lg-4 col-md-6">
                  <ul className="icon-list--five">
                    <li className="list-item icon-list"><span className="color-dark"><i className="la la-calendar-check-o" /></span> Wednesday, 20 May - Friday, 30 May, 2018</li>
                    <li className="list-item icon-list"><span className="color-dark"><i className="la la-clock-o" /></span> 8:00 AM - 5:00 PM</li>
                    <li className="list-item icon-list"><span className="color-dark"><i className="la la-map-marker" /></span> Wst. Conference Center, San Francisco, CA</li>
                    <li className="list-item icon-list"><span className="color-dark"><i className="la la-mobile" /></span> 800 567.890.576</li>
                  </ul>{/* ends .icon-list--five*/}
                </div>{/* ends: .col-lg-4 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Number List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <ol className="number-list number--list1">
                    <li className="numbers-decimal">Professional delivers solutions</li>
                    <li className="numbers-decimal">Business human capital research</li>
                    <li className="numbers-decimal">Services complex problems bringing</li>
                    <li className="numbers-decimal">Strategy works with senior executives</li>
                  </ol>{/* ends .number-list*/}
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-4 col-md-6">
                  <ol className="number-list number--list2">
                    <li className="numbers-decimal-zero">Professional delivers solutions</li>
                    <li className="numbers-decimal-zero">Business human capital research</li>
                    <li className="numbers-decimal-zero">Services complex problems bringing</li>
                    <li className="numbers-decimal-zero">Strategy works with senior executives</li>
                  </ol>{/* ends .number-list*/}
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-4 col-md-6">
                  <ol className="number-list number--list3">
                    <li className="numbers-roman">Professional delivers solutions</li>
                    <li className="numbers-roman">Business human capital research</li>
                    <li className="numbers-roman">Services complex problems bringing</li>
                    <li className="numbers-roman">Strategy works with senior executives</li>
                  </ol>{/* ends .number-list*/}
                </div>{/* ends: .col-lg-4 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Check List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types bg-secondary">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 m-bottom-30">
                  <ul>
                    <li className="list-item checklist checklist-light color-primary">
                      <img src="img/svg/check-circle.svg" alt="" className="svg" />
                      <span>Purus euhy dolor tempor fringilla quam dolor</span>
                    </li>
                    <li className="list-item checklist checklist-light color-primary">
                      <img src="img/svg/check-circle.svg" alt="" className="svg" />
                      <span>Curabitur ullam corper ultricies wegete kyel</span>
                    </li>
                    <li className="list-item checklist checklist-light color-primary">
                      <img src="img/svg/check-circle.svg" alt="" className="svg" />
                      <span>Quisque euismd pellents massa conuat shy</span>
                    </li>
                    <li className="list-item checklist checklist-light color-primary">
                      <img src="img/svg/check-circle.svg" alt="" className="svg" />
                      <span>Quisque euismod ellentesque massa cnsequat</span>
                    </li>
                  </ul>
                </div>{/* ends: .col-lg-6 */}
                <div className="col-lg-6">
                  <ul className="check-list--three">
                    <li className="list-item checklist-three checklist-light">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      <span>Purus euhy dolor tempor fringilla quam dolor</span>
                    </li>
                    <li className="list-item checklist-three checklist-light">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      <span>Curabitur ullam corper ultricies wegete kyel</span>
                    </li>
                    <li className="list-item checklist-three checklist-light">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      <span>Quisque euismd pellents massa conuat shy</span>
                    </li>
                    <li className="list-item checklist-three checklist-light">
                      <img src="img/svg/checked.svg" alt="" className="svg" />
                      <span>Quisque euismod ellentesque massa cnsequat</span>
                    </li>
                  </ul>{/* ends: .check-list--three */}
                </div>{/* ends: .col-lg-6 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Icon List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types bg-dark">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 m-bottom-30">
                  <ul>
                    <li className="list-item icon-list list-light"><span className="color-secondary"><i className="la la-calendar-check-o" /></span> <strong>Date:</strong> Wednesday, 20 May - Friday, 30 May, 2018</li>
                    <li className="list-item icon-list list-light"><span className="color-secondary"><i className="la la-clock-o" /></span> <strong>Time:</strong> 8:00 AM - 5:00 PM</li>
                    <li className="list-item icon-list list-light"><span className="color-secondary"><i className="la la-map-marker" /></span> <strong>Venue:</strong> Wst. Conference Center, San Francisco, CA</li>
                    <li className="list-item icon-list list-light"><span className="color-secondary"><i className="la la-mobile" /></span> <strong>Contact:</strong> 800 567.890.576</li>
                  </ul>
                </div>{/* ends: .col-lg-5 */}
                <div className="col-lg-4 m-bottom-30">
                  <ul>
                    <li className="list-item icon-list list-light"><span className="color-light"><i className="la la-calendar-check-o" /></span> Wednesday, 20 May - Friday, 30 May, 2018</li>
                    <li className="list-item icon-list list-light"><span className="color-light"><i className="la la-clock-o" /></span> 8:00 AM - 5:00 PM</li>
                    <li className="list-item icon-list list-light"><span className="color-light"><i className="la la-map-marker" /></span> Wst. Conference Center, San Francisco, CA</li>
                    <li className="list-item icon-list list-light"><span className="color-light"><i className="la la-mobile" /></span> 800 567.890.576</li>
                  </ul>
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-3 m-bottom-30">
                  <ul>
                    <li className="icon-list2 list-light d-flex">
                      <div className="icon"><i className="la la-headphones" /></div>
                      <div className="contents">
                        <h6>800 567.890.576</h6>
                        <span className="sub-text">Mon-Sat 8am - 18pm</span>
                      </div>
                    </li>
                    <li className="icon-list2 list-light d-flex">
                      <div className="icon"><i className="la la-envelope" /></div>
                      <div className="contents">
                        <h6>support@Tejarat.com</h6>
                        <span className="sub-text">Free enquiry</span>
                      </div>
                    </li>
                    <li className="icon-list2 list-light d-flex">
                      <div className="icon"><i className="la la-map-marker" /></div>
                      <div className="contents">
                        <h6>Melbourne, Australia</h6>
                        <span className="sub-text">95 South Park Avenue</span>
                      </div>
                    </li>
                  </ul>
                </div>{/* ends: .col-lg-3 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>Number List</h3>
                </div>
              </div>
            </div>
          </div>
          <section className="list-types bg-image">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 m-bottom-30">
                  <ol className="number-list">
                    <li className="numbers-decimal list-light">Professional delivers solutions</li>
                    <li className="numbers-decimal list-light">Business human capital research</li>
                    <li className="numbers-decimal list-light">Services complex problems bringing</li>
                    <li className="numbers-decimal list-light">Strategy works with senior executives</li>
                  </ol>
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-4 m-bottom-30">
                  <ol className="number-list">
                    <li className="numbers-decimal-zero list-light">Professional delivers solutions</li>
                    <li className="numbers-decimal-zero list-light">Business human capital research</li>
                    <li className="numbers-decimal-zero list-light">Services complex problems bringing</li>
                    <li className="numbers-decimal-zero list-light">Strategy works with senior executives</li>
                  </ol>
                </div>{/* ends: .col-lg-4 */}
                <div className="col-lg-4m-bottom-30">
                  <ol className="number-list">
                    <li className="numbers-roman list-light">Professional delivers solutions</li>
                    <li className="numbers-roman list-light">Business human capital research</li>
                    <li className="numbers-roman list-light">Services complex problems bringing</li>
                    <li className="numbers-roman list-light">Strategy works with senior executives</li>
                  </ol>
                </div>{/* ends: .col-lg-4 */}
              </div>
            </div>
          </section>{/* ends: .list-types */}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="showcase showcase--title1">
                  <h3>List Inline</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="list-inline-wrapper p-top-80 p-bottom-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ul className="icon-list--three d-flex list--inline">
                    <li className="d-flex align-items-center">
                      <div className="icon"><span><i className="la la-headphones" /></span></div>
                      <div className="contents">
                        <h6>800 567.890.576</h6>
                        <span className="sub-text">Mon-Sat 8am - 18pm</span>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <div className="icon"><span><i className="la la-envelope" /></span></div>
                      <div className="contents">
                        <h6>support@Tejarat.com</h6>
                        <span className="sub-text">Free enquiry</span>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <div className="icon"><span><i className="la la-map-marker" /></span></div>
                      <div className="contents">
                        <h6>Melbourne, Australia</h6>
                        <span className="sub-text">95 South Park Avenue</span>
                      </div>
                    </li>
                  </ul>{/* ends: .icon-list--three */}
                </div>
              </div>
            </div>
          </div>{/* ends: .list-inline */}
        </div>
        <Footer />
        </Fragment>
      );
}

export default IconBox
import React, { Component } from 'react';
import NewsCon from '../../content/news/news';
import {connect} from 'react-redux';
class News extends Component {
    render() {

        const {blog, name, pb} = this.props;
        return (

            <section className={`news-area p-top-100 ${pb ? pb : 'p-bottom-60'}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 section-title">
                            <h2>{name}</h2>
                            <p>Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet consectetur adipisicing dolore.</p>
                        </div>

                        <div className="post--card-four col-lg-12">
                            <div className="row">
                            {
                                Object.values(blog).slice(0, 3).map((value, key) => {
                                    return <NewsCon
                                    key={value.id}
                                    id={value.id}
                                    img={value.imgSrc}
                                    content={value.content}
                                    title={value.title}
                                    date={value.date}
                                    industry={value.industry}
                                     />
                                })
                            }
                            </div>{/*<!-- ends: .row -->*/}
                        </div>{/*<!-- ends: .post--card1 -->*/}

                    </div>
                </div>
            </section>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        blog : state.blog
    }
}
export default connect(mapStateToProps)(News);
import React, { Component } from 'react'
import EventDetails from './event-details';
import { WidgetFrom } from '../../content/element/form/registration';
export default class Event extends Component {
    render() {
        const {content} = this.props;
        return (
            <section className="event-details-wrapper p-top-100 p-bottom-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <EventDetails {...content} />
                        </div>{/*<!-- ends: .col-lg-8 -->*/}
                        <div className="col-lg-4">
                            <div className="sidebar event-sidebar">
                                <div className="widget-wrapper">
                                    <div className="widget-shadow">
                                        <div className="widget-header">
                                            <h4 className="widget-title">Registration Now</h4>
                                            <WidgetFrom />                    
                                        </div>
                                    </div>
                                </div>{/* ends: .widget-single */}
                            </div>{/* ends: .sidebar */}
                        </div>{/* ends: .col-lg-4 */}

                    </div>
                </div>
            </section>
        )
    }
}

import React, { Component, Fragment } from 'react'

import {ProjectInfo} from '../../content/project/project-info';
import {ProjectChalange} from '../../content/project/project-chalange';
import {ProjectResult} from './project-result';
export default class Project extends Component {
    render() {
        const {content, project} = this.props;
        return (
            <Fragment>
                <section className="project-single p-top-100 p-bottom-70 border-bottom">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="project-gallery m-bottom-40 row">
                                    {
                                        Object.values(project).slice(0,  6).map((value, key) => {
                                            return (
                                                <div className="col-lg-4 col-sm-6" key={key}>
                                                    <figure>
                                                        <img src={value.imgSrc} alt="" />
                                                    </figure>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>{/*<!-- ends: .col-lg-8 -->*/}

                            <div className="col-lg-12">
                                <ProjectInfo content={content}/>
                            </div>
                        </div>
                    </div>
                </section>
                <ProjectChalange />
                <ProjectResult />
            </Fragment>
        )
    }
}

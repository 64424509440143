import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import { PricingOne } from '../content/element/pricing/pricing';
import {AccordianOne} from '../content/element/accordians/accordians';


const noAction = e => e.preventDefault();
const Project = () => {
    return (
        <Fragment>

            <Header pageTitle='Pricing Plans'/>

            <section className="p-top-100 p-bottom-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-5">
                                <div className="divider text-center">
                                    <h1 className="color-dark">Choose Options</h1>
                                    <p className="mx-auto">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius Clarias estre etiam pro cessus dynamicus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PricingOne />
            </section>

            <section className="p-top-100 p-bottom-110 section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="m-bottom-65">
                                <div className="divider text-center">
                                    <h1>FAQs About Pricing</h1>
                                    <p className="mx-auto">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius Clarias estre etiam pro cessus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-styles accordion--two">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <AccordianOne />
                            </div>
                            <div className="col-lg-4 offset-lg-1">
                                <div className="form-box">
                                    <h4 className="color-dark">Have Any Question?</h4>
                                    <p className="m-0">Investig ationes demons trave rurnt lectores legere lius quod thlegunt saeps claritas the Investig ationes demon trave rungt.</p>
                                    <form action="#" className="mt-5">
                                        <input type="text" className="form-control mb-4 border-0" placeholder="Your Name" />
                                        <input type="email" className="form-control mb-4 border-0" placeholder="Your Email" />
                                        <input type="text" className="form-control mb-4 border-0" placeholder="Your Question" />
                                        <button onClick={noAction} className="btn btn-primary mt-3">Submit Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>{/*<!-- ends: .section-padding -->*/}

            <Footer />
        </Fragment>
    )
}

export default Project;
import React, {Fragment, Component} from 'react';

import Header from '../layout/header/single-product';
import Footer from '../layout/footer/footer-5';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import { Tab6 } from '../content/element/tab/tab-2';
import Card from '../container/element/card/related-product';
import { Cart } from '../../Store/action/cartActions';
import { toast, ToastContainer } from 'react-toastify';

const options = {
    items: 1,
    center: true,
    loop: false,
    nav: false,
    dots: false,
    URLhashListener: true,
    startPosition: 0
};
class SingleProduct extends Component {
    _isMounted = false;
    state = {
        product: this.props.cart.product,
        quantity: 1
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        setInterval(() => {
            var match = JSON.stringify({product: this.state.product});
            var match2 = localStorage.getItem('cart')
            if(match !== match2) {
                if(this._isMounted) {
                    this.setState({
                        product: JSON.parse(match2).product
                    }, () => this.props.addToCart({product: this.state.product}))
                }
            }

        }, 100)

       const array = this.state.product;
       // add to cart
        const handalebar = (event) => {
            event.preventDefault();
            const product_code = event.target.getAttribute('id');

            const { product } = this.props;
            const code_item = Object.values(product).filter((item) => {
                return item.id === product_code;
            });
            const filter = array.filter((item2) => {
                return parseInt(item2[0].id) === parseInt(code_item[0].id)
            })

            if(filter.length === 0){
                array.push(code_item);
                this.setState(
                    { product: [...array] },
                    () => this.props.addToCart(this.state)
                )
                toast.success("Item Added to Cart");
            } else {
                toast.error("Item already exists in Cart");
            }
        }

        const id = this.props.match.params.id;
        const filter = Object.values(this.props.product).filter((value) => {
            return value.id === id;
        })
        const changeQuantity = (e) => {
            this.setState({
                quantity: e.target.value
            })
        }
        const pluse = () => {
            if(this.state.quantity <= 20) {
                this.setState({
                    quantity: this.state.quantity + 1
                })
            }
        }
        const minus = () => {
            if(this.state.quantity > 1) {
                this.setState({
                    quantity: this.state.quantity - 1

                })
            }
        }

        return (
            <Fragment>

            <ToastContainer />
                <Header pageTitle={filter[0].title}/>
                <section className="product-details p-top-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 offset-lg-0 col-md-8 offset-md-2 margin-md-60">
                                <div className="product-gallery">
                                    <div className="gallery-image-view">
                                        <OwlCarousel options={ options } className="image-view-carousel owl-carousel">
                                            {
                                                filter[0].view.map((value, key) => {
                                                    return (
                                                        <div className="carousel-single" key={key+1} data-hash={"image"+key}>
                                                            <figure><img src={value} alt="" /></figure>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </OwlCarousel>{/*<!-- ends: .image-view-carousel -->*/}
                                        <div className="carousel-thumbs">
                                            <ul className="d-flex justify-content-center">
                                            {
                                                filter[0].view.map((value, key) => {
                                                    return (
                                                        <li key={key+1}><a href={"#image"+key}><img src={value} alt="" /></a></li>
                                                    )
                                                })
                                            }
                                            </ul>
                                        </div>{/*<!-- ends: .carousel-thumb -->*/}
                                    </div>{/*<!-- ends: .gallery-image-view -->*/}
                                </div>
                            </div>{/*<!-- ends: .col-lg-5 -->*/}

                            <div className="col-lg-6 offset-lg-1">
                                <div className="product-info">
                                    <h4>{filter[0].title}</h4>
                                    <span className="product-price">{"$"+filter[0].price}</span>
                                    <div className="product-ratings d-flex">
                                        <p>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="far fa-star"></span>
                                        </p>
                                        <span className="total-reviews">(2 Reviews)</span>
                                    </div>
                                    <p>{filter[0].content}</p>
                                    <div className="product-actions">
                                        <form action="#" className="flex-wrap">
                                            <div className="total-item">
                                                <span className="pcount m-right-10" onClick={minus} data-operation="minus">-</span>
                                                <input type="text" value={this.state.quantity} onChange={changeQuantity} id="numberInput" />
                                                <span className="pcount m-left-10" data-operation="plus" onClick={pluse}>+</span>
                                            </div>
                                            <ul className="d-flex">
                                                <li><NavLink to="/at_demo" id={filter[0].id} onClick={handalebar} className="btn btn--rounded btn-primary">Add To Cart</NavLink></li>
                                                <li><NavLink to="/at_demo" className="btn like-btn btn-grey"><i className="la la-heart-o"></i></NavLink></li>
                                            </ul>
                                        </form>
                                    </div>
                                    <div className="product-category d-flex m-bottom-25">
                                        <span>Categories: </span>
                                        <p><NavLink to="/">{filter[0].category}</NavLink></p>
                                    </div>
                                    <div className="product-share d-flex align-items-center">
                                        <span className="m-right-20">Share in social media: </span>
                                        <div>
                                            <div className="social social--colored ">
                                                <ul className="d-flex flex-wrap">
                                                    <li><NavLink to="/" className="facebook"><span className="fab fa-facebook-f"></span></NavLink></li>
                                                    <li><NavLink to="/" className="twitter"><span className="fab fa-twitter"></span></NavLink></li>
                                                    <li><NavLink to="/" className="linkedin"><span className="fab fa-linkedin-in"></span></NavLink></li>
                                                    <li><NavLink to="/" className="gplus"><span className="fab fa-google-plus-g"></span></NavLink></li>
                                                </ul>
                                            </div>{/*<!-- ends: .social -->*/}
                                        </div>
                                    </div>{/*<!-- ends: .event-share -->*/}
                                </div>
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                        </div>
                    </div>
                </section>{/*<!-- ends: .product-details -->     */}
                <Tab6 />
                <section className="related-products m-bottom-80 p-top-80">
                    <div className="m-bottom-40">

                        <div className="divider divider-simple text-center m-bottom-30">
                            <h3>Related Products</h3>
                        </div>
                        <Card category={filter[0].category} />
                    </div>
                </section>
                <Footer />
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        product : state.product,
        cart : state.cart
    }
}

const mapDispatchToProp = dispatch => {
    return {
        addToCart : (prod) => dispatch(Cart(prod))
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(SingleProduct);
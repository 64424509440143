import React, { Fragment } from 'react';

import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Card from '../container/element/card/card-grid-1';


const Project = () => {
    return (
        <Fragment>

            <Header pageTitle='Project Grid One Column'/>
            <section className="project-list-wrapper section-padding section-bg">
                <div className="container">
                    <Card />
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default Project;
import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import Slider1 from '../../layout/header/fullHeight1';
import Slider2 from '../../layout/header/fullHeight-2';
import Slider3 from '../../layout/header/slider-header';
import Slider4 from '../../layout/header/slider-header-2';
import Slider5 from '../../layout/header/slider-header-17';
import Slider6 from '../../layout/header/slider-header-27';
import Slider7 from '../../layout/header/slider-header-31';
import Slider8 from '../../layout/header/content-height';
import Slider9 from '../../layout/header/content-height-1';
import Slider10 from '../../layout/header/content-height-2';
import Slider11 from '../../layout/header/content-height-3';
import Slider12 from '../../layout/header/content-height-4';
import Slider13 from '../../layout/header/content-height-9';


class Sliders extends Component {

    render() {
      return (
        <Fragment>

            <Header pageTitle="Sliders " />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider1 />
            {/* Sliders - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider2 />
            {/* Sliders - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider3 />
            {/* Sliders - 3 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider4 />
            {/* Sliders - 4 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider5 />
            {/* Sliders - 5 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders - 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider6 />
            {/* Sliders - 6 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders - 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider7 />
            {/* Sliders - 7 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders -8</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider8 />
            {/* Sliders - 8 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders -9</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider9 />
            {/* Sliders - 9 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders -10</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider10 />
            {/* Sliders - 10 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders -11</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider11 />
            {/* Sliders - 11 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders -12</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider12 />
            {/* Sliders - 12 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Sliders -13</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Slider13 />
            {/* Sliders - 13 */}

            <Footer />
        </Fragment>
      )
    }
}
export default Sliders

import React from 'react';
import {NavLink} from 'react-router-dom';
const noAction = (e) => {
    e.preventDefault();
}
const Team_four = (props) => {
    const {img, name, designation, id} = props;
    return (
        <div className="col-lg-3 col-sm-6 col-md-6">
            <div className="team-single team-four">
                <img src={img} alt="" />
                <div className="content text-center">
                    <h6><NavLink to={"/team-details"+id}>{name}</NavLink></h6>
                    <span className="subtitle">{designation}</span>
                    <div className="social-basic ">
                        <ul className="d-flex justify-content-center ">
                            <li><NavLink to="/" onClick={noAction} className="facebook"><span className="fab fa-facebook-f"></span></NavLink></li>
                            <li><NavLink to="/" onClick={noAction} className="twitter"><span className="fab fa-twitter"></span></NavLink></li>
                            <li><NavLink to="/" onClick={noAction} className="linkedin"><span className="fab fa-linkedin-in"></span></NavLink></li>
                            <li><NavLink to="/" onClick={noAction} className="gplus"><span className="fab fa-google-plus-g"></span></NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>{/*<!-- ends: .team-single -->*/}
        </div>
    )
}
export default Team_four; 
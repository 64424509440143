import React, { Fragment } from 'react';

import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import TestimoniaL from '../container/testimonial/testimonial-2';

import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
const Testimonial = () => {
    return (
        <Fragment>

            <Header pageTitle='Testimonial'/>
            <section className="section-padding testimonial--card2 section-bg">
                <TestimoniaL />
                <TestimoniaL />
                <TestimoniaL />
                <div className="text-center m-top-30">
                    <NavLink to="/at_demo" onClick={noAction} className="btn btn-primary btn-icon icon-left"><i className="la la-refresh"></i> Load More</NavLink>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default Testimonial;
import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import SocondaryNav from '../layout/navbar/aboutNavItem';
import Card3 from '../container/element/card/card-3';
import Footer from '../layout/footer/footer-5';



const About = () => {
    return (
        <Fragment>

            <Header pageTitle='Our Success Story' />
            <SocondaryNav />
            <section className="section-bg p-top-100 p-bottom-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="m-bottom-50">
                                <div className="divider section-title-simple">
                                    <h2>You will Get Real Results when Working with Us</h2>
                                    <p className="ml-auto mr-auto m-top-5">Investiga tiones demonstr averunt lectres legere me lius quod ii qua legunt saepi klarias est etiam pro cessus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Card3 />
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default About;
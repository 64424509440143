import React, {Fragment} from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footerCurvedLight';

import BlogContent from '../container/blog/blog-full-width';
import {connect} from 'react-redux';

const Index = (props) => {
    const id = props.match.params.id;
    const filter = Object.values(props.blog).filter((value) => {
        return value.id === id;
    })

    return (
        <Fragment>

            <Header pageTitle={filter[0].title}/>
                <BlogContent content={filter[0]} blog={props.blog} />
            <Footer />
        </Fragment>
    )
}
const mapStateToProps = (state, ownProps) => {

    return {
        blog : state.blog
    }
}
export default connect(mapStateToProps)(Index);
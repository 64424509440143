import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
const options = {
    items: 1,
    nav: true,
    navText: ['<button><i class="la la-angle-left"></i></button>', '<button><i class="la la-angle-right"></i></button>'],
    navContainerClass: "nav-circle",
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
};
class Slider extends Component {
    render() {
        const {project} = this.props;        
        return (
            <OwlCarousel options={options} className="image-carousel-one owl-carousel">
                {
                    Object.values(project).slice(6, 8).map((value, key) => {
                        return (
                        <div key={value.id} className="carousel-single">
                            <figure>
                                <img src={value.imgSrc} alt="" />                                
                            </figure>
                        </div>
                        )
                    })
                
                }                    
            </OwlCarousel>
        )
    }
}
export default Slider;
import $ from "jquery";
import React, { Component } from 'react';
import TestimonialTwoCont from '../../content/testimonial/twitter-feeds';
import {connect} from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 1,
    rtl: falsy,
    autoplay: true,
    loop: true,
    nav: true,
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    navContainerClass: "nav-circle nav-circle--lighten",
    dots: false,
    navElement: 'button',
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
}

class TwitterFeeds extends Component {
    render() {
        const {testimonial} = this.props
        return (
            <section className="twitter-feeds bgimage gradient-overlay">
                <div className="bg_image_holder">
                    <img src="./assets/img/sbg3.jpg" alt="" />
                </div>
                <div className="container content_above">
                    <div className="row">
                        <div className="col-lg-12">
                        <OwlCarousel options={options} className="twitter-feeds-carousel owl-carousel">
                           {
                                Object.values(testimonial).map((value, key) => {
                                    return <TestimonialTwoCont
                                    name={value.name}
                                    subtitle={value.subTitle}
                                    content={value.content.split(' ').slice(0, 20).join(' ')}
                                    img={value.imgSrc}
                                    key={key+1}

                                        />
                                })
                            }
                        </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        testimonial : state.testimonial
    }
}
export default connect(mapStateToProps)(TwitterFeeds)

import React from 'react';
import {NavLink} from 'react-router-dom';
const InsightStories = () => {
    return (
        <div className="section-bg p-bottom-120">

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="section_title text-center">
                            <h1>Insight Stories</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab tab--1 section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="tab_nav cardify">
                                <div className="nav flex-column" id="tab_nav1" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link active" id="v-pills-tab1" data-toggle="pill" href="#tab1_content" role="tab" aria-controls="tab1_content" aria-selected="true">Personal Investment Success</a>
                                    <a className="nav-link" id="v-pills-tab2" data-toggle="pill" href="#tab2_content" role="tab" aria-controls="tab2_content" aria-selected="false">Healthcare and Life Sciences</a>
                                    <a className="nav-link" id="v-pills-tab3" data-toggle="pill" href="#tab3_content" role="tab" aria-controls="tab3_content" aria-selected="false">Travel and Transportation</a>
                                    <a className="nav-link" id="v-pills-tab4" data-toggle="pill" href="#tab4_content" role="tab" aria-controls="tab4_content" aria-selected="false">Insurance and Finance</a>
                                    <a className="nav-link" id="v-pills-tab5" data-toggle="pill" href="#tab5_content" role="tab" aria-controls="tab5_content" aria-selected="false">Manufacturing</a>
                                    <a className="nav-link" id="v-pills-tab6" data-toggle="pill" href="#tab6_content" role="tab" aria-controls="tab6_content" aria-selected="false">Property and Consulting</a>
                                    <a className="nav-link" id="v-pills-tab7" data-toggle="pill" href="#tab7_content" role="tab" aria-controls="tab7_content" aria-selected="false">Consulting Services Accelerate</a>
                                    <a className="nav-link" id="v-pills-tab8" data-toggle="pill" href="#tab8_content" role="tab" aria-controls="tab8_content" aria-selected="false">Healthcare and Life Sciences</a>
                                    <a className="nav-link" id="v-pills-tab9" data-toggle="pill" href="#tab9_content" role="tab" aria-controls="tab9_content" aria-selected="false">Travel and Transportation</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="tab-content" id="tabContent1">
                                <div className="tab-pane animated fadeIn show active" id="tab1_content" role="tabpanel" aria-labelledby="tab1_content">
                                    <figure>
                                        <img src="./assets/img/tabimg2.jpg" alt="Images the foolda" />
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="tab-pane animated fadeIn" id="tab2_content" role="tabpanel" aria-labelledby="tab2_content">
                                    <figure>
                                        <img src="./assets/img/tabimg.jpg" alt="Images the foolda" />
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="tab-pane animated fadeIn" id="tab3_content" role="tabpanel" aria-labelledby="tab3_content">
                                    <figure>
                                        <img src="./assets/img/tabimg2.jpg" alt="Images the foolda" />
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="tab-pane animated fadeIn" id="tab4_content" role="tabpanel" aria-labelledby="tab4_content">
                                    <figure>
                                        <img src="./assets/img/tabimg.jpg" alt="Images the foolda" />
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="tab-pane animated fadeIn" id="tab5_content" role="tabpanel" aria-labelledby="tab5_content">
                                    <figure>
                                        <img src="./assets/img/tabimg2.jpg" alt="Images the foolda" />>
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="tab-pane animated fadeIn" id="tab6_content" role="tabpanel" aria-labelledby="tab6_content">
                                    <figure>
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="tab-pane animated fadeIn" id="tab7_content" role="tabpanel" aria-labelledby="tab7_content">
                                    <figure>
                                        <img src="./assets/img/tabimg.jpg" alt="Images the foolda" />
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="tab-pane animated fadeIn" id="tab8_content" role="tabpanel" aria-labelledby="tab8_content">
                                    <figure>
                                        <img src="./assets/img/tabimg2.jpg" alt="Images the foolda" />
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="tab-pane animated fadeIn" id="tab9_content" role="tabpanel" aria-labelledby="tab9_content">
                                    <figure>
                                        <img src="./assets/img/tabimg.jpg" alt="Images the foolda" />
                                        <figcaption>
                                            <h5>Matador Managed Private Cloud in Five Months</h5>
                                            <p>Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph
                                                claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                            <NavLink to='/at_demo'  className="btn btn-primary">read more</NavLink>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/*<!-- ends .tab -->*/}
        </div>
    )
}
export default InsightStories;
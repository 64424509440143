import $ from "jquery";
import React from 'react';
import {connect} from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import {CaseItem} from '../../content/case-studies/case-studies';
import InsightStories from './insight-stories';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 3,
    margin: 30,
    rtl: falsy,
    nav: false,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        600:{
            items:2
        },
        992:{
            items:3
        }
    }
};
const Case_studies = (props) => {
    const {project} = props;
    return (
        <section className="case_studies">
            <div className="p-top-100 p-bottom-95 bg-half-gray">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="section_title text-center">
                                <h1>Case Studies</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <OwlCarousel options={options} className="carousel-two owl-carousel">
                                {
                                    Object.values(project).slice(0, 6).map((value, key) => {
                                        const {imgSrc, title, id, content} = value;
                                        return(
                                            <CaseItem
                                                imgSrc={imgSrc}
                                                title={title}
                                                key={id}
                                                content={content}
                                                id={id}
                                            />
                                        )
                                    })
                                }
                            </OwlCarousel>{/*<!-- ends: .owl-carousel -->*/}
                        </div>
                    </div>
                </div>
            </div>
            <InsightStories />

        </section>
    )
}

const mapStateToProps = state => {
    return {
        project : state.project
    }
}
export default connect(mapStateToProps)(Case_studies);
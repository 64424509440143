import React, { Component } from 'react';
import TestimonialsCont from '../../content/testimonial/testimonials-3';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';


const options = {
    items: 3,
    nav: true,
    navText: ['<button><i class="la la-angle-left"></i></button>', '<button><i class="la la-angle-right"></i></button>'],
    navContainerClass: "nav-circle",
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        600:{
            items:1
        },
        992:{
            items:3
        }
    }    
}

class Testimonials extends Component {
    render() {
        const {testimonial} = this.props 
              
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <OwlCarousel options={options} className="testimonial-carousel-three owl-carousel">
                        {
                            Object.values(testimonial).map((value, key) => {                                                                                                        
                                return <TestimonialsCont 
                                name={value.name} 
                                subtitle={value.subTitle} 
                                content={value.content} 
                                img={value.imgSrc}
                                key={key+1}
                                    />
                            })
                        }
                        </OwlCarousel>
                    </div>
                </div>
            </div>        
        )
    }
}
const mapStateToProps = (state) => {
    
    return {
        testimonial : state.testimonial
    }
}
export default connect(mapStateToProps)(Testimonials)

import React from 'react';

export const ProjectInfo = (props) => {
    const {content} = props;
    return (
        <div className="project-info row">
            <div className="col-lg-6 col-md-6">
                <h3 className="m-bottom-20">Project Case Study</h3>
                <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutationem consuetudium. Sed imperdiet, ante in porta ultrices, orci metus consequat lacus, ac viverra magna ectores legere.</p>
                <ul className="arrow--list2">
                    
                    <li className="list-item arrow-list2 d-flex align-items-center"><span><i className="la la-angle-right"></i></span> Business human capital research</li>

                                                
                    <li className="list-item arrow-list2 d-flex align-items-center"><span><i className="la la-angle-right"></i></span> Services complex problems bringing</li>

                                                
                    <li className="list-item arrow-list2 d-flex align-items-center"><span><i className="la la-angle-right"></i></span> Strategy works with senior executives</li>

                </ul>{/*<!-- ends: .arrow--list2 -->*/}
            </div>
            <div className="col-lg-5 offset-lg-1 col-md-6">
                <div className="info-box">
                    <h6>Project quick info:</h6>
                    <ul>
                        <li><span>Project Name: </span> <span>{content.title}</span></li>
                        <li><span>Category: </span> <span>{content.subtitle}</span></li>
                        <li><span>Client: </span> <span>AazzTech Group</span></li>
                        <li><span>Date: </span> <span>26 May 2015</span></li>
                        <li><span>Website: </span> <span>www.aazztech.com</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
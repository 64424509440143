import React, {Fragment, Component} from 'react';
import { NavLink } from 'react-router-dom';
import {LineChart, BarChart, PieChart} from '../chart/line-chart';
import Carousel from '../../../container/element/carousel/address_carousel';
import StarRatingComponent from 'react-star-rating-component';
const noAction = e => e.preventDefault();

export const Tab2 = (props) => {
    return (
        <section className={props.padding ? props.padding : "p-top-80 p-bottom-110"}>

            <div className="tab tab--2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tab_nav2">
                                <ul className="nav justify-content-center" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="info_tab1" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">Anthea Consulting</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="info_tab2" data-toggle="tab" href="#info2" role="tab" aria-controls="info2" aria-selected="false">NanoMarkers<sup>TM</sup> by Anthea</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="info_tab3" data-toggle="tab" href="#info3" role="tab" aria-controls="info3" aria-selected="false">Trumarks<sup>TM</sup></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--end ./container --> */}

                <div className="tab-content tab--content2" id="tabcontent2">
                    <div className="tab-pane animated fadeIn active show" id="info" role="tabpanel" aria-labelledby="info_tab1">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="tab_text_module">
                                        <p className="tab_text_module__subtitle">Trusted Business Consultancy</p>
                                        <h2>25 Years of Industry
                                            Experience</h2>
                                        <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius.
                                            Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium.
                                            Investig ationes demons trave runt lectores legere liusry awquod he legunt saepius
                                            clary.</p>
                                        <button type="button" className="btn btn-primary">
                                            Read More
                                        </button>
                                    </div>
                                </div>

                                <div className="col-lg-6 offset-lg-1">

                                    <div className="video-single">
                                        <figure>
                                            <div className="v_img">
                                                <img src="./assets/img/simg-2.jpg" alt="" className="rounded" />
                                            </div>
                                            <figcaption className="d-flex justify-content-center align-items-center shape-wrapper img-shape-two">
                                                <a href="https://www.youtube.com/watch?v=omaTcIbwt9c" className="video-iframe play-btn play-btn--lg play-btn--primary">
                                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 415.346 415.346" style={{enableBackground:'new 0 0 415.346 415.346'}} className="svg replaced-svg">
                                                <g>
                                                    <g>
                                                        <path d="M41.712,415.346c-11.763,0-21.3-9.537-21.3-21.3V21.299C20.412,9.536,29.949,0,41.712,0l346.122,191.697    c0,0,15.975,15.975,0,31.951C371.859,239.622,41.712,415.346,41.712,415.346z"></path>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                </svg></a>
                                            </figcaption>
                                        </figure>
                                    </div>{/*<!-- ends: .video-single -->*/}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- end ./tab-pane --> */}

                    <div className="tab-pane animated fadeIn" id="info2" role="tabpanel" aria-labelledby="info_tab2">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <figure className="tab_image_module--left">
                                        <img src="./assets/img/tab2img2.jpg" alt="" />
                                    </figure>
                                </div>

                                <div className="col-lg-5 offset-lg-1">
                                    <div className="tab_text_module">
                                        <h2>Best Business Support</h2>
                                        <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius.
                                            Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium.
                                            Investig ationes demons trave runt lectores legere liusry awquod he legunt saepius
                                            clary est etiam pro cessus dynamicus, qui sequitur mutaety.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane animated fadeIn" id="info3" role="tabpanel" aria-labelledby="info_tab3">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5 ">
                                    <div className="tab_text_module">
                                        <h2>Business Strategy</h2>
                                        <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius.
                                            Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tionem.</p>

                                        <ul className="arrow--list4">

                                        <li className="list-item arrow-list4"> Professional delivers solutions</li>


                                        <li className="list-item arrow-list4"> Business human capital research</li>


                                        <li className="list-item arrow-list4"> Services complex problems bringing</li>


                                        <li className="list-item arrow-list4"> Strategy works with senior executives</li>

                                        </ul>{/*<!-- ends: .arrow--list4 -->*/}

                                    </div>
                                </div>

                                <div className="col-lg-6 offset-lg-1">
                                    <figure className="tab_image_module--right">
                                        <img src="./assets/img/tab2img3.jpg" alt="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/*<!--end ./tab -->*/}
        </section>
    )
}


export const Tab4 = () => {
    return (
        <div className="tab tab--4" >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab_nav4">
                            <ul className="nav nav-tabs" id="tab4" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="tab4_nav1" data-toggle="tab" href="#tab4_content1" role="tab" aria-controls="tab4_nav1" aria-selected="true">Trumarks<sup>TM</sup></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="tab4_nav2" data-toggle="tab" href="#tab4_content2" role="tab" aria-controls="tab4_nav2" aria-selected="false">Anthea Consulting</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="tab4_nav3" data-toggle="tab" href="#tab4_content3" role="tab" aria-controls="tab4_nav3" aria-selected="false">NanoMarkers<sup>TM</sup> by Anthea</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>{/*<!-- end /.row -->*/}
                <div className="row">
                    <div className="col-md-12">
                      <div className="tab-content tab--content4" id="tabcontent4" style={{backgroundColor: 'white'}}>
                            <div className="tab-pane fade show active" id="tab4_content1" role="tabpanel" aria-labelledby="tab4_content1">
                                <div className="row no-gutters align-items-center">
                                    <div className="col-lg-5 margin-md-60">
                                        <div className="graph_area p-left-50" style={{textAlign: 'center'}}>
                                            <img src="./assets/img/TrumarksPoweredByLogo.png" style={{height: '80px'}}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="text_content p-left-50">
                                            <h4>A unique fingerprint for each product</h4>
                                            <p>Trumarks<sup>TM</sup> is a cloud-based software service for cannabis brands that provides direct business to customer communication and product authentication. It fits your packaging and can be printed on your products using a vareity of print setups. To the consumer, Trumarks is an easy to read code that can be scanned by any iPhone or Android device. For you, Trumarks works on a straightforward software interface - Input your product information once, add custom messages for each lot or item, and Trumarks creates secure labels for your batch.
                                            </p>
                                            <a href="https://trumarks.org" className="btn btn-primary btn--rounded mt-4">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>{/*<!-- ends .tab-pane -->*/}

                            <div className="tab-pane fade" id="tab4_content2" role="tabpanel" aria-labelledby="tab4_content2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col-lg-5 margin-md-60">
                                        <div className="graph_area" style={{textAlign: 'center'}}>
                                            <img src="./assets/img/126.jpg" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="text_content p-left-50">
                                            <h4>From Supply Chain to Food Safety</h4>
                                            <p>We are experts in supply chain integrity. We work with clients to analyze company supply chain practices, recommend changes, and lead implementation of new practices. In addition to supply chain expertise, we offer industry leading consulting on food process safety procedures. Anthea also has worked with dozens of companies on track-and-trace issues, in particular offering advice and best practices around withdraw and recall procedures.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            {/*<!-- ends .tab-pane -->*/}
                            <div className="tab-pane fade" id="tab4_content3" role="tabpanel" aria-labelledby="tab4_content3">
                                <div className="row no-gutters align-items-center">
                                    <div className="col-lg-5 margin-md-60">
                                        <div className="graph_area p-left-50">
                                           <img src="./assets/img/64964.jpg" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="text_content p-left-50">
                                            <h4>Micro Taggants help Prevent counterfeit</h4>
                                            <p>Nanomarker<sup>TM</sup> technology is a platform of various proprietary micro-taggant technologies that may be applied to substrates, inks, and in some cases, physical products to enhance product authentication and to prevent counterfeit. Nanomarker technology protects against brand violation by providing unequivocal and rapid litigation evidence. Nanomarker taggants can be encoded with HD and 2D barcodes – designed to carry manufacturing origin, logistics verification and custom serialization data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>{/*<!-- ends .tab-pane -->*/}

                        </div>
                    </div>
                </div>{/*<!-- end /.row -->*/}
            </div>{/*<!-- end /.container -->*/}
        </div>
    )
}

export class Tab6 extends Component {
    state = {
        rating: 1,
        rating1: 1
    }
    onStarClick(nextValue, prevValue, name) {
        this.setState({rating: nextValue});
    }
    onStarClick1(nextValue, prevValue, name) {
        this.setState({rating1: nextValue});
    }
    render () {
        const { rating, rating1 } = this.state;
        return (
            <section className="product-info-tab">
                <div className="hr-divider p-bottom-35"></div>
                <div className="tab tab--6">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tab_nav2">
                                    <ul className="nav justify-content-center" id="tab6" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link" id="tab6_nav1" data-toggle="tab" href="#tab6_content1" role="tab" aria-controls="tab6_content1" aria-selected="false">Description</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="tab6_nav2" data-toggle="tab" href="#tab6_content2" role="tab" aria-controls="tab6_content2" aria-selected="false">Additional information</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link active show" id="tab6_nav3" data-toggle="tab" href="#tab6_content3" role="tab" aria-controls="tab6_content3" aria-selected="true">Reviews (2)</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="tab-content tab--content2" id="tabcontent6">
                                    <div className="tab-pane fade" id="tab6_content1" role="tabpanel" aria-labelledby="tab6_content1">
                                        <h4>Description</h4>
                                        <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius.
                                            Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium. Investig
                                            ationes demons trave runt lectores legere liusry awquod he legunt saepius clary tyitas
                                            Investig atesw.
                                            Curabitur vel sagittis lectus. Donec vitae efficitur ante. Nam mollis ligula tortor.
                                            Interdum et malesuada fames.</p>

                                        <p>
                                            Suspendisse tristique porttitor magna, ac pulvinar libero lobortis at. Quisque sit amet
                                            facilisis eros. Vestibulum id ligula elementum, rutrum nunc sit amet, vulputate diam.
                                            Phasellus finibus mauris leo, quis commodo elit pellentesque quis.
                                        </p>
                                    </div>{/*<!-- end ./tab-pane -->*/}

                                    <div className="tab-pane fade" id="tab6_content2" role="tabpanel" aria-labelledby="tab6_content2">
                                        <h4>Additional Information</h4>
                                        <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius.
                                            Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium. Investig
                                            ationes demons trave runt lectores legere liusry awquod he legunt saepius clary tyitas
                                            Investig atesw.
                                            Curabitur vel sagittis lectus. Donec vitae efficitur ante. Nam mollis ligula tortor.
                                            Interdum et malesuada fames.</p>
                                        <ul className="bullet--list1 m-top-30">

                                            <li className="bullet_list"><strong className="color-dark">Professional -</strong> Delivers solutions that help drive</li>


                                            <li className="bullet_list"><strong className="color-dark">Business -</strong> Human capital research analytics</li>


                                            <li className="bullet_list"><strong className="color-dark">Services -</strong> Complex problems bringing an approach</li>

                                        </ul>
                                    </div>

                                    <div className="tab-pane fade active show" id="tab6_content3" role="tabpanel" aria-labelledby="tab6_content3">
                                        <h4>Reviews</h4>
                                        <div className="review-wrapper">
                                            <div className="reviews">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="review-single">
                                                            <div className="review-details">
                                                                <ul>
                                                                    <li>
                                                                        <span>Review By: </span>
                                                                        <span className="strong">Bob Andrews</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Posted On: </span>
                                                                        <span className="strong">07 Dec, 2018</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Rating: </span>
                                                                        <span className="strong">
                                                                            <StarRatingComponent
                                                                                className='strong'
                                                                                name="rate1"
                                                                                starCount={5}
                                                                                value={rating}
                                                                                onStarClick={this.onStarClick.bind(this)}
                                                                            />
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="review-text">
                                                                <p>Good product!!! Really it is helpful for business and finance. Thank you so much!!!</p>
                                                            </div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-lg-12 -->*/}
                                                    <div className="col-lg-12">
                                                        <div className="review-single">
                                                            <div className="review-details">
                                                                <ul>
                                                                    <li>
                                                                        <span>Review By: </span>
                                                                        <span className="strong">Frank Woods</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Posted On: </span>
                                                                        <span className="strong">24 Nov, 2018</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Rating: </span>
                                                                        <span className="strong">
                                                                            <StarRatingComponent
                                                                                name="rate1"
                                                                                starCount={5}
                                                                                value={rating1}
                                                                                onStarClick={this.onStarClick1.bind(this)}
                                                                            />
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="review-text">
                                                                <p>Good product!!! Really it is helpful for business and finance. Thank you so much!!!</p>
                                                            </div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-lg-12 -->*/}
                                                </div>
                                            </div>{/*<!-- ends: .reviews -->*/}
                                            <div className="review-form cardify">
                                                <h4>You're Reviewing: </h4>
                                                <p>"<NavLink to="/" onClick={noAction}>Business Marketing Presentation</NavLink>"</p>
                                                <form action="#">
                                                    <div className="form-group row">
                                                        <div className="col-lg-12">
                                                            <div className="rating m-bottom-35 d-flex align-items-center">
                                                                <span>Your Rating: </span>
                                                                <div className="br-wrapper br-theme-fontawesome-stars">
                                                                    <div className="br-wrapper br-theme-fontawesome-stars">
                                                                        <select className="give_rating" style={{display: 'none'}}> {/*<!-- now hidden -->*/}
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                            <option value="5">5</option>
                                                                        </select>
                                                                        <div className="br-widget">
                                                                            <NavLink to="/" onClick={noAction} data-rating-value="1" data-rating-text="1" className="br-selected br-current"></NavLink>
                                                                            <NavLink to="/" onClick={noAction} data-rating-value="2" data-rating-text="2"></NavLink>
                                                                            <NavLink to="/" onClick={noAction} data-rating-value="3" data-rating-text="3"></NavLink>
                                                                            <NavLink to="/" onClick={noAction} data-rating-value="4" data-rating-text="4"></NavLink>
                                                                            <NavLink to="/" onClick={noAction} data-rating-value="5" data-rating-text="5"></NavLink>
                                                                            <div className="br-current-rating">1</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input type="text" placeholder="Your Name" className="form-control" />
                                                        </div>{/*<!-- ends: .col-lg-6 -->*/}
                                                        <div className="col-lg-6">
                                                            <input type="email" placeholder="Your Email" className="form-control" />
                                                        </div>{/*<!-- ends: .col-lg-6 -->*/}
                                                        <div className="col-lg-12">
                                                            <textarea placeholder="Your Review" className="form-control" rows="3"></textarea>
                                                            <button className="btn btn-primary">Submit Review</button>
                                                        </div>{/*<!-- ends: .col-lg-12 -->*/}
                                                    </div>
                                                </form>
                                            </div>{/*<!-- ends: .review-form -->*/}
                                        </div>{/*<!-- ends: .review-wrapper -->*/}
                                    </div>{/*<!-- ends: .tab-pane -->*/}
                                </div>
                            </div>
                        </div>
                    </div>{/*<!--end ./container -->*/}
                </div>{/*<!--end ./tab -->*/}
            </section>
        )
    }
}

export const Tab3 = () => {
    return (
        <div className="tab tab--3">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab_nav3">
                            <ul className="nav justify-content-around" id="myTab2" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="contact_nav" data-toggle="tab" href="#contact_tab" role="tab" aria-controls="contact_tab" aria-selected="true">America</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="contact_nav2" data-toggle="tab" href="#contact_tab2" role="tab" aria-controls="contact_tab2" aria-selected="false">Europe</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="contact_nav3" data-toggle="tab" href="#contact_tab3" role="tab" aria-controls="contact_tab3" aria-selected="false">Africa</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="contact_nav4" data-toggle="tab" href="#contact_tab4" role="tab" aria-controls="contact_tab4" aria-selected="false">Asia</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="contact_nav5" data-toggle="tab" href="#contact_tab5" role="tab" aria-controls="contact_tab5" aria-selected="false">Australia</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>{/*<!-- end /.row -->*/}
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab-content tab--content2" id="tabcontent3">
                            <div className="tab-pane fade show active" id="contact_tab" role="tabpanel" aria-labelledby="contact_tab">
                                <Carousel />
                            </div>
                            <div className="tab-pane fade" id="contact_tab2" role="tabpanel" aria-labelledby="contact_tab2">
                                <Carousel />
                            </div>
                            <div className="tab-pane fade" id="contact_tab3" role="tabpanel" aria-labelledby="contact_tab3">
                                <Carousel />
                            </div>
                            <div className="tab-pane fade" id="contact_tab4" role="tabpanel" aria-labelledby="contact_tab4">
                                <Carousel />
                            </div>
                            <div className="tab-pane fade" id="contact_tab5" role="tabpanel" aria-labelledby="contact_tab5">
                                <Carousel />
                            </div>
                        </div>
                    </div>
                </div>{/*<!-- end /.row -->*/}
            </div>{/*<!-- end /.container -->*/}
        </div>
    )
}

export const ServiceTab = () => {
    return (
        <Fragment>
            <section className="service-tab-wrapper section-padding">
                <div className="tab service--tabs">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-3 sidebar">
                            <div className="tab_nav tab_nav2 m-bottom-50">
                                <div className="nav flex-column" id="tab_nav1" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link active" id="v-pills-tab1" data-toggle="pill" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Financial Analysis</a>
                                    <a className="nav-link" id="v-pills-tab2" data-toggle="pill" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Investment Planning</a>
                                    <a className="nav-link" id="v-pills-tab3" data-toggle="pill" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">Savings &amp; Investments</a>
                                    <a className="nav-link" id="v-pills-tab4" data-toggle="pill" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">Investment Planning</a>
                                    <a className="nav-link" id="v-pills-tab5" data-toggle="pill" href="#tab5" role="tab" aria-controls="tab5" aria-selected="false">Online Consulting</a>
                                    <a className="nav-link" id="v-pills-tab6" data-toggle="pill" href="#tab6" role="tab" aria-controls="tab6" aria-selected="false">Strategic Consulting</a>
                                    <a className="nav-link" id="v-pills-tab7" data-toggle="pill" href="#tab7" role="tab" aria-controls="tab7" aria-selected="false">Business Process Services</a>
                                </div>
                            </div>{/* ends: .tab_nav */}
                            <div className="download-widget m-bottom-30">
                                <div className="header">
                                    <h6>Company Brochure</h6>
                                </div>
                                <div className="content">
                                    <img src="./assets/img/brochure.jpg" alt="" />
                                    <p>
                                    <NavLink to="/" onClick={noAction} className="btn btn-secondary btn-sm btn-icon icon-left"><i className="la la-cloud-download" /> Download (PDF)</NavLink>
                                    <span>165.64 KB</span>
                                    </p>
                                </div>
                            </div>{/* ends: .download-widget */}
                            <div className="cta-widget m-bottom-50">
                                <h6>Any Question?</h6>
                                <div className="content">
                                    <p className="call-us">
                                    <span>Call Us:</span>
                                    <span><i className="la la-headphones" /> 800-567 890 576</span>
                                    </p>
                                    <p className="message-us">
                                        <span>Or</span>
                                        <NavLink to="/" onClick={noAction} className="btn btn-primary btn-sm btn-icon icon-left"><i className="la la-envelope" /> Send Message</NavLink>
                                    </p>
                                </div>
                            </div>{/* ends: .cta-widget */}
                        </div>{/* ends: .col-lg-3 */}
                        <div className="col-lg-9">
                            <div className="tab-content" id="tabContent1">
                            <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1">
                                <div className="tab-image m-bottom-50">
                                <img src="./assets/img/service1.jpg" alt="Images the foolda" />
                                </div>
                                <div className="contents-1">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                    <h3>Financial Overview &amp; Analysis</h3>
                                    </div>
                                </div>
                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.<br /><br /> Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr aver unt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.</p>
                                </div>
                                <div className="contents-2 m-top-60">
                                <div className="m-bottom-35">
                                    <div className="divider divider-simple text-left">
                                    <h3>Our Services Include</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                    <div className="icon-box-four d-flex">
                                        <div className="box-icon">
                                        <span className="icon-rounded-sm"><i className="la la-thumbs-up" /></span>
                                        </div>
                                        <div className="box-content">
                                        <h6>Valuable Ideas</h6>
                                        <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                        </div>
                                    </div>{/* ends: .icon-box */}
                                    </div>{/* ends: .col-md-6 */}
                                    <div className="col-md-6">
                                    <div className="icon-box-four d-flex">
                                        <div className="box-icon">
                                        <span className="icon-rounded-sm"><i className="la la-bar-chart" /></span>
                                        </div>
                                        <div className="box-content">
                                        <h6>Industry Experience</h6>
                                        <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                        </div>
                                    </div>{/* ends: .icon-box */}
                                    </div>{/* ends: .col-md-6 */}
                                    <div className="col-md-6">
                                    <div className="icon-box-four d-flex">
                                        <div className="box-icon">
                                        <span className="icon-rounded-sm"><i className="la la-money" /></span>
                                        </div>
                                        <div className="box-content">
                                        <h6>Budget Friendly</h6>
                                        <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                        </div>
                                    </div>{/* ends: .icon-box */}
                                    </div>{/* ends: .col-md-6 */}
                                    <div className="col-md-6">
                                    <div className="icon-box-four d-flex">
                                        <div className="box-icon">
                                        <span className="icon-rounded-sm"><i className="la la-sun-o" /></span>
                                        </div>
                                        <div className="box-content">
                                        <h6>Industry Experience</h6>
                                        <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                        </div>
                                    </div>{/* ends: .icon-box */}
                                    </div>{/* ends: .col-md-6 */}
                                </div>
                                </div>
                                <div className="contents-2 m-top-10">
                                <div className="m-bottom-25">
                                    <div className="divider divider-simple text-left">
                                    <h3>Your Benefits</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 margin-md-60">
                                    <p>Inves tiga tiones demonstr averun d lectores leg ere melius quod kequa legunt s aepius. Clar tas kdest etiam pro cessus dynamicus.</p>
                                    <ul className="m-top-30">
                                        <li className="bullet_list">Delivers solutions that help drive</li>
                                        <li className="bullet_list">Human capital research analytics</li>
                                        <li className="bullet_list">Complex problems bringing an approach</li>
                                    </ul>
                                    </div>
                                    <div className="col-lg-6">
                                    <div className="pie-chart_wrap">
                                        <canvas className="pie-chart" />
                                        <div className="legend pie-legend legend-list" />
                                    </div>{/* End: .pie-chart_wrap */}
                                    </div>
                                </div>
                                </div>
                                <div className="contents-2 m-top-90">
                                <div className="m-bottom-45">
                                    <div className="divider divider-simple text-left">
                                    <h3>Insights and Impact</h3>
                                    </div>
                                </div>
                                <div className="tab tab--4">
                                    <div className="row">
                                    <div className="col-md-12">
                                        <div className="tab_nav4">
                                        <ul className="nav nav-tabs" id="tab9" role="tablist">
                                            <li className="nav-item">
                                            <a className="nav-link active" id="tab9_nav1" data-toggle="tab" href="#tab9_content1" role="tab" aria-controls="tab9_nav1" aria-selected="true">Client Prospecting</a>
                                            </li>
                                            <li className="nav-item">
                                            <a className="nav-link" id="tab9_nav2" data-toggle="tab" href="#tab9_content2" role="tab" aria-controls="tab9_nav2" aria-selected="false">Funding Research</a>
                                            </li>
                                            <li className="nav-item">
                                            <a className="nav-link" id="tab9_nav3" data-toggle="tab" href="#tab9_content3" role="tab" aria-controls="tab9_nav3" aria-selected="false">Analytics</a>
                                            </li>
                                        </ul>
                                        </div>
                                        {/*end ./tab_nav4 */}
                                    </div>
                                    </div>{/* end /.row */}
                                    <div className="row">
                                    <div className="col-md-12">
                                        <div className="tab-content tab--content4 tab--content9" id="tabcontent4">
                                        <div className="tab-pane fade show active" id="tab9_content1" role="tabpanel" aria-labelledby="tab9_content1">
                                            <div className="row no-gutters">
                                            <div className="col-md-12">
                                                <div className="text_content">
                                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt
                                                    saepius.
                                                    Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem
                                                    consuetudium.
                                                    Investiga tiones demonstr averunt lectores legere me liusked quod kequa
                                                    legunt
                                                    saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.
                                                    Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt
                                                    saepius. Claritas est etiam pro cessus.</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        {/*end ./tab-pane */}
                                        <div className="tab-pane fade" id="tab9_content2" role="tabpanel" aria-labelledby="tab9_content2">
                                            <div className="row no-gutters">
                                            <div className="col-md-12">
                                                <div className="text_content">
                                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt
                                                    saepius.
                                                    Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem
                                                    consuetudium.
                                                    Investiga tiones demonstr averunt lectores legere me liusked quod kequa
                                                    legunt
                                                    saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.
                                                    Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt
                                                    saepius. Claritas est etiam pro cessus.</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        {/*end ./tab-pane */}
                                        <div className="tab-pane fade" id="tab9_content3" role="tabpanel" aria-labelledby="tab9_content3">
                                            <div className="row no-gutters">
                                            <div className="col-md-12">
                                                <div className="text_content">
                                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt
                                                    saepius.
                                                    Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem
                                                    consuetudium.
                                                    Investiga tiones demonstr averunt lectores legere me liusked quod kequa
                                                    legunt
                                                    saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.
                                                    Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt
                                                    saepius. Claritas est etiam pro cessus.</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        {/*end ./tab-pane */}
                                        </div>
                                    </div>
                                    </div>{/* end /.row */}
                                </div>{/* end /.tab--4 */}
                                </div>
                            </div>{/* ends: .tab-pane */}
                            <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2">
                                <div className="contents-1">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                    <h3>Investment Planning</h3>
                                    </div>
                                </div>
                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.<br /><br /> Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr aver unt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.</p>
                                <div className="m-top-40">
                                    {/* start blockquote */}
                                    <blockquote className="blockquote blockquote1 ">
                                    <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                                    <div className="quote-author">
                                        <p><span>Jeff Collins,</span> Founder of Tejarat Inc.</p>
                                    </div>
                                    </blockquote>{/* end: blockquote */}
                                </div>
                                </div>
                            </div>{/* ends: .tab-pane */}
                            <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3">
                                <div className="contents-1">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                    <h3>Savings &amp; Investments</h3>
                                    </div>
                                </div>
                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.<br /><br /> Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr aver unt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.</p>
                                <div className="m-top-40">
                                    {/* start blockquote */}
                                    <blockquote className="blockquote blockquote1 ">
                                    <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                                    <div className="quote-author">
                                        <p><span>Jeff Collins,</span> Founder of Tejarat Inc.</p>
                                    </div>
                                    </blockquote>{/* end: blockquote */}
                                </div>
                                </div>
                            </div>{/* ends: .tab-pane */}
                            <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4">
                                <div className="contents-1">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                    <h3>Business Consulting</h3>
                                    </div>
                                </div>
                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.<br /><br /> Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr aver unt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.</p>
                                <div className="m-top-40">
                                    {/* start blockquote */}
                                    <blockquote className="blockquote blockquote1 ">
                                    <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                                    <div className="quote-author">
                                        <p><span>Jeff Collins,</span> Founder of Tejarat Inc.</p>
                                    </div>
                                    </blockquote>{/* end: blockquote */}
                                </div>
                                </div>
                            </div>{/* ends: .tab-pane */}
                            <div className="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab5">
                                <div className="contents-1">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                    <h3>Online Consulting</h3>
                                    </div>
                                </div>
                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.<br /><br /> Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr aver unt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.</p>
                                <div className="m-top-40">
                                    {/* start blockquote */}
                                    <blockquote className="blockquote blockquote1 ">
                                    <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                                    <div className="quote-author">
                                        <p><span>Jeff Collins,</span> Founder of Tejarat Inc.</p>
                                    </div>
                                    </blockquote>{/* end: blockquote */}
                                </div>
                                </div>
                            </div>{/* ends: .tab-pane */}
                            <div className="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab6">
                                <div className="contents-1">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                    <h3>Strategic Consulting</h3>
                                    </div>
                                </div>
                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.<br /><br /> Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr aver unt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.</p>
                                <div className="m-top-40">
                                    {/* start blockquote */}
                                    <blockquote className="blockquote blockquote1 ">
                                    <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                                    <div className="quote-author">
                                        <p><span>Jeff Collins,</span> Founder of Tejarat Inc.</p>
                                    </div>
                                    </blockquote>{/* end: blockquote */}
                                </div>
                                </div>
                            </div>{/* ends: .tab-pane */}
                            <div className="tab-pane fade" id="tab7" role="tabpanel" aria-labelledby="tab7">
                                <div className="contents-1">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                    <h3>Business Process Services</h3>
                                    </div>
                                </div>
                                <p>Investiga tiones demonstr averun d lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.<br /><br /> Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demonstr aver unt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus.</p>
                                <div className="m-top-40">
                                    {/* start blockquote */}
                                    <blockquote className="blockquote blockquote1 ">
                                    <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                                    <div className="quote-author">
                                        <p><span>Jeff Collins,</span> Founder of Tejarat Inc.</p>
                                    </div>
                                    </blockquote>{/* end: blockquote */}
                                </div>
                                </div>
                            </div>{/* ends: .tab-pane */}
                            </div>
                        </div>{/* ends: .col-lg-9 */}
                        </div>
                    </div>
                </div>{/* ends: .service--tabs */}
            </section>{/* ends: .service-tab-wrapper */}
        </Fragment>
    )
}

import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import Map2 from '../../content/element/map/map-2';
const noAction = (e) => {
    e.preventDefault()
}
const DetailsContent = (props) => {
    const {date, duration} = props;
    
    return (
        <Fragment>
            <div className="event-info cardify rounded border">
                <ul className="icon-list--one">
                    <li className="list-item icon-list"><span className="color-secondary"><i className="la la-calendar-check-o" /></span> <strong>Date:</strong> {date}</li>
                    <li className="list-item icon-list"><span className="color-secondary"><i className="la la-clock-o" /></span> <strong>Time:</strong> {duration}</li>
                    <li className="list-item icon-list"><span className="color-secondary"><i className="la la-map-marker" /></span> <strong>Venue:</strong> Wst. Conference Center, San Francisco, CA</li>
                    <li className="list-item icon-list"><span className="color-secondary"><i className="la la-mobile" /></span> <strong>Contact:</strong> 800 567.890.576</li>
                </ul>{/* ends .icon-list--one */}
            </div>
            {/* ends: .event-info */}
           
            <div className="event-location m-top-40">                
                <Map2 styles={{position: 'relative', height: '400px', overflow: 'hidden'}} />                
            </div>{/* ends: .event-location */}

            <div className="event-overview m-top-70 m-bottom-60">
                <h4>Conference Overview</h4>
                <p>Investiga tiones demonstr averun d lectores legere melius was quod kequa legunt saepius.
                Claritas est etiam pro cessus dynamicus, sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones. </p>
                <div className="m-top-15">
                    <ul className="check-list--three">
                        <li className="list-item checklist-three ">
                            <img src="./assets/img/svg/checked.svg" alt="" className="svg" />
                            <span>Purus euhy dolor tempor fringilla quam dolor</span>
                        </li>
                        <li className="list-item checklist-three ">
                            <img src="./assets/img/svg/checked.svg" alt="" className="svg" />
                            <span>Curabitur ullam corper ultricies wegete kyel</span>
                        </li>
                        <li className="list-item checklist-three ">
                            <img src="./assets/img/svg/checked.svg" alt="" className="svg" />
                            <span>Quisque euismd pellents massa conuat shy</span>
                        </li>
                        <li className="list-item checklist-three ">
                            <img src="./assets/img/svg/checked.svg" alt="" className="svg" />
                            <span>Quisque euismod ellentesque massa</span>
                        </li>
                    </ul>{/* ends: .check-list--three */}
                </div>
            </div>{/* ends: .event-overview */}

            <div className="event-speakers m-bottom-60">
                <h4>Conference Speaker</h4>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="team-single team-five">
                                <img src="./assets/img/t8.jpg" alt="" />
                                <div className="content">
                                    <h6><NavLink to="/team">Jack Sessmper</NavLink></h6>
                                    <span>Chief Executive Officer</span>
                                    <span className="color-primary m-top-5">Flexera</span>
                                    <div className="m-top-20">
                                        <div className="social-basic icon-grey">
                                            <ul className="d-flex justify-content-start rounded-circle">
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-facebook-f" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-twitter" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-linkedin-in" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-google-plus-g" /></NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* ends: .team-single */}
                        </div>{/* ends: .col-lg-4 */}
                        <div className="col-lg-4 col-md-6">
                            <div className="team-single team-five">
                                <img src="./assets/img/t9.jpg" alt="" />
                                <div className="content">
                                    <h6><NavLink to="/team">Tony Stark</NavLink></h6>
                                    <span>Chief Executive Officer</span>
                                    <span className="color-primary m-top-5">EcoTheme</span>
                                    <div className="m-top-20">
                                        <div className="social-basic icon-grey">
                                            <ul className="d-flex justify-content-start rounded-circle">
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-facebook-f" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-twitter" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-linkedin-in" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-google-plus-g" /></NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* ends: .team-single */}
                        </div>{/* ends: .col-lg-4 */}
                        <div className="col-lg-4 col-md-6">
                            <div className="team-single team-five">
                                <img src="./assets/img/t10.jpg" alt="" />
                                <div className="content">
                                    <h6><NavLink to="/team">Jonny Burstru</NavLink></h6>
                                    <span>Chief Executive Officer</span>
                                    <span className="color-primary m-top-5">EcoTheme</span>
                                    <div className="m-top-20">
                                        <div className="social-basic icon-grey">
                                            <ul className="d-flex justify-content-start rounded-circle">
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-facebook-f" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-twitter" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-linkedin-in" /></NavLink></li>
                                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-google-plus-g" /></NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* ends: .team-single */}
                        </div>{/* ends: .col-lg-4 */}
                    </div>
                </div>{/* ends: .event-speakers */}
                <div className="event-schedule m-bottom-60">
                    <h4>Conference Schedule</h4>
                    <div className="tab_nav5">
                        <ul className="nav nav-pills" id="tab5" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="tab5_nav1" data-toggle="tab" href="#tab5_content1" role="tab" aria-controls="tab5_nav1" aria-selected="true">Day One</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="tab5_nav2" data-toggle="tab" href="#tab5_content2" role="tab" aria-controls="tab5_nav2" aria-selected="false">Day Two</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="tab5_nav3" data-toggle="tab" href="#tab5_content3" role="tab" aria-controls="tab5_nav3" aria-selected="false">Day Three</a>
                            </li>
                        </ul>
                    </div>{/* ends .tab_nav5 */}
                    <div className="tab-content tab--content5" id="tabcontent5">
                    <div className="tab-pane fade show active" id="tab5_content1" role="tabpanel" aria-labelledby="tab5_nav1">
                        <div className="tab_event">
                        <div className="tab_event__module cardify border">
                            <div className="event_meta">
                                <p><span className="la la-calendar-check-o" />Wednesday, 20 May 2019</p>
                                <p><span className="la la-clock-o" />8:00 AM - 10:00 AM</p>
                                <p><span className="la la-map-marker" />Room A</p>
                            </div>
                            <h5>Gathering &amp; Welcome Speech</h5>
                            <p>Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demo knstr
                            aver unt lectores legere me lius quod legunt saepius. </p>
                            <ul className="speakers">
                            <li>
                                <img src="./assets/img/sp1.jpg" alt="" />
                                <div>
                                <h6>Jack Semper</h6>
                                <p>Chief Operating Officer</p>
                                </div>
                            </li>
                            <li>
                                <img src="./assets/img/sp2.jpg" alt="" />
                                <div>
                                <h6>Jack Semper</h6>
                                <p>Chief Operating Officer</p>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tab5_content2" role="tabpanel" aria-labelledby="tab5_nav2">
                        <div className="tab_event">
                            <div className="tab_event__module cardify border">
                                <div className="event_meta">
                                    <p><span className="la la-calendar-check-o" />Wednesday, 22 May 2019</p>
                                    <p><span className="la la-clock-o" />8:00 AM - 10:00 AM</p>
                                    <p><span className="la la-map-marker" />Room A</p>
                                </div>
                                <h5>V8 and democratization of JavaScript</h5>
                                <p>Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demo knstr
                                aver unt lectores legere me lius quod legunt saepius. </p>
                                <ul className="speakers">
                                    <li>
                                        <img src="./assets/img/sp1.jpg" alt="" />
                                        <div>
                                            <h6>Jack Semper</h6>
                                            <p>Chief Operating Officer</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="./assets/img/sp2.jpg" alt="" />
                                        <div>
                                            <h6>Jack Semper</h6>
                                            <p>Chief Operating Officer</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tab5_content3" role="tabpanel" aria-labelledby="tab5_nav3">
                        <div className="tab_event">
                            <div className="tab_event__module cardify border">
                                <div className="event_meta">
                                    <p><span className="la la-calendar-check-o" />Wednesday, 27 May 2019</p>
                                    <p><span className="la la-clock-o" />8:00 AM - 10:00 AM</p>
                                    <p><span className="la la-map-marker" />Room A</p>
                                </div>
                                <h5>PLC CPP module in node JS, The robotics part!</h5>
                                <p>Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium. Investiga tiones demo knstr
                                aver unt lectores legere me lius quod legunt saepius. </p>
                                <ul className="speakers">
                                    <li>
                                        <img src="./assets/img/sp1.jpg" alt="" />
                                        <div>
                                            <h6>Jack Semper</h6>
                                            <p>Chief Operating Officer</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="./assets/img/sp2.jpg" alt="" />
                                        <div>
                                            <h6>Jack Semper</h6>
                                            <p>Chief Operating Officer</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>{/* ends .tab-content */}
                </div>
                <div>
                <div className="event-sponsor m-bottom-35">
                    <h4>Conference Sponsors</h4>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row logo-grid-two">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                        <img src="./assets/img/cl9.png" alt="" />
                                    </div>
                                </div>{/* ends: .col-lg-4 */}
                                <div className="col-lg-4 col-sm-6">
                                    <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                        <img src="./assets/img/cl10.png" alt="" />
                                    </div>
                                </div>{/* ends: .col-lg-4 */}
                                <div className="col-lg-4 col-sm-6">
                                    <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                        <img src="./assets/img/cl11.png" alt="" />
                                    </div>
                                </div>{/* ends: .col-lg-4 */}
                                <div className="col-lg-4 col-sm-6">
                                    <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                        <img src="./assets/img/cl12.png" alt="" />
                                    </div>
                                </div>{/* ends: .col-lg-4 */}
                                <div className="col-lg-4 col-sm-6">
                                    <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                        <img src="./assets/img/cl10.png" alt="" />
                                    </div>
                                </div>{/* ends: .col-lg-4 */}
                                <div className="col-lg-4 col-sm-6">
                                    <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                        <img src="./assets/img/cl12.png" alt="" />
                                    </div>
                                </div>{/* ends: .col-lg-4 */}
                            </div>
                        </div>{/* ends: .col-lg-12 */}
                    </div>{/* ends: .row */}
                </div>{/* ends: .event-sponsor */}
                <div className="event-share cardify border rounded d-flex align-items-center">
                    <span className="m-right-20">Share This Event: </span>
                    <div>
                        <div className="social social--colored ">
                            <ul className="d-flex flex-wrap">                            
                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-facebook-f" /></NavLink></li>
                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-twitter" /></NavLink></li>
                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-linkedin-in" /></NavLink></li>
                                <li><NavLink to="/" onClick={noAction}><span className="fab fa-google-plus-g" /></NavLink></li>
                            </ul>
                        </div>{/* ends: .social */}
                    </div>
                </div>{/* ends: .event-share */}
            </div>
            
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        event : state.event
    }
}
export default connect(mapStateToProps)(DetailsContent);
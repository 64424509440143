import React, {Fragment} from 'react';
import Header from '../layout/header/single-product';

import Footer from '../layout/footer/footer-5';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/slider.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/slider';
import Card from '../container/element/card/shop-product';

class ShopProduct extends React.Component {
    state = {
        product: this.props.product,
        cate: this.props.product,
        count: 0
    }

    componentDidMount () {
        $(document).ready(() => {
            //Range slider light
            $("#slider-range1").slider({
                    range: true,
                    min: 0,
                    max: 500,
                    values: [0, 500],
                    slide: function (event, ui) {
                        $("#amount1").val("$" + ui.values[0] + " - $" + ui.values[1]);
                    }
                });

            $("#amount1").val("$" + $("#slider-range1").slider("values", 0) +
                    " - $" + $("#slider-range1").slider("values", 1));
        });
    }



    render () {

        // sorting here
        const sort = [];
        Object.values(this.state.product).map(item => {
            return sort.push(item)
        })

        const sorting = (e) => {
            switch (e.target.value) {
                case 'heigh':
                this.setState({
                    product: sort.sort(function(a, b) {
                        var textA = a.price;
                        var textB = b.price;

                        if (textA < textB) return 1;
                        if (textA > textB) return -1;
                        return 0;
                    })
                })

                break;
                case "low" :
                    this.setState({
                        product: sort.sort(function(a, b) {
                            var textA = a.price;
                            var textB = b.price;

                            if (textA < textB) return -1;
                            if (textA > textB) return 1;
                            return 0;
                        })
                    })
                    break;
                    case 'a-z':
                    this.setState({
                        product: sort.sort(function(a, b) {
                            var textA = a.title;
                            var textB = b.title;

                            if (textA < textB) return -1;
                            if (textA > textB) return 1;
                            return 0;
                        })
                    });
                    break;

                    case 'z-a':
                    this.setState({
                        product: sort.sort(function(a, b) {
                            var textA = a.title;
                            var textB = b.title;

                            if (textA < textB) return 1;
                            if (textA > textB) return -1;
                            return 0;
                        })
                    });
                    break;
                default :
                this.setState({
                    product: this.state.cate
                });

            }
        }

        // filter by category
        const category = e => {
            e.preventDefault();

            const filter = Object.values(this.state.cate).filter(item => {
                return item.category === e.target.innerHTML
            })
            if(e.target.innerHTML !== "All") {
                this.setState({
                    product : filter
                })
            } else {
                this.setState({
                    product : this.state.cate
                })
            }
        }
        const catItem = [];
        Object.values(this.state.cate).map( value => {
            return catItem.push(value.category)
        })
        const catItemFilter = new Set(catItem);
        // filter by category end

        // filter by price range
        const range = e => {
            e.preventDefault();
            const value = document.getElementById('amount1').value;
            const max   = value.split('-').pop().split('$').pop().trim();
            const min   = value.split('-').shift().split('$').pop().trim();

            this.setState({
                product: Object.values(this.state.cate).filter(item1 => {
                    return item1.price >= min && item1.price <= max
                })
            })
        }
        // filter by price range end

        // filter by search
        const search = e => {
            e.preventDefault();
            const value = e.target.value.toLowerCase();

            this.setState({
                product: Object.values(this.state.cate).filter(function (item) {

                    return item.title.toLowerCase().startsWith(value) || item.content.toLowerCase().startsWith(value) ?
                        item : ''

                })
            })
        }

        // pagination start
        const blog  = this.state.product;
        const project = Object.values(blog).slice(this.state.count*9, this.state.count*9+9);
        const length = Object.values(blog).length;
        const item_length = Math.ceil(length / 9);

        const item = () => {
            return (
                Object.values(blog).map((value, key) => {
                    return (
                        key <= item_length-1 &&
                        <li key={ key } className={`page-item click ${key === 0 && "active"}`}><NavLink className="page-link" to="#">{key + 1}</NavLink></li>
                    )
                })
            )
        }

        setTimeout(() => {
            const li = document.querySelectorAll('.page-item');
            const click = document.querySelectorAll('.click');
            click.forEach((value, key) => {
                value.addEventListener('click', () => {
                    $(window).scrollTop(0);
                    li.forEach((li_item, li_key) => {
                        li_item.classList.remove('active');
                    })
                    value.classList.add('active');
                    this.setState({
                        count : key
                    })
                })
            })

            var next = document.querySelector('.next');
            var prev = document.querySelector('.prev');
            next.addEventListener('click', () => {
                document.querySelector('.page-item.active').nextElementSibling.click();
            })
            prev.addEventListener('click', () => {
                document.querySelector('.page-item.active').previousElementSibling.click();
            })
        }, 500);
        // pagination

        return (
            <Fragment>

                <Header pageTitle="Shop Products"/>

                <section className="products-wrapper p-top-60 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 order-1 order-md-0">
                                <div className="sidebar">
                                    {/* <!-- search widget --> */}
                                    <div className="d-none d-lg-block">
                                        <div className="widget-wrapper">
                                            <div className="search-widget">
                                                <form action="#">
                                                    <div className="input-group">
                                                        <input type="text" onChange={search} className="fc--rounded" placeholder="Search" />
                                                        <button type="submit"><i className="la la-search"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        {/* <!-- ends: .widget-wrapper --> */}
                                    </div>
                                    {/* <!-- category widget --> */}
                                    <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">Product Categories</h6>
                                            </div>
                                            <div className="widget-content">
                                                <div className="category-widget">
                                                   <ul>
                                                        <li className="arrow-list4"><NavLink to="/" onClick={category}>All</NavLink></li>
                                                        {
                                                            Array.from(catItemFilter).map((value, key) => {
                                                                return <li key={key+1} className="arrow-list4"><NavLink to="/" onClick={category}>{value}</NavLink></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- ends: .widget-wrapper --> */}
                                    {/* <!-- price filter widget --> */}
                                    <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">Filter By Price</h6>
                                            </div>
                                            <div className="widget-content">
                                                <div className="price-filter">
                                                    <div className="range-slider text-center">
                                                        <div id="slider-range1" className="slider-range m-auto"></div>
                                                        <p>
                                                            <button type="submit" onClick={range} className="btn filter-btn btn--rounded btn-secondary">Filter</button>
                                                            <input type="text" id="amount1" className="amounts" readOnly />
                                                        </p>
                                                    </div>{/*<!-- end: .range-slider -->*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/*<!-- ends: .widget-wrapper -->*/}
                                </div>{/*<!-- ends: .sidebar -->*/}
                            </div>{/*<!-- ends: .col-lg-3 -->*/}

                            <div className="col-lg-9 col-md-8 order-0 order-md-1">
                                <div className="d-lg-none">
                                    {/*<!-- search widget -->*/}
                                    <div className="widget-wrapper">
                                        <div className="search-widget">
                                            <form action="#">
                                                <div className="input-group">
                                                    <input type="text" className="fc--rounded" placeholder="Search" />
                                                    <button type="submit"><i className="la la-search"></i></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>{/*<!-- ends: .widget-wrapper -->*/}
                                </div>
                                <div className="product-sort d-flex justify-content-between align-items-center">
                                    <div className="sort d-flex align-items-center">
                                        <span className="m-right-15">Sort By: </span>
                                        <div className="form-group">
                                            <div className="select-basic">
                                                <select className="form-control fc--rounded" onChange={sorting}>
                                                    <option>Default Sorting</option>
                                                    <option value="heigh">Price : High to Low</option>
                                                    <option value="low">Price : Low to High</option>
                                                    <option value="a-z">Sort by Name : A-Z</option>
                                                    <option value="z-a">Sort by Name : Z-A</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="total-pages text-right">
                                        <p>Showing <span>1</span> - <span>9</span> results</p>
                                    </div>
                                </div>{/*<!-- ends: .product-sort -->*/}

                                <div className="product-grid">
                                    <Card shop_product={project} />
                                    <div className="project-pagination m-top-30">
                                        <div className="pagination-area">
                                            <nav aria-label="Page navigation pagination-left">
                                                <ul className="pagination justify-content-center">
                                                    <li className="page-item prev"><NavLink className="page-link" to="#">Previous</NavLink></li>
                                                    {
                                                        item()
                                                        }

                                                    <li className="page-item next"><NavLink className="page-link" to="#">Next</NavLink></li>
                                                </ul>
                                            </nav>
                                        </div>{/*<!-- ends: .pagination-wrapper -->*/}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        product : state.product,
        cart : state.cart
    }
}
export default connect(mapStateToProps)(ShopProduct);
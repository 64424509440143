import React from 'react';
import {NavLink} from 'react-router-dom';
import FooterItem from './footerItem';
const noAction = (e) => {
    e.preventDefault();
}

const FooterWavedLignt = () => {
    return (
        <footer className="footer7">
            <div className="footer__big">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget text_widget">
                                <img className="footer_logo" src="./assets/img/logo.png" alt="logo" />
                                <p>
                                    <a href="tel:+123-4567890" className="tel">+123 4567890</a>
                                    <a href="mailto:support@Tejarat.com" className="mail">Support@Tejarat.com</a>
                                    <span className="address">Melbourne, Australia, 95 South Park Avenue</span>
                                </p>
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}

                        <FooterItem />
                        
                    </div>
                </div>
            </div>{/*<!-- ends: footer__big -->*/}
            <div className="footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer__bottom-content">
                                <p>&copy; 2019 Tejarat. All rights reserved. Created by <a href="https://aazztech.com/" >AazzTech</a></p>
                                <div className="social-basic ">
                                    <ul className="d-flex justify-content-end ">
                                        <li><NavLink to="/" onClick={noAction}><span className="fab fa-facebook-f"></span></NavLink></li>
                                        <li><NavLink to="/" onClick={noAction}><span className="fab fa-twitter"></span></NavLink></li>
                                        <li><NavLink to="/" onClick={noAction}><span className="fab fa-linkedin-in"></span></NavLink></li>
                                        <li><NavLink to="/" onClick={noAction}><span className="fab fa-google-plus-g"></span></NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> {/*<!-- ends: footer__small -->*/}
        </footer>         
    )
}
export default FooterWavedLignt;
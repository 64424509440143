import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import {NavLink} from 'react-router-dom';

const ElementPage = (props) => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar /> 
            </section>
            {/* <!-- ends: .intro-area --> */}
            <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                <h4 className="page_title">{props.pageTitle}</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">{props.pageTitle}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>{/* ends: .row */}
                </div>
            </section>{/* ends: .breadcrumb_area */}
        </Fragment>       
                      
    )   
}
export default ElementPage;
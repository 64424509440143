import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';


class SubscribeFour extends Component {
        constructor (props) {
            super (props)
            this.state = {
                email:''            
            }
            this.validator = new SimpleReactValidator(); 
            this.setStateFromInput = this.setStateFromInput.bind(this);       
        }
    
        setStateFromInput = (event) => {
            var obj = {};
            obj[event.target.name] = event.target.value;
            this.setState(obj);
        }
    
        _isMounted = false;
    
        componentDidMount() {
            this._isMounted = true;
        }
    
        componentWillUnmount() {
            this._isMounted = false;
        }
    
    render () {

        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state)
                var success = true;
                return success;
                        
            } else {
                this.validator.showMessages();              
                this.forceUpdate();              
                var success2 = false;
                return success2;               
            }
        }
    return (
        <section className="subscribe-four bgimage gradient-overlay">
            <div className="bg_image_holder" style={{backgroundImage: 'url(&quot;img/sbg1.jpg&quot;)', opacity: 1}}>
                <img src="./assets/img/sbg1.jpg" alt="./assets/img/sbg1.jpg" />
            </div>
            <div className="container content_above">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                        <h3>Subscribe to Our Newsletter</h3>
                        <p>Subscribe to get update, offers notification and information. </p>
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                    <div className="col-lg-6">
                        <form>
                            
                            <div className="input-group input-group-subscribe">
                                <div className="text-field">
                                    <input type="text" value={this.state.email} name="email" onChange={this.setStateFromInput} className="form-control rounded-0" placeholder="Enter your email" aria-label="Email" />
                                </div>
                                <button onClick={Shipping_Address} >Submit</button>
                            </div>{/*<!-- End: .input-group -->*/}
                            <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div>

                        </form>
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
            </div>
        </section>
    )
    }
}
export default SubscribeFour;
import React, { Fragment } from 'react';

import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Filter from '../container/element/filter/filter-grid-masonry';

const Project = () => {
    return (
        <Fragment>

            <Header pageTitle='Project Masonry With Filter'/>
            <section className="project-masonry p-top-105 p-bottom-110">
                 <Filter />
            </section>
            <Footer />
        </Fragment>
    )
}

export default Project;
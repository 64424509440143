import React from 'react';
import { NavLink } from 'react-router-dom';
const Filter = props => {
    const {id, title, img, subtitle, category, content} = props;
    return (
        <div data-uk-filter={category} className="filtr-item col-md-6">
            <div className="card card-shadow card-four">
                <figure>
                    <img src={ img } alt="" style={{width: '100%'}} />                    
                </figure>
                <div className="card-body">
                    <p className="card-subtitle color-secondary">{ subtitle }</p>
                    <h5><NavLink to={ "/project-details"+id }>{ title }</NavLink></h5>
                    <p className="card-text">{content}</p>
                </div>
            </div>{/*<!-- Ends: .card -->*/}
        </div>
        
    )
}

export default Filter;
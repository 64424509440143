import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';

function addToMailchimp(source, data) {
  return fetch("/.netlify/functions/" + source, {
    method: "post",
    body: JSON.stringify(data)
  })
}


class Subscribe8 extends Component {
    constructor (props) {
        super (props)
        this.state = {
            email:''
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {

        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                this.setState({success: true})
                addToMailchimp('addToMailchimp', this.state)

                var success = true;
                return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }
        return (
            <section className="subscribe-eight">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="subscribe-contents text-center">
                                <h2>Get Industry Updates from Anthea <br /> <span>Subscribe to our Newsletter</span></h2>
                                {this.state.success ? (
                                    <h2>Thank you for subscribing!</h2>
                                ) : (
                                  <form id="subscribe" onSubmit={Shipping_Address}action="#" className="subscribe-form-two p-left-50 p-right-50">
                                  <div>
                                      <input type="text" value={this.state.email} name="email" onChange={this.setStateFromInput} className="form-control" placeholder="Enter your email address" aria-label="Username" />
                                      <button form="subscribe" className="btn btn-primary">Subscribe</button>
                                  </div>
                                  <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|string')}</div>
                                  </form>

                                )}
                            </div>{/*<!-- ends: .subscribe-contents -->*/}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Subscribe8;

import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import { IconBoxThree } from '../content/element/iconBox/iconBox';

import Cta from '../content/cta/cta-sm-2';

const Service = () => {
    return (
        <Fragment>

            <Header pageTitle='Business Services'/>
            <IconBoxThree />
            <Cta />
            <Footer />
        </Fragment>
    )
}

export default Service;
import React, { Fragment } from 'react';

import Header from '../layout/header/singlePageHeader';
import SocondaryNav from '../layout/navbar/aboutNavItem';
import ContentBlock3 from '../content/contentBlock/contentBlock-3';
import Footer from '../layout/footer/footer-5';
import { NavLink } from 'react-router-dom';

const noAction = e => e.preventDefault();

const About = () => {
    return (
        <Fragment>

            <Header pageTitle='Our Business Conduct' />
            <SocondaryNav />
            <section className="section-padding section-bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 margin-md-60">
                            <h2>Integrity is One of Our Most Prized Assets</h2>
                            <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium. Investig ationes demons trave runt lectores legere liusry awquod he legunt saepius clary Investig.</p>
                            <NavLink to='/' onClick={noAction} className="btn btn-primary btn-icon icon-right m-top-25">Download PDF <i className="la la-download" /></NavLink>
                        </div>{/* ends: .col-lg-5 */}
                        <div className="col-lg-6 offset-lg-1">
                            <img src="./assets/img/slide9_1.png" alt="" className="rounded" />
                        </div>{/* ends: .col-lg-6 */}
                    </div>
                </div>
            </section>
            <section className="content-block content--block3 section-padding">
                <ContentBlock3 />
            </section>
            <Footer />
        </Fragment>
    )
}

export default About;
import React, {Fragment} from 'react';
import Header from '../layout/header/content-height-4';
import SectionSplit from '../container/split/section-split-2';
import ContentBlockReport from '../content/contentBlock/report';
import Subscribe from '../content/subscribe/subscribe8';
import Footer2 from '../layout/footer/footerCurvedLight';
import CTA from '../content/cta/cta-3';

const Index10 = () => {
    return (
        <Fragment>
            <Header />
            <SectionSplit />
            <ContentBlockReport />
            <CTA />
            <Subscribe />
            <Footer2 />
        </Fragment>
    )
}

export default Index10;

import React from 'react';
const ContentBlock11 = ()=> {
    return (
        <section className="content-block section-bg content--block11 p-top-110 p-bottom-110">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-5 margin-md-60">
                        <h2>We Support Financial Ideas</h2>
                        <p>Investiga tiones demonstr averunt lectres legere kedrme quod kequa legunt saepius. Clarias est
                            etiam cessus.</p>
                        <div className="m-top-40">
                            <ul className="icon-light content-list--two">
                                <li className="content-list d-flex">
                                    <div className="icon icon-circle"><span><i className="la la-cog"></i></span></div>
                                    <div className="contents">
                                        <h6 className="color-dark">Reliability</h6>
                                        <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes.</p>
                                    </div>
                                </li>
                                <li className="content-list d-flex">
                                    <div className="icon icon-circle"><span><i className="la la-thumbs-up"></i></span></div>
                                    <div className="contents">
                                        <h6 className="color-dark">Industry Experience</h6>
                                        <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes.</p>
                                    </div>
                                </li>
                            </ul>{/*<!-- ends: .content-list--two -->*/}
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1">
                        <div className="video-single video-single--two video-overlay">
                            <figure>
                                <img src="./assets/img/v2.png" alt="" />
                            </figure>
                        </div>{/*<!-- ends: .video-single -->*/}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ContentBlock11;
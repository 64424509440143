import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import {NavLink} from 'react-router-dom';

const SinglePage2 = (props) => {
    return (
        <Fragment>
            <section className="header header--2">
                <Navbar />
            </section>
            {/* <!-- ends: .intro-area --> */}
            <div id="rev_slider_9_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="service_hero" data-source="gallery" style={{margin:'0px auto',background:'transparent',padding:'0px',marginTop:'0px',marginBottom:'0px', backgroundColor: 'rgb(243, 247, 255)'}}>
                {/* <!-- START REVOLUTION SLIDER 5.4.8.1 auto mode --> */}
                <div id="rev_slider_9_1" className="rev_slider fullwidthabanner" style={{display:'none'}} data-version="5.4.8.1">
                    <ul>
                        {/* <!-- SLIDE  --> */}
                        <li data-index="rs-12" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            {/* <!-- MAIN IMAGE --> */}
                            <img src="./assets/img/transparent.png" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
                            {/* <!-- LAYERS --> */}
                            {/* <!-- LAYER NR. 1 --> */}
                            <div className="tp-caption   tp-resizeme tp-svg-layer" id="slide-12-layer-1" data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['0','0','0','0']" data-width="['853','853','853','476']" data-height="['700','700','700','none']" data-whitespace="nowrap" data-type="svg" data-svg_src="./assets/img/svg/service-hero.svg" data-svg_idle="sc:transparent;sw:0;sda:0;sdo:0;" data-responsive_offset="on" data-frames='[{"delay":0,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+-300","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 5, minWidth: '853px', maxWidth: '853px', color: '#ffffff'}}> </div>
                            {/* <!-- LAYER NR. 2 --> */}
                            <div className="tp-caption   tp-resizeme" id="slide-12-layer-2" data-x="['right','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','bottom','bottom','bottom']" data-voffset="['70','0','0','0']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on" data-frames='[{"delay":0,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+-300","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6}}><img src="./assets/img/undraw_resume_folder_2_arse.png" alt="" data-ww="['511px','511px','511px','266px']" data-hh="['478px','478px','478px','315px']" data-no-retina /> </div>
                            {/* <!-- LAYER NR. 3 --> */}
                            <div className="tp-caption   tp-resizeme" id="slide-12-layer-3" data-x="['left','center','center','center']" data-hoffset="['403','-180','-57','0']" data-y="['top','top','top','top']" data-voffset="['195','114','110','110']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":0,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+-300","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 7, whiteSpace: 'nowrap', fontSize: '48px', lineHeight: '60px', fontWeight: 400, color: '#202428', letterSpacing: '0px',fontFamily:'Poppins'}}>Brand Integrity Experts </div>
                            {/* <!-- LAYER NR. 4 --> */}
                            <div className="tp-caption   tp-resizeme" id="slide-12-layer-4" data-x="['left','left','left','center']" data-hoffset="['403','145','145','0']" data-y="['top','top','top','top']" data-voffset="['260','192','192','187']" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"delay":0,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+-300","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, whiteSpace: 'nowrap', fontSize: '48px', lineHeight: '60px', fontWeight: 600, color: '#2196f3', letterSpacing: '0px',fontFamily:'Poppins'}}>with Experience </div>
                            {/* <!-- LAYER NR. 5 --> */}
                            <div className="tp-caption   tp-resizeme" id="slide-12-layer-5" data-x="['left','left','left','center']" data-hoffset="['406','148','148','0']" data-y="['top','top','top','top']" data-voffset="['337','287','287','273']" data-width="['573','573','573','469']" data-height="none" data-whitespace="normal" data-type="text" data-responsive_offset="on" data-frames='[{"delay":0,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+-300","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 9, minWidth: '573px', maxWidth: '573px', whiteSpace: 'normal', fontSize: '20px', lineHeight: '32px', fontWeight: 400, color: '#5a6671', letterSpacing: '0px',fontFamily:'Poppins'}}>Our team has over 100 years of combined experience in the health and wellness, pharmaceutical, and authentication industries. </div>
                            {/* <!-- LAYER NR. 6 --> */}

                        </li>
                    </ul>
                    <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}}></div>
                </div>
            </div>
        </Fragment>
    )
}
export default SinglePage2;

import React from 'react';
import Card from "../element/card/card-style-10";
const Expert = (props) => {
    return (
        <section className={props.padding ? props.padding : "p-top-100 p-bottom-40"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-bottom-50">
                            <div className="divider text-center">
                                <h1 className="color-dark">Expertise Areas</h1>
                                <p className="mx-auto">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius Clarias estre etiam pro cessus dynamicus.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Card />
        </section>
    )
}
export default Expert;


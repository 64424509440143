import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
const noAction = (e) => {
    e.preventDefault();
}
const FooterItem = (props) => {
    return (
        <Fragment>
            <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-lg-center">
                <div className="widget widget--links">
                    <h4 className={ props.color !== "black" ? "widget__title2" : "widget__title" }>Company</h4>
                    <ul className="links">
                        <li><NavLink to="/about" >About Us</NavLink></li>
                        <li><NavLink to="/contact" >Contact Us</NavLink></li>
                        <li><a href="https://trumarks.org" >Trumarks</a></li>
                        <li><NavLink to="/track-and-trace" >Services</NavLink></li>
                    </ul>
                </div>{/*<!-- ends: .widget -->*/}
            </div>{/*<!-- ends: .col-lg-3 -->*/}

        </Fragment>
    )
}

export default FooterItem;

import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import ContentAbove from '../../content/contentBlock/content-above';


class SplittedBanners extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Splitted Banners " />
            <ContentAbove />
            <Footer />
        </Fragment>
      )
    }
}
export default SplittedBanners

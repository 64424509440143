import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import { ApplicationForm } from '../content/element/form/registration';


const Project = () => {
    return (
        <Fragment>

            <Header pageTitle='Submit Resume & Apply'/>
            <section className="apply-form p-top-100 p-bottom-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="apply-form--header">
                                <h3>Apply For This Potition</h3>
                                <p>Submit your resume below to apply for the job <span>"Account Business Manager"</span>.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ApplicationForm />
            </section>{/* ends: section */}
            <Footer />
        </Fragment>
    )
}

export default Project;
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import {AccordianOne} from '../content/element/accordians/accordians';

const noAction = e => e.preventDefault();
const Project = () => {
    return (
        <Fragment>

            <Header pageTitle='FAQs'/>
            <section className="sectionbg p-top-100 p-bottom-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-5">
                                <div className="divider divider-simple text-center">
                                    <h3>About Our Services</h3>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .col-lg-12 -->*/}
                    </div>
                </div>
                <AccordianOne />
            </section>{/*<!-- ends: .section-padding -->*/}

            <section className="p-top-100 p-bottom-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-5">
                                <div className="divider divider-simple text-center">
                                    <h3>About Our Pricing</h3>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .col-lg-12 -->*/}
                    </div>
                </div>
                <div className="faqs-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="faq-single">
                                    <h6>1. When can we get started?</h6>
                                    <p>Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius. Clarias etiam pro cessus dynamicus.</p>
                                </div>
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                            <div className="col-lg-6">
                                <div className="faq-single">
                                    <h6>2. How do I go about conducting market research?</h6>
                                    <p>Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius. Clarias etiam pro cessus dynamicus.</p>
                                </div>
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                            <div className="col-lg-6">
                                <div className="faq-single">
                                    <h6>3. What do I need in order to start selling?</h6>
                                    <p>Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius. Clarias etiam pro cessus dynamicus.</p>
                                </div>
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                            <div className="col-lg-6">
                                <div className="faq-single">
                                    <h6>4. What other help is available?</h6>
                                    <p>Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius. Clarias etiam pro cessus dynamicus.</p>
                                </div>
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                            <div className="col-lg-6">
                                <div className="faq-single">
                                    <h6>5. What other help is available?</h6>
                                    <p>Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius. Clarias etiam pro cessus dynamicus.</p>
                                </div>
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                            <div className="col-lg-6">
                                <div className="faq-single">
                                    <h6>6. How do I go about conducting market?</h6>
                                    <p>Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius. Clarias etiam pro cessus dynamicus.</p>
                                </div>
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                            <div className="col-lg-12 text-center m-top-20">
                                <NavLink to="/at_demo" onClick={noAction} className="btn btn-primary">More FAQs</NavLink>
                            </div>
                        </div>
                    </div>
                </div>{/*<!-- ends: .faqs-one -->*/}
            </section>{/*<!-- ends: .section-padding-sm -->*/}
            <Footer />
        </Fragment>
    )
}

export default Project;
import React from 'react';
import {NavLink} from 'react-router-dom';
import { Parallax } from 'react-parallax';
const Cta9 = () => {
    return (
            <Parallax
                bgImage={'./assets/img/cta1.jpg'}
                strength={400}
                className="cta-wrapper cta--one biz_overlay"
                renderLayer={percentage => (
                    <div
                        style={{
                            position: 'absolute',                            
                            left: '50%',
                            top: '50%',
                            width: percentage * 500,
                            height: percentage * 500,
                        }}
                    />
                )}
            >
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <span className="sub-heading color-secondary">Over a Century of Service</span>
                        <h2>Five basic beliefs that guide and encourage us to achieve lasting success for our clients.</h2>
                        <NavLink to="/contact" className="btn btn-light">Get In Touch</NavLink>
                    </div>
                </div>
            </div>
        </Parallax>
       
    )
}
export default Cta9;
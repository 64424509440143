import React, { Component } from 'react';
import ClientContent from '../../content/clients/bg-half-secondary';

import {connect} from 'react-redux';



class Bg_half extends Component {
    render() {
        const {client} = this.props;
        return (
            <div className={this.props.bg ? this.props.bg : "bg-half-secondary"}>            
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        
                            <div className="logo-grid-one rounded">
                                <ul className="d-flex flex-wrap">
                                    {
                                        Object.values(client).map((value, key) => {
                                            return (
                                                <ClientContent 
                                                    key={value.id} 
                                                    img={value.imgSrc}
                                                />
                                            )
                                        })
                                    }                                    
                                </ul>
                            </div>
                        </div>
                    </div>{/*<!-- ends: .row -->*/}
                </div>{/*<!-- ends: .container -->*/}
            </div>
        )
    }
}
const mapStateToProps = (state) => {    
    
    return {
        client : state.client[0].clients
    }
}
export default connect(mapStateToProps)(Bg_half)

import React, {Fragment} from 'react';
import Navbar from '../navbar/transNav-2';
import Slider from '../slider/revollutionSlider-2';

const FullHeightHeader2 = () => {      
    return (
        <Fragment>
            <section className="intro-area-two">
                <div className="">                    
                    <Navbar />
                    <Slider />
                    {/* <!-- ends: .intro-area-11 --> */}
                </div>
            </section>
            {/* <!-- ends: .intro-area --> */}
        </Fragment>       
                      
    )   
}


export default FullHeightHeader2;




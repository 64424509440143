import React, { Component } from 'react'
import BlogDetails from './detailsContent-gallery';
import RightSideBar from './rightSidebar';
export default class Blog extends Component {
    render() {
        const {content, blog} = this.props;
        return (
            <section className="blog-single-wrapper section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8">
                            <BlogDetails {...content} />
                        </div>{/*<!-- ends: .col-lg-8 -->*/}
                        <RightSideBar {...blog} />                        
                    </div>
                </div>
            </section>
        )
    }
}

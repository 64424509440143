import React from 'react';

const TestimonialFourCon = (props) => {
    const {img, name, content} = props;
    return (
        <div className="carousel-single text-center">
            <img src={img} alt="" className="rounded-circle" />
            <h6>{name}</h6>
            <span className="sub-text">Customer Relations</span>
            <p>{content}</p>            
        </div> 
        // <!-- end: .carousel-single -->
    )
}
export default TestimonialFourCon;
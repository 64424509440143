import React, {Component, Fragment} from 'react';
import NavItem from "./navItem";
import {NavLink} from 'react-router-dom';
class Nabbar extends Component {
    
        render() {        
                    
          return (
            <Fragment>
                <section className="header header--8">
                    {/* <!-- start menu area --> */}
                    <div className="menu_area menu8 menu--sticky">
                        <div className="container">            
                            <nav className="navbar navbar-expand-lg navbar-light px-0 ">
                                <NavLink className="navbar-brand order-sm-1 order-1" to="/"><img src="./assets/img/logo.png" alt="" /></NavLink>
                                <NavItem />                    
                            </nav>
                        </div>
                    </div>
                    {/* <!-- end menu area --> */}
                </section>
                {/* <!-- end: .header --> */}
            </Fragment>
        );
    }
}
export default Nabbar;
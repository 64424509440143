import React from 'react';
import {NavLink} from 'react-router-dom';

const noAction = (e) => {
    e.preventDefault();
}
const News = (props) => {
    const {img, title, content, date, industry, id} = props;
    return (
        <div className="col-lg-4 col-sm-6">                                    
            <div className="card post--card post--card4">
                <figure>
                    <img src={img} alt="" />
                </figure>
                <div className="card-body">
                    <h6><NavLink to={`/news-details${id}`} > {title} </NavLink></h6>
                    <p>{content}</p>
                    <ul className="post-meta d-flex m-top-20">
                        <li>{date}</li>
                        <li>in <NavLink to={`/news-details:${id}`} onClick={noAction}>{industry}</NavLink></li>
                    </ul>
                </div>
            </div>
            {/*<!-- End: .card -->*/}
        </div>       
    )
}

export default News;
import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Card from '../container/element/card/card-style-10';
import Cta from '../content/cta/cta-sm-2';


const Service = () => {
    return (
        <Fragment>

            <Header pageTitle='Business Services'/>
            <section className="p-top-110 p-bottom-40">
                <Card />
                <Card />
                <Card />
            </section>
            <Cta />
            <Footer />
        </Fragment>
    )
}

export default Service;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import VideoWraper from '../../content/contentBlock/video-wraper';
import ContentBlockService from '../../content/contentBlock/contentBlock-service';

import ContentBlock3 from '../../content/contentBlock/contentBlock-3';
import ContentBlock5 from '../../content/contentBlock/contentBlock-feature-box';
import ContentBlock6 from '../../content/contentBlock/contentBlock-6';
import ContentAbove from '../../content/contentBlock/content-above-2';
import ContentBlock11 from '../../content/contentBlock/contentBlock-11';
import ContentBlock12 from '../../content/contentBlock/contentBlock-12';
import ContentBlock14 from '../../content/contentBlock/contentBlock-14';
import ContentBlock15 from '../../content/contentBlock/contentBlock-15';


const ContentBlock = () => {
    return (
        <Fragment>

            <Header pageTitle="Content Block" />

             {/* Content Block - 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <VideoWraper padding="p-bottom-0" />
            {/* Content Block - 1 */}

            {/* Content Block - 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlock3 />
            {/* Content Block - 2 */}

            {/* Content Block - 3 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlockService padding="p-bottom-0 p-top-0" />
            {/* Content Block - 3 */}

            {/* Content Block - 4 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlock5 />
            {/* Content Block - 4 */}

            {/* Content Block - 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlock6 />
            {/* Content Block - 5 */}

            {/* Content Block - 6 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentAbove />
            {/* Content Block - 6 */}

            {/* Content Block - 7 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlock11 />
            {/* Content Block - 7 */}

            {/* Content Block - 8 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 8</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlock12 padding="p-bottom-0 p-top-0" />
            {/* Content Block - 8 */}

            {/* Content Block - 9 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 9</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlock14 />
            {/* Content Block - 9 */}

            {/* Content Block - 10 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Content Block - 10</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContentBlock15 padding="content-block content-block--15 pt-0" />
            {/* Content Block - 10 */}

            <Footer />
        </Fragment>
    )
}

export default ContentBlock;
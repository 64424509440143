import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

export const AccordianFour = () => {
    return (
        <div className="col-lg-6 offset-lg-1">
            <div className="accordion accordion_four" id="accordion_four">
                <div className="accordion-single">
                    <div className="accordion-heading" id="accordion_four_heading1">
                        <h6 className="mb-0">
                            <NavLink to="/" className="bg-gray-light" data-toggle="collapse" data-target="#accordion_four_collapse1" aria-expanded="true" aria-controls="accordion_four_collapse1">
                                <i className="la la-users"></i> The Public
                            </NavLink>
                        </h6>
                    </div>
                    <div id="accordion_four_collapse1" className="collapse show" aria-labelledby="accordion_four_heading1" data-parent="#accordion_four">
                        <div className="accordion-contents">
                            <p>The public should trust that they are consuming safe cannabis products.</p>
                        </div>
                    </div>
                </div>{/*<!-- Ends: .accordion-single -->*/}
                <div className="accordion-single">
                    <div className="accordion-heading" id="accordion_four_heading2">
                        <h6 className="mb-0">
                            <NavLink to="/" className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_four_collapse2" aria-expanded="false" aria-controls="accordion_four_collapse2">
                                <i className="la la-building"></i> Cannabis Producers
                            </NavLink>
                        </h6>
                    </div>
                    <div id="accordion_four_collapse2" className="collapse" aria-labelledby="accordion_four_heading2" data-parent="#accordion_four">
                        <div className="accordion-contents">
                            <p>Producers should have tools to protect their brand and product integrity.</p>
                        </div>
                    </div>{/*<!-- Ends: .collapse -->*/}
                </div>{/*<!-- Ends: .accordion-single -->*/}
                <div className="accordion-single">
                    <div className="accordion-heading" id="accordion_four_heading3">
                        <h6 className="mb-0">
                            <NavLink to="/" className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_four_collapse3" aria-expanded="false" aria-controls="accordion_four_collapse3">
                                <i className="la la-briefcase"></i>The State
                            </NavLink>
                        </h6>
                    </div>
                    <div id="accordion_four_collapse3" className="collapse" aria-labelledby="accordion_four_heading3" data-parent="#accordion_four">
                        <div className="accordion-contents">
                            <p>Should be equipped to act transparently and enforce reasonable controls that help the industry grow.</p>
                        </div>
                    </div>{/*<!-- End: .collapse -->*/}
                </div>{/*<!-- Ends: .accordion-single -->*/}
            </div>{/*<!-- Ends: #accordion_four -->*/}
        </div>
    )
}

export const AccordianFour2nd = () => {
    return (

        <div className="accordion accordion_four" id="accordion_4_2">
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_4_2_heading1">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="bg-gray-light" data-toggle="collapse" data-target="#accordion_4_2_collapse1" aria-expanded="true" aria-controls="accordion_4_2_collapse1">
                  <i className="la la-cog" /> Our Standards of Business Conduct
                </NavLink>
              </h6>
            </div>
            <div id="accordion_4_2_collapse1" className="collapse show" aria-labelledby="accordion_4_2_heading1" data-parent="#accordion_4_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_4_2_heading2">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_4_2_collapse2" aria-expanded="false" aria-controls="accordion_4_2_collapse2">
                  <i className="la la-bar-chart" /> Our Supplier Code of Conduct
                </NavLink>
              </h6>
            </div>
            <div id="accordion_4_2_collapse2" className="collapse" aria-labelledby="accordion_4_2_heading2" data-parent="#accordion_4_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_4_2_heading3">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_4_2_collapse3" aria-expanded="false" aria-controls="accordion_4_2_collapse3">
                  <i className="la la-briefcase" /> Our Business Conduct Office
                </NavLink>
              </h6>
            </div>
            <div id="accordion_4_2_collapse3" className="collapse" aria-labelledby="accordion_4_2_heading3" data-parent="#accordion_4_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* End: .collapse */}
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_4_2_heading4">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_4_2_collapse4" aria-expanded="false" aria-controls="accordion_4_2_collapse4">
                  <i className="la la-headphones" /> Supporting Our People
                </NavLink>
              </h6>
            </div>
            <div id="accordion_4_2_collapse4" className="collapse" aria-labelledby="accordion_4_2_heading4" data-parent="#accordion_4_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* End: .collapse */}
          </div>{/* Ends: .accordion-single */}
        </div>
      )
}

export const AccordianOne = () => {
    return (
        <div className="accordion-styles accordion--one">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="accordion accordion_one" id="accordion_one">
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingOne">
                                    <h6 className="mb-0">
                                        <NavLink to="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Digital Ecosystem Management - The New Way for Insurers to Grow
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div>{/*<!-- Ends: .collapseOne -->*/}
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingTwo">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Digitalization and Regulation Drive Tejarat Agenda
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div>
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingThree">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Insurance IT Transformation – Enabling Digital Change
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div>{/*<!-- Ends: .collapseOne -->*/}
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingFour">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            The Power of an Omnichannel
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div>{/*<!-- Ends: .collapseOne -->*/}
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingFive">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Insurance IT Transformation – Enabling Digital Change
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div>{/*<!-- Ends: .collapseOne -->*/}
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                        </div>{/*<!-- Ends: #accordion_one -->*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AccordianTwo = () => {
    return (
        <div className="col-lg-7">
            <div className="accordion accordion_two" id="accordion_two">
                <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_1">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} data-toggle="collapse" data-target="#collapse2_1" aria-expanded="true" aria-controls="collapse2_1">
                            1. What industries do you specialize in?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_1" className="collapse show" aria-labelledby="heading2_1" data-parent="#accordion_two">
                        <div className="accordion-contents">
                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                            averunt lectores legere me liusked quod kequa legunt saepius.</p>
                        </div>
                    </div>{/* Ends: .collapseOne */}
                    </div>{/* Ends: .accordion-single */}
                    <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_2">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_2" aria-expanded="false" aria-controls="collapse2_2">
                            2. Can you help us raise money?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_2" className="collapse" aria-labelledby="heading2_2" data-parent="#accordion_two">
                        <div className="accordion-contents">
                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                            Sequitur mutatin onem consuetudium.</p>
                        </div>
                    </div>{/* Ends: .collapseOne */}
                    </div>{/* Ends: .accordion-single */}
                    <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_3">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_3" aria-expanded="false" aria-controls="collapse2_3">
                            3. What strategic and commercial approach?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_3" className="collapse" aria-labelledby="heading2_3" data-parent="#accordion_two">
                        <div className="accordion-contents">
                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                            Sequitur mutatin onem consuetudium.</p>
                        </div>
                    </div>{/* Ends: .collapse */}
                    </div>{/* Ends: .accordion-single */}
                    <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_4">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_4" aria-expanded="false" aria-controls="collapse2_4">
                            4. What industries do you specialize in?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_4" className="collapse" aria-labelledby="heading2_4" data-parent="#accordion_two">
                        <div className="accordion-contents">
                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                            Sequitur mutatin onem consuetudium.</p>
                        </div>
                    </div>{/* Ends: .collapse */}
                </div>{/* Ends: .accordion-single */}
            </div>{/* Ends: #accordion_two */}
        </div>
    )
}

export const AccordianThree = () => {
    return (
        <div className="accordion accordion_three" id="accordion_three">
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_three_heading1">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} data-toggle="collapse" data-target="#accordion_threecollapse1" aria-expanded="true" aria-controls="accordion_threecollapse1">
                  Management and Technology Consulting
                </NavLink>
              </h6>
            </div>
            <div id="accordion_threecollapse1" className="collapse show" aria-labelledby="accordion_three_heading1" data-parent="#accordion_three">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_three_heading2">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed " data-toggle="collapse" data-target="#accordion_threecollapse2" aria-expanded="false" aria-controls="accordion_threecollapse2">
                  Software Solutions
                </NavLink>
              </h6>
            </div>
            <div id="accordion_threecollapse2" className="collapse" aria-labelledby="accordion_three_heading2" data-parent="#accordion_three">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_three_heading3">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed " data-toggle="collapse" data-target="#accordion_threecollapse3" aria-expanded="false" aria-controls="accordion_threecollapse3">
                  Support Services
                </NavLink>
              </h6>
            </div>
            <div id="accordion_threecollapse3" className="collapse" aria-labelledby="accordion_three_heading3" data-parent="#accordion_three">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_three_heading4">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed " data-toggle="collapse" data-target="#accordion_threecollapse4" aria-expanded="false" aria-controls="accordion_threecollapse4">
                  Friendly and Engaging Team
                </NavLink>
              </h6>
            </div>
            <div id="accordion_threecollapse4" className="collapse" aria-labelledby="accordion_three_heading4" data-parent="#accordion_three">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
        </div>
      );
}

export const AccordianThree2nd = () => {
    return (

        <div className="accordion accordion_three" id="accordion_3_2">
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_3_2_heading1">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="bg-gray-light" data-toggle="collapse" data-target="#accordion_3_2collapse1" aria-expanded="true" aria-controls="accordion_3_2collapse1">
                  Management and Technology Consulting
                </NavLink>
              </h6>
            </div>
            <div id="accordion_3_2collapse1" className="collapse show" aria-labelledby="accordion_3_2_heading1" data-parent="#accordion_3_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_3_2_heading2">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_3_2collapse2" aria-expanded="false" aria-controls="accordion_3_2collapse2">
                  Software Solutions
                </NavLink>
              </h6>
            </div>
            <div id="accordion_3_2collapse2" className="collapse" aria-labelledby="accordion_3_2_heading2" data-parent="#accordion_3_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_3_2_heading3">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_3_2collapse3" aria-expanded="false" aria-controls="accordion_3_2collapse3">
                  Support Services
                </NavLink>
              </h6>
            </div>
            <div id="accordion_3_2collapse3" className="collapse" aria-labelledby="accordion_3_2_heading3" data-parent="#accordion_3_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_3_2_heading4">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_3_2collapse4" aria-expanded="false" aria-controls="accordion_3_2collapse4">
                  Friendly and Engaging Team
                </NavLink>
              </h6>
            </div>
            <div id="accordion_3_2collapse4" className="collapse" aria-labelledby="accordion_3_2_heading4" data-parent="#accordion_3_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
        </div>
      )
}

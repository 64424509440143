import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { BlockquoteTwo, BlockquoteThree, BlockquoteFour, BlockquoteFive } from '../../content/element/blockquote/blockquote';


const Blockquotes = () => {
    return (
        <Fragment>

            <Header pageTitle="Blockquotes" />
            {/* Blockquote - 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Blockquote - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="m-bottom-0">
                <div className="quotes-wrapper blockquote--one">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/*<!-- start blockquote -->*/}
                                <blockquote className="blockquote blockquote3">
                                    <p>Crocal has the best responsive function on the market is very fast, good adaptable and easy to handle. Support responds quickly and is helpful lorem ipsum dolor sit amet.</p>
                                    <div className="quote-author">
                                        <p><span>Jeff Collins,</span> Founder of Tejarat Inc.</p>
                                    </div>
                                </blockquote>{/*<!-- end: blockquote -->*/}
                            </div>
                        </div>
                    </div>
                </div>{/*<!-- ends: .quotes-wrapper -->*/}
            </section>
            {/* Blockquote - 1 */}

            {/* Blockquote - 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Blockquote - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <BlockquoteTwo />
            {/* Blockquote - 2 */}

            {/* Blockquote - 3 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Blockquote - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <BlockquoteThree />
            {/* Blockquote - 3 */}

            {/* Blockquote - 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Blockquote - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <BlockquoteFour />
            {/* Blockquote - 4 */}

            {/* Blockquote - 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Blockquote - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <BlockquoteFive />
            {/* Blockquote - 5 */}

            <Footer />
        </Fragment>
    )
}

export default Blockquotes;
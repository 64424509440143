import React from 'react';

const Testimonials = (props) => {
    const {img, name, subtitle, content} = props;
    return (
        <div className="carousel-single">
            <div className="card card-shadow card--testimonial2">
                <div className="card-body text-center">
                    <img src={img} alt="" className="rounded-circle" />
                    <h6>{subtitle}</h6>
                    <div className="ratings color-warning">
                        <span><i className="la la-star"></i></span>
                        <span><i className="la la-star"></i></span>
                        <span><i className="la la-star"></i></span>
                        <span><i className="la la-star"></i></span>
                        <span><i className="la la-star"></i></span>
                    </div>
                    <p>{content.split(' ').slice(0, 20).join(' ')}</p>
                    <p className="author-spec"><strong>{name},</strong> <span className="color-secondary">Aazztech</span></p>
                </div>
            </div>{/*<!-- End: .card-body -->*/}
        </div>         
    )
}
export default Testimonials;
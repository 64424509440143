import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
const TestimonialFourCon = (props) => {   
    return (
        <div className="feed-single text-center">
            <div className="feed-header">
                <span><i className="la la-twitter"></i></span>
                <p>
                    <span className="user">@Aazztech</span>
                    <span className="time"> - 3 Weeks Ago</span>
                </p>
            </div>{/*<!-- ends: .feed-header -->*/}
            <div className="feed-content">
                <p>Praesent is pharetra justo ught scel erihy sque the mattis lhreo there justo quam. Praesent is pharetra justo ught scel erihy sque the mattis lhreo justo quam sque mattis lhreo justo quam praesent is pharetra.</p>
                <div>
                    <NavLink to="/" onClick={noAction} className="twit-link">https://t.co/rI1M2JlyZG</NavLink>
                    <span className="tags"> #business #consulting #finace</span>
                </div>
            </div>{/*<!-- ends: .feed-header -->*/}
        </div>
    )
}
export default TestimonialFourCon;
import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


class VideoBg extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Video Background" />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Youtube Video Background</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="video-background player biz_overlay overlay--secondary" id="bgndVideo" data-property="{videoURL:'https://youtu.be/BsekcY04xvQ',containment:'self',startAt:50,mute:true,autoPlay:true,loop:false,opacity:.8,showControls:false}">
                    <div className="video-content content_above">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="text-center">
                                        <h2>Here's a local video being used as a background</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/*<!-- ends: .video-background -->*/}
            <Footer />
        </Fragment>
      )
    }
}
export default VideoBg

import React, { Component } from 'react';
import TestimonialTwoCont from '../../content/testimonial/twitter-feeds';
import {connect} from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
const options = {
    items: 1,
    autoplay: true,
    loop: true,
    nav: false,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
}

class TwitterFeeds2 extends Component {
    render() {
        const {testimonial} = this.props
        return (
            <section className={this.props.classes ? this.props.classes : "twitter-feeds twitter-feeds--light"}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <OwlCarousel options={options} className="twitter-feeds-carousel2 owl-carousel">
                           {
                                Object.values(testimonial).map((value, key) => {
                                    return <TestimonialTwoCont
                                        name={value.name}
                                        subtitle={value.subTitle}
                                        content={value.content.split(' ').slice(0, 20).join(' ')}
                                        img={value.imgSrc}
                                        key={key+1}

                                    />
                                })
                            }
                        </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        testimonial : state.testimonial
    }
}
export default connect(mapStateToProps)(TwitterFeeds2)

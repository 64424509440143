import React, { Component } from 'react';
import TestimonialTwoCont from '../../content/testimonial/testimonial-2';
import {connect} from 'react-redux';

class TestimonialTwo extends Component {
    render() {
        const {testimonial} = this.props               
        return (
            <div className="container">
                <div className="row">
                    {
                        Object.values(testimonial).map((value, key) => {                                                                                                        
                            return <TestimonialTwoCont 
                            name={value.name} 
                            subtitle={value.subTitle} 
                            content={value.content.split(' ').slice(0, 20).join(' ')} 
                            img={value.imgSrc}
                            key={key+1}
                            
                                />
                        })
                    }
                </div>
                
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    
    return {
        testimonial : state.testimonial
    }
}
export default connect(mapStateToProps)(TestimonialTwo)
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const Dividers = () => {
    return (
        <Fragment>

            <Header pageTitle="Dividers" />
            <section className="dividers p-bottom-110">
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Dividers - 1</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="divider text-left">
                                <h3 className="color-dark">Forming the Future of Finance</h3>
                                <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro sequitur mutay tionem consue tudium torum. Mirum est notare quam littera gothica quam.</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Dividers - 2</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="divider text-center">
                                <h1 className="color-dark">Business Services</h1>
                                <p className="mx-auto " />
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Dividers - 3</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="divider text-left">
                                <h3 className="color-dark">What They Said</h3>
                                <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro keds sequitur mutay tionem consue tudium torum. Mirum est notare quam littera gothica quam.</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Dividers - 4</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="divider text-center">
                                <h1 className="color-dark">What They Said</h1>
                                <p className="mx-auto">Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro sequitur mutay tionem consue tudium torum. Mirum est notare quam littera gothica quam.</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Dividers - 5</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mb-5">
                            <div className="divider text-left">
                                <h1 className="color-dark">Why Choose Tejarat</h1>
                                <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro sequitur mutay tionem consue tudium torum. Mirum est notare quam littera gothica quam.</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Divider - 6</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="divider text-left">
                                <span className="subtext">Client Story</span>
                                <h3>The Untold Story</h3>
                                <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro sequitur mutay tionem consue</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Divider - 7</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="divider divider-simple text-left">
                                <h3>What They Said</h3>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Divider - 8</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="divider divider-simple text-center">
                                <h3>Business Services</h3>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Divider Light</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 bg-secondary py-5">
                            <div className="divider text-center">
                                <h1 className="color-light">Why Choose Bizillion</h1>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Divider Secondary</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="divider text-center">
                                <h1>Business Services</h1>
                                <p className="mx-auto">Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro sequitur mutay tionem consue</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Divider Without Line</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="divider section-title-simple">
                                <h1>Why Choose Bizillion</h1>
                                <p className="m-auto mb-0">Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro sequitur mutay tionem consue</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="showcase showcase--title1">
                                        <h3>Divider Without Line (Light)</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 bg-secondary py-5">
                            <div className="divider text-center">
                                <h1 className="color-light p-bottom-10">Why Choose Bizillion</h1>
                                <p className="color-light m-auto mb-0">Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro sequitur mutay tionem consue</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default Dividers;
import React, { Fragment } from 'react';

import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Filter from '../container/element/filter/filter';

const Project = () => {
    return (
        <Fragment>

            <Header pageTitle='Project Grid 3 Column Filter'/>
            <section className="project-grid-3 section-padding2 section-bg">
                <div className="filter-wrapper">
                    <div className="container">
                        <Filter />
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default Project;
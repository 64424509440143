import React, {Fragment} from 'react';

import Navbar from '../layout/navbar/colorNav';
import TopHeader from '../layout/header/topHeader';
import { NavLink } from "react-router-dom";
import Map1 from '../content/element/map/map-2';
import Subscrib5 from '../content/subscribe/subscribe-5';
import Footer from '../layout/footer/footer-5';
const CareerSingle = (props) => {
    return (
        <Fragment>

            <section className="header header--2">
               <TopHeader />
                <Navbar />
            </section>
            <section className="breadcrumb_area breadcrumb3 bgimage biz_overlay">
                <div className="bg_image_holder">
                    <img src="./assets/img/breadbg2.jpg" alt="" />
                </div>
                <div className="container content_above">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex flex-column align-items-center">
                                <h4 className="page_title">Account Business Manager</h4>
                                <div className="action-btns m-top-35">
                                    <ul className="d-flex">
                                        <li><NavLink to='/apply' className="btn btn-secondary m-right-5">Apply Now</NavLink></li>
                                        <li><a href=' ' className="btn btn-primary btn-icon-only m-right-5"><i className="la la-heart-o" /></a></li>
                                        <li className="share-btn"><a href=' ' className="btn btn-primary btn-icon-only"><i className="la la-share-alt" /></a>
                                            <ul>
                                                <li><a href=' '><i className="fab fa-facebook-f" /></a></li>
                                                <li><a href=' '><i className="fab fa-twitter" /></a></li>
                                                <li><a href=' '><i className="fab fa-linkedin-in" /></a></li>
                                                <li><a href=' '><i className="fab fa-google-plus-g" /></a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>{/* ends: .action-btns */}
                            </div>{/* ends: .breadcrumb_wrapper */}
                        </div>
                    </div>{/* ends: .row */}
                </div>
            </section>{/* ends: .breadcrumb_area */}
            <section className="information-box p-top-140 p-bottom-60">
                <div className="information-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card shadow-lg-2 border-0">
                                    <div className="card-header">
                                        <h5 className="m-bottom-0">Quick Information</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <ul>
                                                    <li className="d-flex">
                                                        <span><i className="la la-user" /> Requisition ID:</span>
                                                        <span>1657364</span>
                                                    </li>
                                                    <li className="d-flex">
                                                        <span><i className="la la-calendar-check-o" /> Posting Date:</span>
                                                        <span>12/08/2019</span>
                                                    </li>
                                                </ul>
                                            </div>{/* ends: .col-lg-4 */}
                                            <div className="col-lg-4">
                                                <ul>
                                                    <li className="d-flex">
                                                        <span><i className="la la-tag" /> Category:</span>
                                                        <span>Services</span>
                                                    </li>
                                                    <li className="d-flex">
                                                        <span><i className="la la-map-marker" /> Location:</span>
                                                        <span>Pontiac, Michigan, United States</span>
                                                    </li>
                                                </ul>
                                            </div>{/* ends: .col-lg-4 */}
                                            <div className="col-lg-4">
                                                <ul>
                                                    <li className="d-flex">
                                                        <span><i className="la la-clock-o" /> Schedule:</span>
                                                        <span>Full Time</span>
                                                    </li>
                                                    <li className="d-flex">
                                                        <span><i className="la la-briefcase" /> Employee Status:</span>
                                                        <span>Day Job</span>
                                                    </li>
                                                </ul>
                                            </div>{/* ends: .col-lg-4 */}
                                        </div>
                                    </div>
                                </div>{/* ends: .card */}
                            </div>{/* ends: .col-lg-12 */}
                        </div>
                    </div>
                </div>
            </section>{/* ends: .information-box */}

            <section className="job-contents p-top-20 p-bottom-65">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Job Description</h3>
                            <p>We can offer you a wide range of opportunities to build a varied and rewarding career.We offer attractive careers for talented and ambitious professionals from a wide range of backgrounds, with different skills, capabilities. Project managers ensure that a project is completed on time and within budget, that the project's objectives are met and that everyone is doing their job properly. Projects are usually separate to usual day-to-day business activities and require a group of people to work together to achieve a set of specific objectives. Project managers over see the project to ensure the desired result is achieved, the most efficient resources are used and the different interests involved are satisfied.</p>
                            <div className="hr-divider2" />
                            <h3>Key Responsibilities</h3>
                            <p>We can offer you a wide range of opportunities to build a varied and rewarding career.We offer attractive careers for talented and ambitious professionals from a wide range of backgrounds, with different skills, capabilities. </p>
                            <div className="m-top-25">
                                <ul className="bullet--list2">
                                <li className="bullet_list">Build next-generation web applications with a focus on the client</li>
                                <li className="bullet_list">Redesign UI’s, implement new UI’s, and pick up Java as necessary.</li>
                                <li className="bullet_list">Explore and design dynamic compelling consumer experiences.</li>
                                <li className="bullet_list">Design and build scalable framework for web applications.</li>
                                </ul>{/* ends: .bullet--list2 */}
                            </div>
                            <div className="hr-divider2" />
                            <h3>Qualifications / Requirements</h3>
                            <p>We can offer you a wide range of opportunities to build a varied and rewarding career.We offer attractive careers for talented and ambitious professionals from a wide range of backgrounds, with different skills, capabilities. </p>
                            <div className="m-top-25">
                                <ul className="bullet--list2">
                                <li className="bullet_list">Build next-generation web applications with a focus on the client</li>
                                <li className="bullet_list">Redesign UI’s, implement new UI’s, and pick up Java as necessary.</li>
                                <li className="bullet_list">Explore and design dynamic compelling consumer experiences.</li>
                                <li className="bullet_list">Design and build scalable framework for web applications.</li>
                                </ul>{/* ends: .bullet--list2 */}
                            </div>
                            <div className="hr-divider2" />
                            <div className="action-btns">
                                <ul className="d-flex">
                                <li><a href="/apply" className="btn btn-primary m-right-5">Apply Now</a></li>
                                <li><a href=" " className="btn btn-secondary m-right-5">Apply With Facebook</a></li>
                                <li><a href=" " className="btn btn-secondary m-right-5 btn-icon-only"><i className="la la-heart-o" /></a></li>
                                <li className="share-btn"><a href=" " className="btn btn-secondary btn-icon-only"><i className="la la-share-alt" /></a>
                                    <ul>
                                    <li><a href=" "><i className="fab fa-facebook-f" /></a></li>
                                    <li><a href=" "><i className="fab fa-twitter" /></a></li>
                                    <li><a href=" "><i className="fab fa-linkedin-in" /></a></li>
                                    <li><a href=" "><i className="fab fa-google-plus-g" /></a></li>
                                    </ul>
                                </li>
                                </ul>
                            </div>
                        </div>{/* ends: .col-lg-12 */}
                    </div>
                </div>
            </section>{/* ends: .job-contents */}

            <section className="p-bottom-110">
                <div className="google_map">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Map1 styles={{position: 'relative'}} />
                            </div>
                        </div>
                    </div>
                </div>{/* ends .google_map */}
            </section>{/* ends: section */}

            <section className="section-bg p-top-100 p-bottom-110">
                <div className="m-bottom-50">
                    <div className="divider text-center">
                        <h1>Related Positions</h1>
                        <p className="mx-auto" />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-one">
                                    <thead className="table-secondary table-secondary--darken">
                                        <tr>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>{/* ends: thead */}
                                    <tbody>
                                        <tr>
                                        <th scope="row">Account Business Manager</th>
                                            <td>Vienna, Austria</td>
                                            <td>Administrative</td>
                                            <td>Jan 08, 2019</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Cash Application and Collections Specialist</th>
                                            <td>Detroit, Michigan</td>
                                            <td>Marketing</td>
                                            <td>Mar 23, 2019</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Director of Financial Systems</th>
                                            <td>London, UK</td>
                                            <td>Finance</td>
                                            <td>Apr 09, 2019</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Account Business Manager</th>
                                            <td>Paris, France</td>
                                            <td>Support Manager</td>
                                            <td>Jun 10, 2019</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Cash Application and Collections Specialist</th>
                                            <td>Detroit, Michigan</td>
                                            <td>Senior Executive</td>
                                            <td>Sep 17, 2019</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Director of Financial Systems</th>
                                            <td>London, UK</td>
                                            <td>Accounting</td>
                                            <td>Nov 01, 2019</td>
                                        </tr>
                                    </tbody>{/* ends: tbody */}
                                </table>
                            </div>{/* ends: .table-responsive */}
                        </div>
                    </div>
                </div>
            </section>{/* ends: section */}
            <Subscrib5 />
            <Footer />
        </Fragment>
    )
}
export default CareerSingle;
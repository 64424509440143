import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
export const Login = () => {
    return (
        <div className="login-form d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                        <div className="form-wrapper">
                            <div className="card card-shadow">
                                <div className="card-header">
                                    <h4 className="text-center">Sign In Here!</h4>
                                </div>
                                <div className="card-body">
                                    <form action="#">
                                        <div className="form-group">
                                            <input type="text" placeholder="User Name" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" placeholder="Password" className="form-control" />
                                        </div>
                                        <div className="form-action d-flex justify-content-between">
                                            <div className="custom-control custom-checkbox checkbox-secondary">
                                                <input type="checkbox" className="custom-control-input" id="customCheck3" />
                                                <label className="custom-control-label" htmlFor="customCheck3">Remember Me</label>
                                            </div>
                                            <NavLink to="/at_demo" onClick={noAction} className="color-secondary">Lost your password?</NavLink>
                                        </div>
                                        <div className="form-group text-center m-bottom-20">
                                            <button onClick={noAction} className="btn btn-secondary" type="submit">Sign In</button>
                                        </div>
                                    </form>
                                    <p className="text-center m-bottom-25">Don't you have an account? <NavLink to="/register">Register</NavLink></p>
                                    <div className="d-flex other-logins justify-content-center">
                                        <NavLink to="/at_demo"><span className="fab fa-facebook-f"></span> Facebook</NavLink>
                                        <NavLink to="/at_demo"><span className="fab fa-google-plus-g"></span> Google</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
            </div>
        </div>
    )
}

import React, {Fragment} from 'react';

import Header from '../layout/header/single-product';
import Footer from '../layout/footer/footer-5';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Cart } from '../../Store/action/cartActions';
import { toast, ToastContainer } from 'react-toastify';

class ShopProduct extends React.Component {
    state = {
        product: this.props.cart.product,
        cart : null,
        total : null,
        cartCount : null,
        grandTotal: 0
    }
    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {

        setInterval(() => {
            var match = JSON.stringify({product: this.state.product});
            var match2 = localStorage.getItem('cart')
            if(match !== match2) {
                if(this._isMounted) {
                    this.setState({
                        product: JSON.parse(match2).product
                    }, () => this.props.addToCart({product: this.state.product}))
                }
            }

        }, 100)

        // cart item controle panel
        var cart_item = [];
        Object.values(this.state.product).map((value, key) => {
            return cart_item.push({ ['item'+key] : 1 })
        })

        // total price start
        var total_price = 0;
        Object.values(this.state.product).map((value, key) => {
            return total_price += parseInt( value[0].price) * parseInt( value[0].quantity);
        });

        // cart item increment
        const plus = (e) => {
            const plus_id  = e.target.getAttribute('plus_id');
            if(parseInt(this.state.product[plus_id][0].quantity) < 5)
            this.setState(prevState => {
                let product = Object.assign({}, prevState.product);
                product[plus_id][0].quantity = parseInt(this.state.product[plus_id][0].quantity)+1
                return { product }
            }, () => this.props.addToCart({product: this.state.product}));

        }
        // cart item increment end

        // cart item decrement
        const minus = (e) => {
            const minus_id  = e.target.getAttribute('minus_id');
            if(parseInt(this.state.product[minus_id][0].quantity) > 1)
            this.setState(prevState => {
                let product = Object.assign({}, prevState.product);
                product[minus_id][0].quantity = parseInt(this.state.product[minus_id][0].quantity)-1
                return { product }
            }, () => this.props.addToCart({product: this.state.product}));
        }


        // cart item decrement end
        const change = e => {
            e.preventDefault()
        }

        // all cart item clear
        const clear = e => {
            e.preventDefault();
            if(this._isMounted) {
                this.setState({
                    product: []
                }, () => this.props.addToCart({"product":[]}))
            }
            this.setState({total: total_price})
        }

        //cart item remove
        const remove = (e) => {
            e.preventDefault();
            this.setState({
                cartCount : e.currentTarget.getAttribute('data')
            }, () => {
                const code_item = Object.values(this.state.product).filter((item, key) => {
                    return key !== parseInt(this.state.cartCount);
                });
                this.setState(
                    { product: [...code_item] },
                    () => this.props.addToCart(this.state)
                )
                toast.error("Cart Item deleted!");
            })
        }
        // cart item controle panel end

        return (
            <Fragment>

                <ToastContainer />
                <Header pageTitle="Shopping Cart"/>
                <section className="cart-wrapper section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cart-table table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"><span>Product Name</span></th>
                                                <th scope="col"><span>Quantity</span></th>
                                                <th scope="col"><span>Price</span></th>
                                                <th scope="col"><span>Subtotal</span></th>
                                                <th scope="col"><span><NavLink to="/at_demo" onClick={clear} className="btn btn-sm btn--rounded btn-outline-danger">Clear Cart</NavLink></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.values(this.state.product).map((value, key) => {
                                                    const {imgSrc, title, price, id, quantity} = value[0]

                                                    return (
                                                        this.state.product ?
                                                        <tr key={key+1} className="cart-single ">
                                                            <th scope="row">
                                                                <div className="name">
                                                                    <img src={imgSrc} alt="" />
                                                                    <NavLink to={"/single-product"+id}>{title}</NavLink>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <div className="quantity text-right">
                                                                    <div className="total-item">
                                                                        <span className="minus" onClick={minus} minus_id={key} >-</span>
                                                                        <input type="text" onChange={change} value={ quantity } />
                                                                        <span className="plus" onClick={plus} plus_id={key}>+</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="price">
                                                                    <span>{'$'+price}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="subtotal">
                                                                    <span>{'$'+ quantity * price}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="remove text-center item_remove"><NavLink to="/" onClick={remove} data={key}><i className="la la-close"></i></NavLink></div>
                                                            </td>
                                                        </tr>

                                                        : <tr><td colSpan="4"><center><h1>Data not found!</h1></center></td></tr>
                                                    )
                                                })
                                            }
                                            <tr className="cart-bottom">
                                                <td colSpan="4">
                                                    <form action="#">
                                                        <input type="text" className="form-control" placeholder="Coupon Code" />
                                                        <button className="btn btn-primary">Apply Coupon</button>
                                                    </form>
                                                </td>
                                                <td colSpan="1">
                                                    <div className="total-amount text-right">
                                                        Total: <span>{this.state.grandTotal === 0 ? total_price : this.state.grandTotal}</span>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>{/*<!-- ends: .table -->*/}

                                    <div className="cart-actions m-top-50">
                                        <div>
                                            <button type="button" className="btn btn-outline-secondary btn-icon icon-left"><i className="la la-angle-double-left"></i> Back To Shopping</button>
                                        </div>
                                        <div className="ml-auto">
                                            <NavLink to="/at_demo" className="btn btn-secondary">Update Cart</NavLink>
                                            <NavLink to="/checkout" className="btn btn-primary">Checkout</NavLink>
                                        </div>
                                    </div>{/*<!-- ends: .cart-actions -->*/}
                                </div>{/*<!-- ends: .table-responsive -->*/}
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        product : state.product,
        cart : state.cart
    }
}

const mapDispatchToProp = dispatch => {
    return {
        addToCart : (prod) => dispatch(Cart(prod))
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(ShopProduct);
import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
export const SignUp = () => {
    return (
        <div className="signup-form d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="form-wrapper">
                            <div className="card card-shadow">
                                <div className="card-header">
                                    <h4 className="text-center">Signup Here!</h4>
                                </div>
                                <div className="card-body">
                                    <form action="#">
                                        <div className="form-group">
                                            <input type="text" placeholder="First Name" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" placeholder="Last Name" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" placeholder="Email" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" placeholder="Password" className="form-control" />
                                        </div>
                                        <div className="custom-control custom-checkbox checkbox-secondary">
                                            <input type="checkbox" className="custom-control-input" id="customCheck3" defaultChecked />
                                            <label className="custom-control-label" htmlFor="customCheck3">I agree with the all additional <NavLink to="/at_demo">Terms and Conditions</NavLink></label>
                                        </div>
                                        <div className="form-group text-center m-top-30 m-bottom-20">
                                            <button onClick={noAction} className="btn btn-secondary" type="submit">Sign In</button>
                                        </div>
                                    </form>
                                    <p className="text-center m-bottom-25">Have an account? <NavLink to="/login">Login</NavLink></p>
                                    <div className="d-flex other-logins justify-content-center">
                                        <NavLink to="/at_demo"><span className="fab fa-facebook-f"></span> Facebook</NavLink>
                                        <NavLink to="/at_demo"><span className="fab fa-google-plus-g"></span> Google</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
            </div>
        </div>
    )
}

export const WidgetFrom = () => {
    return (
    <div className="widget-content">
        <div className="signup-widget">
          <form action="#">
            <div className="form-group">
              <input type="text" placeholder="First Name" className="form-control" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <input type="text" placeholder="Last Name" className="form-control" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <input type="email" placeholder="Email" className="form-control" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <input type="text" placeholder="Company" className="form-control" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <input type="text" placeholder="Phone" className="form-control" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <div className="select-basic">
                <select className="form-control">
                  <option>Job Function</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                </select>
              </div>
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <div className="select-basic">
                <select className="form-control">
                  <option>Job Level</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                </select>
              </div>
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <div className="select-basic">
                <select className="form-control">
                  <option>Country</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                </select>
              </div>
            </div>{/* ends: .form-group */}
            <div className="form-group m-top-30">
              <div className="custom-control custom-checkbox checkbox-secondary">
                <input type="checkbox" className="custom-control-input" id="customCheck3" defaultChecked />
                <label className="custom-control-label" htmlFor="customCheck3">I agree with the all additional <NavLink to='/' onClick={noAction}>Terms and Conditions</NavLink></label>
              </div>
              <div className="text-center">
                <button className="btn btn-secondary">Join Now</button>
              </div>
            </div>{/* ends: .form-group */}
          </form>{/* ends: form */}
        </div>{/* ends: .signup-widget */}
    </div>
    )
}

export const ApplicationForm = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h4 className="border-bottom p-bottom-25 m-bottom-40">Personal Information</h4>
          <form action="#">
            <div className="form-group">
              <label>First Name<span className="sup">*</span></label>
              <input type="text" required className="form-control" placeholder="First Name" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Last Name<span className="sup">*</span></label>
              <input type="text" required className="form-control" placeholder="Last Name" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Email Address<span className="sup">*</span></label>
              <input type="email" required className="form-control" placeholder="Email" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Phone</label>
              <input type="text" required className="form-control" placeholder="Phone" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Address</label>
              <input type="text" required className="form-control" placeholder="Address" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>City</label>
              <input type="text" className="form-control" required placeholder="City" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>State</label>
              <div className="select-basic">
                <select className="form-control" required>
                  <option>Select an option</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                </select>
              </div>
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Country</label>
              <div className="select-basic">
                <select className="form-control" required>
                  <option>Select Country</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                </select>
              </div>
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Company Name</label>
              <input type="text" required className="form-control" placeholder="Company Name" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Professional Title</label>
              <input type="text" required className="form-control" placeholder="e.g. Web Developer" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Skills <span className="optional">(Optional)</span></label>
              <input type="text" className="form-control" placeholder="Comma separate a list of relevant skills" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Photo <span className="optional">(Optional)</span></label>
              <div className="custom-photo-upload">
                <input type="file" className="custom-upload" />
                <div className="custom-upload-box d-flex">
                  <div className="image-box">
                    <span className="icon"><i className="la la-image" /></span>
                    <span className="file-name"><i className="la la-plus" /> Add Image</span>
                  </div>
                  <div className="image-info">
                    <span>200 x 200 pixel</span>
                    <span>Max File Size: 200kb</span>
                  </div>
                </div>
              </div>
            </div>{/* ends: .form-group */}
            <h4 className="border-bottom p-bottom-25 m-bottom-50 m-top-70">Academic Information</h4>
            <div className="form-group">
              <label>University Name</label>
              <input type="text" required className="form-control" placeholder="University / School Name" />
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Degree Level</label>
              <div className="select-basic" >
                <select className="form-control" required>
                  <option>Select an option</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                  <option>Option 4</option>
                </select>
              </div>
            </div>{/* ends: .form-group */}
            <div className="form-group">
              <label>Grade Point Average</label>
              <input type="text" className="form-control" required placeholder="Grade Point" />
            </div>{/* ends: .form-group */}
            <h4 className="border-bottom p-bottom-25 m-bottom-50 m-top-70">Add Resume<span className="color-primary">*</span></h4>
            <div className="form-group">
              <div className="custom-upload">
                <input type="file" className="custom-upload" required/>
                <div className="custom-upload-box d-flex">
                  <div className="upload-btn">
                    <a href=' ' className="btn btn-primary btn-icon icon-right">Choose Resume <i className="la la-cloud-upload" /></a>
                  </div>
                  <div className="upload-info">
                    <span>200 x 200 pixel</span>
                    <span>Max File Size: 200kb</span>
                  </div>
                </div>
              </div>
            </div>{/* ends: .form-group */}
            <h4 className="border-bottom p-bottom-25 m-bottom-50 m-top-70">Social Profiles <span className="optional">(Optional)</span></h4>
            <ul className="d-flex social-btns">
              <li><a href=' ' className="btn btn-facebook"><span className="fab fa-facebook-f" /> Facebook</a></li>
              <li><a href=' ' className="btn btn-twitter"><span className="fab fa-twitter" /> Twitter</a></li>
              <li><a href=' ' className="btn btn-linkedin"><span className="fab fa-linkedin-in" /> Linkedin</a></li>
              <li><a href=' ' className="btn btn-gplus"><span className="fab fa-google-plus-g" /> Google</a></li>
            </ul>
            <h4 className="border-bottom p-bottom-25 m-bottom-50 m-top-70">Additional Messages</h4>
            <div id="text-editor" placeholder="Type your message here" />
            <div className="btns m-top-50">
              <button type="submit" className="btn btn-primary m-right-25">Apply Now</button>
              <button type="reset" className="btn btn-secondary">Cancel</button>
            </div>
          </form>
        </div>
      </div>{/* end: .row */}
    </div>
  )
}

import React, { Fragment } from 'react';

import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Card from '../container/element/card/card-grid-2';


const Project = () => {
    return (
        <Fragment>

            <Header pageTitle='Project Grid 2 Column'/>
            <section className="project-grid-2 section-padding section-bg">
                <div className="card--four">
                    <div className="container">
                        <Card />
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default Project;
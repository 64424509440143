import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const Colors = () => {
    return (
        <Fragment>

            <Header pageTitle="Colors" />
            <section className="theme-colors my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Basic Colors</h4>
                                </div>
                                <div className="showcase-body">
                                    <ul className="color-group">
                                        <li><span className="bg-primary">Primary Color</span></li>
                                        <li>
                                            <span>Default</span>
                                            <span className="color-primary">#f5443a</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group">
                                        <li><span className="bg-secondary">Secondary Color</span></li>
                                        <li>
                                            <span>Default</span>
                                            <span className="color-secondary">#3b3e79</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group">
                                        <li><span className="bg-success">Success Color</span></li>
                                        <li>
                                            <span>Default</span>
                                            <span className="color-success">#35b53e</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group">
                                        <li><span className="bg-info">Info Color</span></li>
                                        <li>
                                            <span>Default</span>
                                            <span className="color-info">#3590ec</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group">
                                        <li><span className="bg-warning">Warning Color</span></li>
                                        <li>
                                            <span>Default</span>
                                            <span className="color-warning">#3590ec</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group">
                                        <li><span className="bg-danger">Danger Color</span></li>
                                        <li>
                                            <span>Default</span>
                                            <span className="color-danger">#e23636</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group">
                                        <li><span className="bg-dark">Dark Color</span></li>
                                        <li>
                                            <span>Default</span>
                                            <span className="color-dark">#e23636</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>{/*<!-- ends: col-lg-12 -->*/}
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Text Colors</h4>
                                </div>
                                <div className="showcase-body">
                                    <ul className="color-group">
                                        <li><span className="bg-dark">Dark Color</span></li>
                                        <li>
                                            <span>Color Code</span>
                                            <span className="color-dark">#202428</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group">
                                        <li><span className="bg-light-default">Default Color</span></li>
                                        <li>
                                            <span>Color Code</span>
                                            <span className="color-default">#5a6671</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group">
                                        <li><span className="bg-light-gray">Light Color</span></li>
                                        <li>
                                            <span>Color Code</span>
                                            <span className="color-light-gray">#9192a3</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .col-lg-12 -->*/}
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Other Colors</h4>
                                </div>
                                <div className="showcase-body">
                                    <ul className="color-group other-colors">
                                        <li><span className="bg-line">Line Color</span></li>
                                        <li>
                                            <span>Color Code</span>
                                            <span className="color-line">#e4e8ed</span>
                                        </li>
                                    </ul>
                                    <ul className="color-group other-colors">
                                        <li><span className="bg-default">Background Color</span></li>
                                        <li>
                                            <span>Color Code</span>
                                            <span className="color-bg">#F4F4F4</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .col-lg-12 -->*/}
                    </div>
                </div>
            </section>{/*<!-- ends: .theme-colors --> */}
            <Footer />
        </Fragment>
    )
}

export default Colors;
import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader-2';
import Footer from '../layout/footer/footerCurvedLight';

import { CountUp7 } from '../content/element/counter/count';
import MissionVission from '../content/element/mission-vission';
import { IconBoxFour } from '../content/element/iconBox/iconBox';
import Team from '../container/element/carousel/team-carousel-one';

const About = () => {
    return (
        <Fragment>
            <Header/>
            <CountUp7 />
            <section className="section-bg p-top-105 p-bottom-65">
                <div className="m-bottom-40">
                    <div className="divider text-center">
                        <h1>Business Experts</h1>
                        <p className="mx-auto"></p>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Team />
                        </div>
                    </div>
                </div>
            </section>
            <MissionVission />

            <Footer />
        </Fragment>
    )
}

export default About;

import $ from "jquery";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}

const options = {
    items: 1,
    nav: false,
    rtl: falsy,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
};
class Slider extends Component {
    render() {
        const {project} = this.props;
        return (
            <OwlCarousel options={options} className="carousel-four owl-carousel">
                {
                    Object.values(project).slice(8, 10).map((value, key) => {
                        return (
                            <div className="carousel-single" key={key}>
                                <div className="card card-shadow card--eight">
                                    <figure>
                                        <img src={value.imgSrc} alt="" />
                                    </figure>
                                    <div className="card-body text-center">
                                        <h5><NavLink to={"/project-details"+value.id}>{value.title}</NavLink></h5>
                                        <p>{value.content}</p>
                                        <NavLink to={"/project-details"+value.id} className="btn btn-primary">See Details</NavLink>
                                    </div>
                                </div>{/*<!-- End: .card -->*/}
                            </div>
                        )
                    })

                }
            </OwlCarousel>
        )
    }
}
const mapStateToProps = state => {
    return {
        project : state.project
    }
}
export default connect(mapStateToProps)(Slider);
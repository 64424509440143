import React from 'react';
import Carousel from '../element/carousel/image-carousel-2';
import { AccordianFour } from '../../content/element/accordians/accordians';
const Split = () => {
    return (
        <section className=" section-bg p-top-100 p-bottom-105">
            <div className="m-bottom-50">
                <div className="divider text-center">
                    <h1>Our Core Vision</h1>
                    <p className="mx-auto">To establish trust through brand integrity for all stakeholders in the Cannabis Industry.</p>
                </div>
            </div>
            <section className="accordion-styles accordion--three">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                          <div className="carousel-single">
                              <figure>
                                  <img src="./assets/img/team.png" alt="" style={{maxHeight: '300px'}} />
                              </figure>
                          </div>
                        </div>
                        <AccordianFour />
                    </div>
                </div>
            </section>
        </section>
    )
}
export default Split;

import $ from "jquery";
import React from 'react';
import {connect} from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import {CaseItem} from '../../content/case-studies/case-studies-2';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}

const options = {
    items: 4,
    nav: false,
    rtl:falsy,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        550:{
            items:2
        },
        768: {
            items:3
        },
        1000: {
            items: 4
        }
    }
};
const Case_studies = (props) => {
    const {project} = props;
    return (
        <section className="p-top-100 p-bottom-95">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="section_title text-center">
                            <h1>Case Studies</h1>
                        </div>
                    </div>
                </div>
            </div>
            <OwlCarousel options={options} className="portfolio-carousel owl-carousel">
            {
                Object.values(project).slice(0, 6).map((value, key) => {
                    const {imgSrc, title, id, content} = value;
                    return(
                        <CaseItem
                            imgSrc={imgSrc}
                            title={title}
                            key={id}
                            content={content}
                            id={id}
                        />
                    )
                })
            }
            </OwlCarousel>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        project : state.project
    }
}
export default connect(mapStateToProps)(Case_studies);
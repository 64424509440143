import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
const Content_height = () => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar />                
            </section>
            <div className="intro-three">
                <div className="intro-three--contents">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-6 col-md-12">
                                <h1 className="display-2">Business <br /> <span>Startup</span></h1>
                                <p>Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem
                                    ipsum dolor sit amet consectetur adipisicing dolore.</p>
                                <NavLink to="/demo" onClick={noAction} className="btn btn-secondary">Learn More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>{/*<!-- ends: .intro-three-contents -->*/}
                <div className="intro-three--img">
                    <img src="./assets/img/slider_4.png" alt="" />
                </div>{/*<!-- ends: .intro-three-img -->*/}
            </div>{/*<!-- ends: .intro-three -->*/}
        </Fragment>       
                      
    )   
}


export default Content_height;




import React, {Fragment} from 'react';

import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import BlogDetails from '../container/element/card/card-post-2';
import LeftSideBar from '../container/blog/rightSidebar';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import $ from 'jquery';

class Blog extends React.Component {
    // pagination
    state = {
        count : 0
    }
    render () {
        const { blog } = this.props;

        const project = Object.values(blog).slice(6, 14).slice(this.state.count*5, this.state.count*5+5);
        const length = Object.values(blog).slice(6, 14).length;
        const item_length = Math.ceil(length / 5);

        const item = () => {
            return (
                Object.values(blog).map((value, key) => {
                    return (
                        key <= item_length-1 &&
                        <li key={ key } className={`page-item click ${key === 0 && "active"}`}><NavLink className="page-link" to="#">{key + 1}</NavLink></li>
                    )
                })
            )
        }

        setTimeout(() => {
            const li = document.querySelectorAll('.page-item');
            const click = document.querySelectorAll('.click');
            click.forEach((value, key) => {
                value.addEventListener('click', () => {
                    $(window).scrollTop(0);
                    li.forEach((li_item, li_key) => {
                        li_item.classList.remove('active');
                    })
                    value.classList.add('active');
                    this.setState({
                        count : key
                    })
                })
            })

            var next = document.querySelector('.next');
            var prev = document.querySelector('.prev');
            next.addEventListener('click', () => {
                document.querySelector('.page-item.active').nextElementSibling.click();
            })
            prev.addEventListener('click', () => {
                document.querySelector('.page-item.active').previousElementSibling.click();
            })
        }, 500);
        // pagination


        return (
            <Fragment>

                <Header pageTitle='Blog Left Sidebar'/>
                <section className="blog-wrapper section-padding">
                    <div className="container">
                        <div className="row">
                            <LeftSideBar {...blog} />

                            <div className="col-lg-8 margin-md-60">
                                <div className="blog-posts">
                                    <BlogDetails {...project} />
                                </div>

                                <div className="project-pagination m-top-40">
                                    <div className="pagination-area">
                                        <nav aria-label="Page navigation pagination-left">
                                            <ul className="pagination">
                                                <li className="page-item prev"><NavLink className="page-link" to="#">Previous</NavLink></li>
                                                {
                                                    item()
                                                    }

                                                <li className="page-item next"><NavLink className="page-link" to="#">Next</NavLink></li>
                                            </ul>
                                        </nav>
                                    </div>{/*<!-- ends: .pagination-wrapper -->*/}
                                </div>

                            </div>{/*<!-- ends: .col-lg-8 -->*/}
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        )
    }
}
const mapStateToProps = (state, ownProps) => {

    return {
        blog : state.blog
    }
}
export default connect(mapStateToProps)(Blog);
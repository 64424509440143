import React, {Fragment} from 'react';
import Header from '../layout/header/event-header';
import Footer from '../layout/footer/footer-5';
import EventContent from '../container/blog/event';
import {connect} from 'react-redux';


const Index = (props) => {
    const id = props.match.params.id;
    const filter = Object.values(props.event).filter((value) => {
        return value.id === id;
    })

    return (
        <Fragment>

            <Header pageTitle={filter[0]}/>
                <EventContent content={filter[0]} event={props.event} />
            <Footer />
        </Fragment>
    )
}
const mapStateToProps = (state, ownProps) => {

    return {
        event : state.event
    }
}
export default connect(mapStateToProps)(Index);
import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import SocondaryNav from '../layout/navbar/aboutNavItem';
import Footer from '../layout/footer/footer-5';

import { LogoBox } from '../content/element/iconBox/iconBox';
import CtaSm from '../content/cta/cta-sm';

const About = () => {
    return (
        <Fragment>

            <Header pageTitle='Our Clients' />
            <SocondaryNav />
            <section className="content-block content--block5 p-top-110 p-bottom-110">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 margin-md-60">
                            <img src="./assets/img/about.png" alt="" />
                        </div>{/* ends: .col-lg-6 */}
                            <div className="col-lg-5 offset-lg-1">
                            <h2>Serving Clients Around the World</h2>
                            <p>Investiga tiones demonstr averunt lectres legere me lius quod ii qua legunt saepius. Clarias est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium. Investig ationes demons trave runt lectores.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p-top-90 section-bg">
                <div className="m-bottom-40 p-left-15 p-right-15">
                    <div className="divider text-center m-bottom-50">
                        <h2 className="color-dark m-0">Happy Partners</h2>
                    </div>
                </div>
                <LogoBox />
            </section>
            <CtaSm />
            <Footer />
        </Fragment>
    )
}

export default About;
import React from 'react';
import {NavLink} from 'react-router-dom';

const Cta3 = () => {
    return (
        <section className="cta-wrapper cta--three bgimage biz_overlay overlay--secondary">
            <div className="bg_image_holder">

            </div>
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="cta-content text-center">
                            <p>A range of consulting, software, and physical product solutions that lead your cannabis business on the path towards sustainable growth.</p>
                            <NavLink to="/contact" className="btn btn-primary">Contact Us</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Cta3;

import React, { Component } from 'react'
import BlogDetails from './detailsContent';

export default class Blog extends Component {
    render() {
        const {content} = this.props;
        return (
            <section className="blog-single-wrapper section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-10 offset-lg-1">
                            <BlogDetails {...content} />
                        </div>{/*<!-- ends: .col-lg-8 -->*/}                                          
                    </div>
                </div>
            </section>
        )
    }
}

import React, {Fragment} from 'react';

const ClientContent = (props) => {
    const {img} = props;
    return (
        <Fragment>
            <div className="carousel-single">
                <img src={img} alt="" />
            </div>
            {/*<!-- ends: .carousel-single -->*/}            
        </Fragment>
    )
}
export default ClientContent;

import React from 'react';
import {NavLink} from 'react-router-dom';
export const CaseItem  = props => {
    const {title, content, id} =  props;
    return(
        <div className="carousel-two-single">
            <div className="card card-shadow card--seven">
                <figure>
                    <img src={`./assets/img/c${id}.jpg`} alt="" />
                </figure>
                <div className="card-body">
                    <h6><NavLink to={"/project-details"+id}>{title}</NavLink></h6>
                    <p>{content}</p>
                    <NavLink to={"/project-details"+id} className="btn btn-outline-primary">See Details</NavLink>
                </div>
            </div>{/*<!-- End: .card -->*/}
        </div>
    )
}
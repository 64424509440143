import React from 'react';
import { NavLink } from 'react-router-dom';

const AboutNavItem = () => {
    return (
        <section className="about-links">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="d-flex justify-content-center">
                            <li><NavLink to="/about-two">About Us</NavLink></li>
                            <li><NavLink to="/history">Our History</NavLink></li>
                            <li><NavLink to="/values">Our Values</NavLink></li>
                            <li><NavLink to="/business-conduct">Business Conduct</NavLink></li>
                            <li><NavLink to="/clients">Our Clients</NavLink></li>
                            <li><NavLink to="/locations">Our Locations</NavLink></li>
                            <li><NavLink to="/success-story">Success Story</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutNavItem;
import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


class Videos extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Videos" />

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Video - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="videos-wrapper video--one">
                <div className="container">
                    <div className="row align-items-md-center">
                        <div className="col-lg-5 col-md-6 margin-sm-60">
                            <h3 className="color-dark">Useful Solutions for Your <span>Business</span></h3>
                            <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutationem consuetudium lecry torum. Mirum est notare quam littera gothica, quam the nunc putamus parum claram, ante posuerit.</p>
                            <a href=" " className="btn btn-primary btn--rounded mt-4">Explore More</a>
                        </div>
                        <div className="col-lg-6 col-md-6 offset-lg-1">
                            <div className="video-single">
                                <figure>
                                    <div className="v_img">
                                        <img src="./assets/img/simg-2.jpg" alt="" className="rounded" />
                                    </div>
                                    <figcaption className="d-flex justify-content-center align-items-center shape-wrapper img-shape-two">
                                        <a href="https://www.youtube.com/watch?v=omaTcIbwt9c" className="video-iframe play-btn play-btn--lg play-btn--primary"><img src="./assets/img/svg/play-btn.svg" alt="" className="svg" /></a>
                                    </figcaption>
                                </figure>
                            </div>{/*<!-- ends: .video-single -->*/}
                        </div>
                    </div>
                </div>
            </div>{/*<!-- ends: .videos-wrapper -->*/}
            <div className="hr-divider"></div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Video - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="videos-wrapper video--two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="video-single video-overlay">
                                <figure>
                                    <img src="./assets/img/v1.jpg" alt="" />
                                    <figcaption className="d-flex justify-content-center align-items-center">
                                        <a href="https://www.youtube.com/watch?v=omaTcIbwt9c" className="video-iframe play-btn play-btn--lg play-btn-light"><img src="./assets/img/svg/play-btn.svg" alt="" className="svg" /></a>
                                    </figcaption>
                                </figure>
                            </div>{/*<!-- ends: .video-single -->*/}
                        </div>
                    </div>
                </div>
            </section>{/*<!-- ends: .videos-wrapper -->*/}
            <div className="hr-divider"></div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Video - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-wrapper video--three">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="video-banner bgimage biz_overlay rounded">
                                <div className="bg_image_holder">
                                    <img src="./assets/img/v_banner.jpg" alt="" />
                                </div>
                                <div className="content_above">
                                    <a href="https://www.youtube.com/watch?v=omaTcIbwt9c" className="video-iframe">
                                        <span><span className="play-btn play-btn--sm play-btn-light"><img src="./assets/img/svg/play-btn.svg" alt="" className="svg" /></span></span>
                                        <span>Watch video to know how Tejarat makes building your site</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/*<!-- ends: .video-wrapper -->*/}
            <div className="hr-divider"></div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Video - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-wrapper video-bg bgimage biz_overlay">
                <div className="bg_image_holder">
                    <img src="./assets/img/video-bg.jpg" alt="" />
                </div>
                <div className="container content_above">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-center align-items-center">
                                <a href="https://www.youtube.com/watch?v=omaTcIbwt9c" className="video-iframe d-flex justify-content-center align-items-center">
                                    <span><span className="play-btn play-btn--lg play-btn-light m-right-20"><img src="./assets/img/svg/play-btn.svg" alt="" className="svg" /></span></span>
                                    <span>Watch video to know how Tejarat makes building your site</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/*<!-- ends: .video-wrapper -->*/}
            <Footer />
        </Fragment>
      )
    }
}
export default Videos

import React from 'react';

const TestimonialFourCon = (props) => {
    const {img, name, content, subtitle} = props;
    return (
        <div className="col-lg-4 col-md-6">
            <div className="card card-shadow card--testimonial2">
                <div className="card-body text-center">
                    <img src={img} alt="" className="rounded-circle" /> 
                    <h6>{ subtitle }</h6>
                    <div className="ratings color-warning">
                        <span><i className="la la-star"></i></span>
                        <span><i className="la la-star"></i></span>
                        <span><i className="la la-star"></i></span>
                        <span><i className="la la-star"></i></span>
                        <span><i className="la la-star"></i></span>
                    </div>
                    <p>{content}</p>
                    <p className="author-spec"><strong>{name},</strong> <span className="color-secondary">Aazztech</span></p>
                </div>
            </div>{/*<!-- End: .card-body -->*/}
        </div>
    )
}
export default TestimonialFourCon;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const FormElements = () => {

    return (
        <Fragment>

        <Header pageTitle="Form Elements" />
        <section className="form-elements p-top-100 p-bottom-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ul className="form-tabs nav justify-content-center" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="active" id="tab-one" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Basic Form</a>
                  </li>
                  <li className="nav-item">
                    <a id="tab-two" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Form Group</a>
                  </li>
                  <li className="nav-item">
                    <a id="tab-three" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">Select</a>
                  </li>
                  <li className="nav-item">
                    <a id="tab-four" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">Checkbox</a>
                  </li>
                  <li className="nav-item">
                    <a id="tab-five" data-toggle="tab" href="#tab5" role="tab" aria-controls="tab5" aria-selected="false">Radio &amp; Toggle</a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-one">
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Basic Form</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Basic Text Inputs</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <div className="form-group">
                                <input type="text" placeholder="Base Input" className="form-control" />
                              </div>
                              <div className="form-group">
                                <input type="text" placeholder="Squared Input" className="form-control fc--squared" />
                              </div>
                              <div className="form-group">
                                <input type="text" placeholder="Rounded Input" className="form-control fc--rounded" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Solid Text Inputs</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <div className="form-group">
                                <input type="text" placeholder="Base Input" className="form-control fc--solid-bg" />
                              </div>
                              <div className="form-group">
                                <input type="text" placeholder="Squared Input" className="form-control fc--squared fc--solid-bg" />
                              </div>
                              <div className="form-group">
                                <input type="text" placeholder="Rounded Input" className="form-control fc--rounded fc--solid-bg" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Input With Label</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <div className="form-group">
                                <label htmlFor="intext">Base Label</label>
                                <input type="text" id="intext" placeholder="Base Input" className="form-control" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Input With Help Text</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" placeholder="Base Input" className="form-control" />
                              <small className="form-text">We'll never share your email with anyone else!</small>
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Disabled Input</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" placeholder="Disabled Input" className="form-control fc--default fc--solid-bg" disabled />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Readonly Input Field</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" placeholder="Readonly Input" className="form-control fc--default fc--solid-bg" readOnly />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Readonly Plain Text</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" defaultValue="email@example.com" className="form-control-plaintext" readOnly />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Predefined Input Value</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="form-group">
                              <input type="text" defaultValue="Your Name" className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Textual Input Types Options</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Text Type Input</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" placeholder="User Name" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Email Input</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="email" placeholder="email@example.com" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Password Input</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="password" placeholder="Enter Password" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Telephones Input</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="tel" placeholder="(555)-555-555" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Number</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="number" min={1} max={50} defaultValue={35} className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Date &amp; Time</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="datetime-local" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Date</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="date" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Time</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="time" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Week</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="week" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Month</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="month" className="form-control" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Color</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="color" className="form-control form-control--color" defaultValue="#f5443a" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Range</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <div className="range-input">
                                <input type="range" className="custom-range" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Basic Textarea</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Basic Textarea</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <textarea className="form-control" rows={3} defaultValue={""} />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Textarea With Placeholder</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <textarea placeholder="Simple Textarea" className="form-control" rows={3} defaultValue={""} />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Textarea With Label</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <div className="form-group">
                                <label htmlFor="txtara">Textarea Label</label>
                                <textarea id="txtara" placeholder="Simple Textarea" className="form-control" rows={3} defaultValue={""} />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Solid Textarea</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <textarea placeholder="Simple Textarea" className="form-control fc--solid-bg" rows={3} defaultValue={""} />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Basic File Browser</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Default File Browser</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="file" className="form-control-file" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Custom File Browser</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="custom-file">
                              <input type="file" className="custom-file-input" id="customFile" />
                              <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                            </div>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Basic Aligned Text</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Left Aligned Text</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" className="form-control text-left" placeholder="Left Aligned" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Center Aligned Text</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" className="form-control text-center" placeholder="Center Aligned" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Input With Tooltip</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Tooltip On Click</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" className="form-control" placeholder="Tooltip on Hover" data-toggle="tooltip" data-placement="top" data-trigger="click" title="Tooltip on Click" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Tooltip on Hover</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <input type="text" className="form-control" placeholder="Tooltip on Hover" data-toggle="tooltip" data-placement="top" title="Tooltip on Hover" />
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Control Sizing Options</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Input Sizes</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <div className="form-group">
                                <input type="text" className="form-control form-control-lg" placeholder="Large Input" />
                              </div>
                              <div className="form-group">
                                <input type="text" className="form-control" placeholder="Default Input" />
                              </div>
                              <div className="form-group">
                                <input type="text" className="form-control form-control-sm" placeholder="Small Input" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Input Select Sizes</h5>
                          </div>
                          <div className="showcase-body">
                            <form action="#">
                              <div className="form-group">
                                <select className="form-control form-control-lg">
                                  <option>Large select</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <select className="form-control">
                                  <option>Default select</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <select className="form-control form-control-sm">
                                  <option>Small select</option>
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                  </div>{/* ends: .tab-pane */}
                  <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-two">
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Basic Form Groups</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Left Addon Input Group</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">@</span>
                              </div>
                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Right Addon Input Group</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <input type="text" className="form-control" placeholder="User Email" aria-label="User Email" />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">@example.com</span>
                              </div>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Addon on Both Side</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">#</span>
                              </div>
                              <input type="text" className="form-control" />
                              <div className="input-group-append">
                                <span className="input-group-text">px</span>
                              </div>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Solid Style</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">#</span>
                              </div>
                              <input type="text" className="form-control fc--solid-bg" />
                              <div className="input-group-append">
                                <span className="input-group-text">px</span>
                              </div>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Icon Input Group Style 1</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Left Icon</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon3"><i className="la la-user" /></span>
                              </div>
                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Right Icon</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <input type="text" className="form-control" placeholder="User Email" aria-label="User Email" />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon4"><i className="la la-phone-square" /></span>
                              </div>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Icon on Both Side</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text"><i className="la la-filter" /></span>
                              </div>
                              <input type="text" className="form-control" />
                              <div className="input-group-append">
                                <span className="input-group-text"><i className="la la-cog" /></span>
                              </div>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Solid Style</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text"><i className="la la-clone" /></span>
                              </div>
                              <input type="text" className="form-control fc--solid-bg" />
                            </div>
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Left Color Icon</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-primary color-light border-primary" id="basic-addon5"><i className="la la-user" /></span>
                              </div>
                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Right Color Icon</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <input type="text" className="form-control" placeholder="User Email" aria-label="User Email" />
                              <div className="input-group-append">
                                <span className="input-group-text bg-primary color-light border-primary" id="basic-addon6"><i className="la la-phone-square" /></span>
                              </div>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Color Icon on Both Side</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-secondary color-light border-secondary"><i className="la la-filter" /></span>
                              </div>
                              <input type="text" className="form-control" />
                              <div className="input-group-append">
                                <span className="input-group-text bg-primary color-light border-primary"><i className="la la-cog" /></span>
                              </div>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Solid Color Style</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-primary color-light border-primary"><i className="la la-clone" /></span>
                              </div>
                              <input type="text" className="form-control fc--solid-bg" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Icon Input Groups Style 2</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Left Icon</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group input-group-light">
                              <span className="icon-left" id="basic-addon7"><i className="la la-envelope" /></span>
                              <input type="text" className="form-control" placeholder="Enter your email" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Right Icon</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group input-group-light">
                              <span className="icon-right" id="basic-addon8"><i className="la la-phone-square" /></span>
                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Icon on Both Side</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group input-group-light">
                              <span className="icon-left" id="basic-addon9"><i className="la la-user" /></span>
                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" />
                              <span className="icon-right" id="basic-addon15"><i className="la la-check-circle" /></span>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Solid Styles</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group input-group-light">
                              <span className="icon-left" id="basic-addon10"><i className="la la-user" /></span>
                              <input type="text" className="form-control fc--solid-bg" placeholder="Username" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Icon Input Groups Color Styles</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Left Icon</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group input-group-light">
                              <span className="icon-left color-primary" id="basic-addon11"><i className="la la-user" /></span>
                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Right Icon</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group input-group-light">
                              <span className="icon-right color-primary" id="basic-addon12"><i className="la la-phone-square" /></span>
                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Icon on Both Side</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group input-group-light">
                              <span className="icon-left color-primary" id="basic-addon13"><i className="la la-user" /></span>
                              <input type="text" className="form-control" placeholder="Username" aria-label="Username" />
                              <span className="icon-right color-primary" id="basic-addon1564"><i className="la la-check-circle" /></span>
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Solid Style</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="input-group input-group-light">
                              <span className="icon-left color-primary" id="basic-addon14"><i className="la la-user" /></span>
                              <input type="text" className="form-control fc--solid-bg" placeholder="Username" aria-label="Username" />
                            </div>{/* End: .input-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                  </div>{/* ends: .tab-pane */}
                  <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab-three">
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Basic Select</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Single Select</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="form-group">
                              <div className="select-basic">
                                <select className="form-control">
                                  <option>Select Option</option>
                                  <option>Option 1</option>
                                  <option>Option 2</option>
                                  <option>Option 3</option>
                                  <option>Option 4</option>
                                </select>
                              </div>
                            </div>{/* End: .form-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Select group</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="form-group">
                              <div className="select-multiple select-basic">
                                <select className="multiple-select form-control" name="states[]">
                                  <optgroup label="Alaskan/Hawaiian Time Zone">
                                    <option value="AK">Alaska</option>
                                    <option value="HI">Hawaii</option>
                                  </optgroup>
                                  <optgroup label="Pacific Time Zone">
                                    <option value="CA">California</option>
                                    <option value="NV">Nevada</option>
                                    <option value="OR">Oregon</option>
                                    <option value="WA">Washington</option>
                                  </optgroup>
                                  <optgroup label="Mountain Time Zone">
                                    <option value="AZ">Arizona</option>
                                    <option value="CO">Colorado</option>
                                    <option value="ID">Idaho</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="UT">Utah</option>
                                    <option value="WY">Wyoming</option>
                                  </optgroup>
                                  <optgroup label="Central Time Zone">
                                    <option value="AL">Alabama</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TX">Texas</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="WI">Wisconsin</option>
                                  </optgroup>
                                </select>
                              </div>
                            </div>{/* End: .form-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Multiple Select</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="form-group">
                              <div className="select-multiple">
                                <select className="form-control" name="states[]" multiple>
                                  <optgroup label="Pacific Time Zone">
                                    <option value="CA">California</option>
                                    <option value="NV">Nevada</option>
                                    <option value="OR">Oregon</option>
                                    <option value="WA">Washington</option>
                                  </optgroup>
                                  <optgroup label="Mountain Time Zone">
                                    <option value="AZ">Arizona</option>
                                    <option value="CO">Colorado</option>
                                    <option value="ID">Idaho</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="UT">Utah</option>
                                    <option value="WY">Wyoming</option>
                                  </optgroup>
                                </select>
                              </div>
                            </div>{/* End: .form-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Select with search</h5>
                          </div>
                          <div className="showcase-body">
                            <div className="form-group">
                              <div className="select-multiple select-basic">
                                <select className="select2_default form-control" name="states[]">
                                  <optgroup label="Pacific Time Zone">
                                    <option value="CA">California</option>
                                    <option value="NV">Nevada</option>
                                    <option value="OR">Oregon</option>
                                    <option value="WA">Washington</option>
                                  </optgroup>
                                  <optgroup label="Mountain Time Zone">
                                    <option value="AZ">Arizona</option>
                                    <option value="CO">Colorado</option>
                                    <option value="ID">Idaho</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="UT">Utah</option>
                                    <option value="WY">Wyoming</option>
                                  </optgroup>
                                </select>
                              </div>
                            </div>{/* End: .form-group */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Tag/Multiple selection</h5>
                          </div>
                          <div className="showcase-body">
                            <select className="select2_tagged form-control" name="states[]">
                              <optgroup label="Pacific Time Zone">
                                <option value="CA">California</option>
                                <option value="NV">Nevada</option>
                                <option value="OR">Oregon</option>
                                <option value="WA">Washington</option>
                              </optgroup>
                              <optgroup label="Mountain Time Zone">
                                <option value="AZ">Arizona</option>
                                <option value="CO">Colorado</option>
                                <option value="ID">Idaho</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NM">New Mexico</option>
                                <option value="ND">North Dakota</option>
                                <option value="UT">Utah</option>
                                <option value="WY">Wyoming</option>
                              </optgroup>
                            </select>{/* End: .form-control */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                      <div className="col-lg-6">
                        <div className="showcase-box">
                          <div className="showcase-header">
                            <h5>Custom select with icon</h5>
                          </div>
                          <div className="showcase-body">
                            <select className="selection_with_icon form-control" name="states[]">
                              <option value="wordpress">WordPress</option>
                              <option value="joomla">Joomla</option>
                              <option value="github">Github</option>
                              <option value="amazon">Amazon</option>
                            </select>{/* End: .form-control */}
                          </div>
                        </div>
                      </div>{/* ends: .col-lg-6 */}
                    </div>{/* ends: .row */}
                  </div>{/* ends: .tab-pane */}
                  <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab-four">
                    <div className="mb-5 pb-5">
                      <div className="showcase showcase--title1 showcase--title2">
                        <h3>Default Checkbox</h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 m-bottom-60">
                          <form action="#">
                            <div className="custom-control custom-checkbox checkbox-info">
                              <input type="checkbox" className="custom-control-input" id="customCheck3" defaultChecked />
                              <label className="custom-control-label" htmlFor="customCheck3">Checked</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-primary">
                              <input type="checkbox" className="custom-control-input" id="customCheck4" />
                              <label className="custom-control-label" htmlFor="customCheck4">Unchecked</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-primary">
                              <input type="checkbox" className="custom-control-input" id="customCheck5" disabled />
                              <label className="custom-control-label" htmlFor="customCheck5">Disabled</label>
                            </div>
                          </form>
                        </div>{/* ends: .col-lg-4 */}
                        <div className="col-lg-4 col-md-6 m-bottom-60">
                          <form action="#">
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input type="checkbox" className="custom-control-input" id="customCheck6" defaultChecked />
                              <label className="custom-control-label" htmlFor="customCheck6">Checked</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-secondary">
                              <input type="checkbox" className="custom-control-input" id="customCheck7" />
                              <label className="custom-control-label" htmlFor="customCheck7">Unchecked</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline">
                              <input type="checkbox" className="custom-control-input" id="customCheck8" disabled />
                              <label className="custom-control-label" htmlFor="customCheck8">Disabled</label>
                            </div>
                          </form>
                        </div>{/* ends: .col-lg-4 */}
                        <div className="col-lg-4 col-md-6">
                          <form action="#">
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-gray">
                              <input type="checkbox" className="custom-control-input" id="customCheck9" defaultChecked />
                              <label className="custom-control-label" htmlFor="customCheck9">Checked</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-gray">
                              <input type="checkbox" className="custom-control-input" id="customCheck10" />
                              <label className="custom-control-label" htmlFor="customCheck10">Unchecked</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-gray">
                              <input type="checkbox" className="custom-control-input" id="customCheck11" disabled />
                              <label className="custom-control-label" htmlFor="customCheck11">Disabled</label>
                            </div>
                          </form>
                        </div>{/* ends: .col-lg-4 */}
                      </div>{/* ends: .row */}
                      <div className="showcase showcase--title1 showcase--title2">
                        <h3>Checkbox States</h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 m-bottom-60">
                          <form action="#">
                            <div className="custom-control custom-checkbox checkbox-primary">
                              <input type="checkbox" className="custom-control-input" id="customCheck12" defaultChecked />
                              <label className="custom-control-label" htmlFor="customCheck12">Primary</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-secondary">
                              <input type="checkbox" className="custom-control-input" id="customCheck13" />
                              <label className="custom-control-label" htmlFor="customCheck13">Secondary</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-success">
                              <input type="checkbox" className="custom-control-input" id="customCheck14" />
                              <label className="custom-control-label" htmlFor="customCheck14">Success</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-info">
                              <input type="checkbox" className="custom-control-input" id="customCheck15" />
                              <label className="custom-control-label" htmlFor="customCheck15">Info</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-warning">
                              <input type="checkbox" className="custom-control-input" id="customCheck16" />
                              <label className="custom-control-label" htmlFor="customCheck16">Warning</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-danger">
                              <input type="checkbox" className="custom-control-input" id="customCheck17" />
                              <label className="custom-control-label" htmlFor="customCheck17">Danger</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-dark">
                              <input type="checkbox" className="custom-control-input" id="customCheck18" />
                              <label className="custom-control-label" htmlFor="customCheck18">Dark</label>
                            </div>
                          </form>
                        </div>{/* ends: .col-lg-4 */}
                        <div className="col-lg-4 col-md-6 m-bottom-60">
                          <form action="#">
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input type="checkbox" className="custom-control-input" id="customCheck19" defaultChecked />
                              <label className="custom-control-label" htmlFor="customCheck19">Primary</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-secondary">
                              <input type="checkbox" className="custom-control-input" id="customCheck20" />
                              <label className="custom-control-label" htmlFor="customCheck20">Secondary</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-success">
                              <input type="checkbox" className="custom-control-input" id="customCheck21" />
                              <label className="custom-control-label" htmlFor="customCheck21">Success</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-info">
                              <input type="checkbox" className="custom-control-input" id="customCheck22" />
                              <label className="custom-control-label" htmlFor="customCheck22">Info</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-warning">
                              <input type="checkbox" className="custom-control-input" id="customCheck23" />
                              <label className="custom-control-label" htmlFor="customCheck23">Warning</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-danger">
                              <input type="checkbox" className="custom-control-input" id="customCheck24" />
                              <label className="custom-control-label" htmlFor="customCheck24">Danger</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-dark">
                              <input type="checkbox" className="custom-control-input" id="customCheck25" />
                              <label className="custom-control-label" htmlFor="customCheck25">Dark</label>
                            </div>
                          </form>
                        </div>{/* ends: .col-lg-4 */}
                        <div className="col-lg-4 col-md-6">
                          <form action="#">
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary checkbox-rounded">
                              <input type="checkbox" className="custom-control-input" id="customCheck26" defaultChecked />
                              <label className="custom-control-label" htmlFor="customCheck26">Primary</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-secondary checkbox-rounded">
                              <input type="checkbox" className="custom-control-input" id="customCheck27" />
                              <label className="custom-control-label" htmlFor="customCheck27">Secondary</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-success checkbox-rounded">
                              <input type="checkbox" className="custom-control-input" id="customCheck28" />
                              <label className="custom-control-label" htmlFor="customCheck28">Success</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-info checkbox-rounded">
                              <input type="checkbox" className="custom-control-input" id="customCheck29" />
                              <label className="custom-control-label" htmlFor="customCheck29">Info</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-warning checkbox-rounded">
                              <input type="checkbox" className="custom-control-input" id="customCheck30" />
                              <label className="custom-control-label" htmlFor="customCheck30">Warning</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-danger checkbox-rounded">
                              <input type="checkbox" className="custom-control-input" id="customCheck31" />
                              <label className="custom-control-label" htmlFor="customCheck31">Danger</label>
                            </div>
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-dark checkbox-rounded">
                              <input type="checkbox" className="custom-control-input" id="customCheck32" />
                              <label className="custom-control-label" htmlFor="customCheck32">Dark</label>
                            </div>
                          </form>
                        </div>{/* ends: .col-lg-4 */}
                      </div>{/* ends: .row */}
                    </div>
                  </div>{/* ends: .tab-pane */}
                  <div className="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab-five">
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Default Radios</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <form action="#">
                          <div className="custom-control custom-radio">
                            <input type="radio" id="customRadio3" name="customRadio1" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadio3">Radio is off</label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input type="radio" id="customRadio4" name="customRadio1" className="custom-control-input" defaultChecked />
                            <label className="custom-control-label" htmlFor="customRadio4">Radio is on</label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input type="radio" id="customRadio5" name="customRadio1" className="custom-control-input" disabled />
                            <label className="custom-control-label" htmlFor="customRadio5">Disabled Radio</label>
                          </div>
                        </form>
                      </div>{/* ends: .col-lg-4 */}
                      <div className="col-lg-4">
                        <form action="#">
                          <div className="custom-control custom-radio custom-radio-two">
                            <input type="radio" id="customRadio6" name="customRadio1" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadio6">Radio is off</label>
                          </div>
                          <div className="custom-control custom-radio custom-radio-two">
                            <input type="radio" id="customRadio7" name="customRadio1" className="custom-control-input" defaultChecked />
                            <label className="custom-control-label" htmlFor="customRadio7">Radio is on</label>
                          </div>
                          <div className="custom-control custom-radio custom-radio-two">
                            <input type="radio" id="customRadio8" name="customRadio1" className="custom-control-input" disabled />
                            <label className="custom-control-label" htmlFor="customRadio8">Disabled Radio</label>
                          </div>
                        </form>
                      </div>{/* ends: .col-lg-4 */}
                      <div className="col-lg-4">
                        <form action="#">
                          <div className="custom-control custom-radio custom-radio-three">
                            <input type="radio" id="customRadio9" name="customRadio1" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="customRadio9">Radio is off</label>
                          </div>
                          <div className="custom-control custom-radio custom-radio-three">
                            <input type="radio" id="customRadio10" name="customRadio1" className="custom-control-input" defaultChecked />
                            <label className="custom-control-label" htmlFor="customRadio10">Radio is on</label>
                          </div>
                          <div className="custom-control custom-radio custom-radio-three">
                            <input type="radio" id="customRadio11" name="customRadio1" className="custom-control-input" disabled />
                            <label className="custom-control-label" htmlFor="customRadio11">Disabled Radio</label>
                          </div>
                        </form>
                      </div>{/* ends: .col-lg-4 */}
                    </div>{/* ends: .row */}
                    <div className="showcase showcase--title1 showcase--title2">
                      <h3>Toggle Buttons</h3>
                    </div>
                    <div className="row mb-5 pb-2">
                      <div className="col-lg-4">
                        <div className="toggle-btn">
                          <label className="toggle-switch">
                            <input type="checkbox" />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="toggle-btn">
                          <label className="toggle-switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>{/* ends: .col-lg-4 */}
                      <div className="col-lg-4">
                        <div className="toggle-btn">
                          <label className="toggle-switch toggle-switch-text">
                            <input type="checkbox" />
                            <span className="slider round">
                              <span className="on">On</span>
                              <span className="off">Off</span>
                            </span>
                          </label>
                        </div>
                        <div className="toggle-btn">
                          <label className="toggle-switch toggle-switch-text">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round">
                              <span className="on">On</span>
                              <span className="off">Off</span>
                            </span>
                          </label>
                        </div>
                      </div>{/* ends: .col-lg-4 */}
                      <div className="col-lg-4">
                        <div className="toggle-btn">
                          <label className="toggle-switch toggle-switch-bordered">
                            <input type="checkbox" />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="toggle-btn">
                          <label className="toggle-switch toggle-switch-bordered">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>{/* ends: .col-lg-4 */}
                    </div>{/* ends: .row */}
                  </div>{/* ends: .tab-pane */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        </Fragment>
      );
}
export default FormElements;
import $ from "jquery";
import React, { Component } from 'react'
import Content from '../../content/team/team-wrap';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 1,
    rtl: falsy,
    nav: true,
    navText: ['<button><i class="la la-angle-left"></i></button>', '<button><i class="la la-angle-right"></i></button>'],
    navContainerClass: "nav-circle nav-circle--light",
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        600:{
            items:1
        },
        992:{
            items:1
        }
    }
};
class Team_wrap extends Component {
    render() {
        const {team} = this.props;
        return (
            <section className="team-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="title-box">
                                <h2>Meet Our Professional Business Experts</h2>
                            </div>
                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                        <div className="col-lg-8">
                            <OwlCarousel options={options} className="team-carousel-three owl-carousel">
                            {
                                Object.values(team).slice(0, 3).map((value, key) => {
                                    const {imgSrc, id, name, speach, designation} = value;
                                    return (
                                        <Content img={imgSrc}
                                         key={id}
                                         name={name}
                                         designation={designation}
                                         speach={speach}
                                          />
                                    )
                                })
                            }


                            </OwlCarousel>{/*<!-- ends: .team-carousel -->*/}
                        </div>{/*<!-- ends: .col-lg-8 -->*/}
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        team : state.team
    }
}
export default connect(mapStateToProps)(Team_wrap);
import React, {Fragment} from 'react';
import Header from '../layout/header/login-register';
import { Login } from '../content/element/form/login';


const Login1 = () => {
    return (
        <Fragment>

            <Header>
               <Login />
            </Header>
        </Fragment>
    )
}

export default Login1;
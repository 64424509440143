import React from 'react';

const Fetures = () => {

    return (
        <section className="shop-features section-bg p-top-110 p-bottom-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="icon-box-nine text-center">
                            <span className="icon-rounded-sm"><i className="la la-shopping-cart"></i></span>
                            <h6>Free Worldwide Shipping</h6>
                            <p className="d-none">Investig ationes demons legere lyrus quod legunt itasest demons.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-3 col-sm-6">
                        <div className="icon-box-nine text-center">
                            <span className="icon-rounded-sm"><i className="la la-refresh"></i></span>
                            <h6>Money Back Guarantee</h6>
                            <p className="d-none">Investig ationes demons legere lyrus quod legunt itasest demons.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-3 col-sm-6">
                        <div className="icon-box-nine text-center">
                            <span className="icon-rounded-sm"><i className="la la-headphones"></i></span>
                            <h6>24/7 Customer Support</h6>
                            <p className="d-none">Investig ationes demons legere lyrus quod legunt itasest demons.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-3 col-sm-6">
                        <div className="icon-box-nine text-center">
                            <span className="icon-rounded-sm"><i className="la la-money"></i></span>
                            <h6>Secure Online Payment</h6>
                            <p className="d-none">Investig ationes demons legere lyrus quod legunt itasest demons.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                </div>
            </div>
        </section>
    )
}

export default Fetures;
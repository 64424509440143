import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
const ContentBlock12 = (props)=> {
    return (
        <section className={props.padding ? props.padding : "p-top-110 p-bottom-110"}>
            <div className="content-block content-block--12">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 margin-md-60">
                            <span className="subtext">Who we are</span>
                            <h2>25 Years of Industry Experience</h2>
                            <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas
                                est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium. Investig ationes
                                demons trave runt lectores legere liusry awquod he legunt saepius clary tyitas Investig
                                atifonesw.</p>
                            <NavLink to='/at_demo'  onClick={noAction} className="btn btn-secondary m-top-30">Explore More</NavLink>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="shape-wrapper img-shape-three">
                                <img src="./assets/img/simg-3.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>{/*<!-- ends: .content-block -->*/}
        </section>
    )
}
export default ContentBlock12;

import React, {Fragment} from 'react';
import NavItem from '../navbar/navItem';
import { NavLink } from 'react-router-dom';

const Login = (props) => {      
    return (
        <Fragment>
             <section className="login-register bgimage biz_overlay overlay--secondary2">
                <div className="bg_image_holder">
                    <img src="./assets/img/image3.jpg" alt="" />
                </div>
                <div className="content_above">
                    {/* <!-- start menu area --> */}
                    <div className="menu_area menu5">
                        <div className="container">
                            <nav className="navbar navbar-bg navbar-expand-lg px-0">
                                <NavLink className="navbar-brand order-sm-1 order-1" to="/"><img src="./assets/img/logo-white.png" alt="" /></NavLink>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent5" aria-controls="navbarSupportedContent5" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="la la-bars"></span>
                                </button>
                                <div className="collapse navbar-collapse order-md-1" id="navbarSupportedContent5">
                                    <NavItem />                                            
                                </div>
                                {/* <!-- ends: .intro-area-11 --> */}
                            </nav>
                        </div>
                    </div>
                    {props.children}
                </div>
            </section>
            {/* <!-- ends: .intro-area --> */}
        </Fragment>       
                      
    )   
}

export default Login;




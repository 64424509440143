import React, { Fragment } from 'react';

import Header from '../layout/header/career-header';
import VideoWraper from '../content/contentBlock/video-wraper';
import { AccordianThree } from '../content/element/accordians/accordians';
import Subscrib5 from '../content/subscribe/subscribe-5';
import Footer from '../layout/footer/footer-5';
const Careers = () => {
    return (
        <Fragment>

            <Header />
            <section className="career-search p-top-105 p-bottom-70">
                <div className="search-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <form action="#">
                                    <div className="form-group d-flex flex-wrap">
                                        <input type="text" required placeholder="What are you looking for?" className="form-control fc--squared fc--solid-bg border-0" />
                                        <input type="text" required placeholder="Location" className="form-control fc--squared fc--solid-bg border-0" />
                                        <div className="select-basic">
                                            <select className="form-control fc--squared fc--solid-bg border-0">
                                                <option>Categories</option>
                                                <option>Option 1</option>
                                                <option>Option 2</option>
                                                <option>Option 3</option>
                                                <option>Option 4</option>
                                            </select>
                                        </div>
                                        <button type="submit" className="btn btn-secondary m-left-30">Search Now</button>
                                    </div>
                                </form>
                            </div>{/* ends: .col-lg-12 */}
                        </div>
                    </div>
                </div>{/* ends: .search-wrapper */}
            </section>
            <VideoWraper />
            <section className="p-top-110 p-bottom-105 section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 margin-md-60">
                            <h3>Benefits &amp; Rewards</h3>
                            <p className="mb-3 pb-3">We can offer you a wide range of opportunities to build a varied
                                and rewarding career.We offer attractive careers for talented and
                                ambitious professionals from a wide range of backgrounds, with
                                different skills, capabilities. </p>
                            <ul className="bullet--list1">
                                <li className="bullet_list"><strong className="color-dark">Professional -</strong> Delivers solutions that help drive</li>
                                <li className="bullet_list"><strong className="color-dark">Business -</strong> Human capital research analytics</li>
                                <li className="bullet_list"><strong className="color-dark">Services -</strong> Complex problems bringing an approach</li>
                                <li className="bullet_list"><strong className="color-dark">Strategy -</strong> Works with senior executives to help them</li>
                            </ul>{/* ends: .bullet--list1 */}
                        </div>{/* ends: .col-lg-6 */}
                        <div className="col-lg-6">
                        <AccordianThree />
                        </div>{/* ends: .col-lg-6 */}
                    </div>
                </div>
            </section>{/* ends: section */}
            <section className="job-categories p-top-100 p-bottom-80">
                <div className="m-bottom-50">
                    <div className="divider text-center">
                        <h1>Browse Recent Jobs</h1>
                        <p className="mx-auto" />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card card--dark rounded-0">
                                <div className="card-header">
                                    <h5 className="color-dark">Browse Jobs by Location</h5>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>Africa - Middle East</span>
                                                <span className="count">20</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>Austria</span>
                                                <span className="count">05</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>Belgium</span>
                                                <span className="count">11</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>China</span>
                                                <span className="count">07</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>Denmark</span>
                                                <span className="count">06</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>Finland</span>
                                                <span className="count">17</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>France</span>
                                                <span className="count">12</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>Germany</span>
                                                <span className="count">09</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>Ireland</span>
                                                <span className="count">19</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=" " className="d-flex justify-content-between align-items-center">
                                                <span>Italy</span>
                                                <span className="count">16</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>{/* ends: .col-lg-6 */}
                        <div className="col-lg-6">
                            <div className="card card--dark rounded-0">
                                <div className="card-header">
                                    <h5 className="color-dark">Browse Jobs by Category</h5>
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Insurance &amp; Superannuation</span>
                                                <span className="count">20</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Construction</span>
                                                <span className="count">05</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Design &amp; Architecture</span>
                                                <span className="count">11</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Engineering</span>
                                                <span className="count">07</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Education &amp; Training</span>
                                                <span className="count">06</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Legal</span>
                                                <span className="count">17</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Farming, Animals &amp; Conservation</span>
                                                <span className="count">12</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>CEO &amp; General Management</span>
                                                <span className="count">09</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Community Services &amp; Development</span>
                                                <span className="count">19</span>
                                            </a>
                                        </li>
                                        <li className="list-count">
                                            <a href=' ' className="d-flex justify-content-between align-items-center">
                                                <span>Government &amp; Defence</span>
                                                <span className="count">16</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>{/* ends: .col-lg-6 */}
                    </div>
                </div>
            </section>{/* ends: section */}
            <section className="section-bg p-top-100 p-bottom-110">
                <div className="m-bottom-50">
                    <div className="divider text-center">
                        <h1>Open Positions</h1>
                        <p className="mx-auto" />
                    </div>
                    </div>
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-one">
                                <thead className="table-secondary table-secondary--darken">
                                    <tr>
                                        <th scope="col">Job Title</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>{/* ends: thead */}
                                <tbody>
                                    <tr>
                                        <th scope="row">Account Business Manager</th>
                                        <td>Vienna, Austria</td>
                                        <td>Administrative</td>
                                        <td>Jan 08, 2019</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Cash Application and Collections Specialist</th>
                                        <td>Detroit, Michigan</td>
                                        <td>Marketing</td>
                                        <td>Mar 23, 2019</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Director of Financial Systems</th>
                                        <td>London, UK</td>
                                        <td>Finance</td>
                                        <td>Apr 09, 2019</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Account Business Manager</th>
                                        <td>Paris, France</td>
                                        <td>Support Manager</td>
                                        <td>Jun 10, 2019</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Cash Application and Collections Specialist</th>
                                        <td>Detroit, Michigan</td>
                                        <td>Senior Executive</td>
                                        <td>Sep 17, 2019</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Director of Financial Systems</th>
                                        <td>London, UK</td>
                                        <td>Accounting</td>
                                        <td>Nov 01, 2019</td>
                                    </tr>
                                </tbody>{/* ends: tbody */}
                                </table>
                            </div>{/* ends: .table-responsive */}
                        </div>
                    </div>
                </div>
            </section>{/* ends: section */}
            <Subscrib5 />
            <Footer />
        </Fragment>
    )
}

export default Careers;
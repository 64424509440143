import React from 'react';
import { LineChart } from '../element/chart/line-chart';
const ContentBlockService = (props)=> {
    return (
        <section className={props.padding ? props.padding : "p-top-110 p-bottom-110"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 margin-md-60">
                        <div className="divider text-left">
                            <h1 className="color-dark">Services Overview</h1>
                            <p></p>
                        </div>
                        <p>Anthea is a full-service risk management company with solutions and expertise specific to the cannabis industry. We provide services, software, and physical products that fall under the three business lines below.</p>
                        <div className="m-top-30">
                            <ul className="bullet--list1">
                              <li className="bullet_list"><strong className="color-dark">Trumarks<sup>TM</sup> -</strong> Cloud based software service that provides direct producer to consumer communication and product authentication.</li>
                              <li className="bullet_list"><strong className="color-dark">Anthea Consulting -</strong> Solution focused consulting around recall processes, supply chain integrity, and food and process safety.</li>
                              <li className="bullet_list"><strong className="color-dark">NanoMarkers<sup>TM</sup> by Anthea -</strong> Mico-taggant technologies that can be used to enhance product authentication and prevent counterfeit.</li>
                            </ul>{/*<!-- ends: .bullet--list1 -->*/}
                        </div>
                    </div>{/*<!-- ends: .col-lg-5 -->*/}
                    <div className="col-lg-6 offset-lg-1">
                        <img src="./assets/img/undraw_Business_analytics_64el.png" />
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
            </div>
        </section>
    )
}
export default ContentBlockService;

import React, {Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import EventGridCard13 from '../container/element/card/event-grid-card-13';


class Event extends React.Component {

    render () {
        return (
            <Fragment>

                <Header pageTitle='Upcoming Event'/>
                <section className="events-grid p-bottom-100 section-bg">
                    <div className="event-types m-bottom-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="d-flex justify-content-center">
                                        <li><NavLink to="/event-grid">Happening</NavLink></li>
                                        <li><NavLink to="/upcoming">Upcoming</NavLink></li>
                                        <li><NavLink to="/expired">Expired</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <EventGridCard13 />
                    <EventGridCard13 />
                    <EventGridCard13 />
                </section>
                <Footer />
            </Fragment>
        )
    }
}

export default Event;
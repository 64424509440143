import React from 'react';
export const Process1 = () => {
    return (
        <div className="process-one">
            <div className="process-single text-center">
                <div className="icon light-bg">
                    <span className="step">1</span>
                    <span className="icon-la"><i className="la la-sun-o"></i></span>
                </div>
                <h6>Initial Concept</h6>
                <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
            </div>{/*<!-- ends: .process-single -->*/}
            <div className="process-single text-center">
                <div className="icon light-bg">
                    <span className="step">2</span>
                    <span className="icon-la"><i className="la la-bar-chart"></i></span>
                </div>
                <h6>Market Analysis</h6>
                <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
            </div>{/*<!-- ends: .process-single -->*/}
            <div className="process-single text-center">
                <div className="icon light-bg">
                    <span className="step">3</span>
                    <span className="icon-la"><i className="la la-rocket"></i></span>
                </div>
                <h6>Grow Business</h6>
                <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
            </div>{/*<!-- ends: .process-single -->*/}
            <div className="process-single text-center">
                <div className="icon light-bg">
                    <span className="step">4</span>
                    <span className="icon-la"><i className="la la-headphones"></i></span>
                </div>
                <h6>Fast Support</h6>
                <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
            </div>{/*<!-- ends: .process-single -->*/}
        </div>
    )
}

export const Process2 = () => {
    return (        
      <div className="process-wrapper section-bg process--two">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="process-one">
                        <div className="process-single text-center">
                            <div className="icon light-bg">
                                <span className="step">1</span>
                                <span className="icon-la"><i className="la la-sign-out" /></span>
                            </div>
                            <h6>Initial Concept</h6>
                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                        </div>{/* ends: .process-single */}
                        <div className="process-single text-center">
                            <div className="icon light-bg">
                                <span className="step">2</span>
                                <span className="icon-la"><i className="la la-bar-chart" /></span>
                                </div>
                            <h6>Market Analysis</h6>
                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                        </div>{/* ends: .process-single */}
                        <div className="process-single text-center">
                            <div className="icon light-bg">
                                <span className="step">3</span>
                                <span className="icon-la"><i className="la la-rocket" /></span>
                            </div>
                            <h6>Grow Business</h6>
                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                        </div>{/* ends: .process-single */}
                        <div className="process-single text-center">
                            <div className="icon light-bg">
                                <span className="step">4</span>
                                <span className="icon-la"><i className="la la-headphones" /></span>
                            </div>
                            <h6>Fast Support</h6>
                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                        </div>{/* ends: .process-single */}
                        <div className="process-single text-center">
                            <div className="icon light-bg">
                                <span className="step">5</span>
                                <span className="icon-la"><i className="la la-sign-out" /></span>
                            </div>
                            <h6>Initial Concept</h6>
                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                        </div>{/* ends: .process-single */}
                        <div className="process-single text-center">
                            <div className="icon light-bg">
                                <span className="step">6</span>
                                <span className="icon-la"><i className="la la-bar-chart" /></span>
                            </div>
                            <h6>Market Analysis</h6>
                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                        </div>{/* ends: .process-single */}
                        <div className="process-single text-center">
                            <div className="icon light-bg">
                                <span className="step">7</span>
                                <span className="icon-la"><i className="la la-rocket" /></span>
                            </div>
                            <h6>Grow Business</h6>
                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                        </div>{/* ends: .process-single */}
                        <div className="process-single text-center">
                            <div className="icon light-bg">
                                <span className="step">8</span>
                                <span className="icon-la"><i className="la la-headphones" /></span>
                            </div>
                            <h6>Fast Support</h6>
                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                        </div>{/* ends: .process-single */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}


import React, { Fragment } from 'react';

const Mission = () => {
        return (
            <Fragment>
                 <section className="p-top-100 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 margin-md-60">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                        <h2 className="m-bottom-20">Anthea's Mission</h2>
                                    </div>
                                </div>
                                <p>We help cannabis manufacturers who value brand security and consumer saftey by providing the tools and services necessary to help them build secure and long term relationships with their customers. And the change we want to make is that cannabis manufacturers begin to leverage security as an asset for their brand and for their customers. We'll know we are successful when these same manufacturers don't see brand securtiy and consumer safety as a cost anymore, they only view it as an asset to all stakeholders involved.</p>

                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                            <div className="col-lg-6">
                                <div className="m-bottom-30">
                                    <div className="divider divider-simple text-left">
                                        <h2 className="m-bottom-20">Anthea's Vision</h2>
                                    </div>
                                </div>
                                <p>Where the people have voted to create a well-regulated cannabis market, our contribution is to help protect the interests of the public, producers, and the state. We do this through the application of services and technologies designed to ensure safe products are delivered to the public and cannabis producers maintain their brand integrity. The long-term value for the cannabis industry lies in establishing a network of trust. This is what Anthea is all about.</p>

                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                        </div>
                    </div>
                </section>{/*<!-- ends: section -->*/}


            </Fragment>
        )
}
export default Mission

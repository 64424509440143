import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Card from '../container/element/card/team-card-2';
import ContentBlock from '../content/contentBlock/contentBlock-service2';
import Cta from '../content/cta/cta-8';
import Slider from '../container/element/carousel/image-carousel-1';
import IconBox from '../content/service/service-iconbox-four';
import {AccordianOne} from '../content/element/accordians/accordians';
import { PricingOne } from '../content/element/pricing/pricing';


const Service = () => {
    return (
        <Fragment>

            <Header pageTitle='Our Services'/>
            <section className="m-top-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Slider />
                        </div>
                    </div>
                </div>
            </section>
            <ContentBlock />
            <IconBox />

            <section className="p-top-100 p-bottom-110">
                <div className="m-bottom-50 p-left-15 p-right-15">
                    <div className="divider text-center">
                        <h1>How We Can Help You</h1>
                        <p className="mx-auto"></p>
                    </div>
                </div>
                <AccordianOne />
            </section>

            <section className="p-top-100 p-bottom-80 section-bg">
                <div className="m-bottom-50 p-left-15 p-right-15">
                    <div className="divider text-center">
                        <h1>Pricing Options</h1>
                        <p className="mx-auto"></p>
                    </div>
                </div>
                <PricingOne />
            </section>

            <section className="p-top-100 p-bottom-80">
                <div className="m-bottom-50 p-left-15 p-right-15">
                    <div className="divider text-center">
                        <h1>Global Team</h1>
                        <p className="mx-auto"></p>
                    </div>
                </div>
                <Card />
            </section>

            <Cta />
            <Footer />
        </Fragment>
    )
}

export default Service;
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';

class Subscribe3 extends Component {
    constructor (props) {
        super (props)
        this.state = {
            email:'',
            success: false
        }
        this.validator = new SimpleReactValidator(); 
        this.setStateFromInput = this.setStateFromInput.bind(this);       
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state);                          
                var success = true;
                return success;
                        
            } else {
                this.validator.showMessages();              
                this.forceUpdate();
                var success2 = false;
                return success2;               
            }
        }       
        return (
            <section className="subscribe-three">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-5">
                                    <h4>Get Latest News Update!</h4>
                                </div>{/*<!-- ends: .col-lg-5 -->*/}
                                <div className="col-lg-7">
                                    <form action="/" className="intro-form m-0" _lpchecked="1">
                                        <div className="input-group input-group-light">
                                            <span className="icon-left" id="basic-addon7"><i className="la la-envelope"></i></span>
                                            <input type="text" value={this.state.email} name="email" onChange={this.setStateFromInput} className="form-control" placeholder="Enter your email" aria-label="Username" />
                                        </div>
                                        <div><button onClick={Shipping_Address} type="submit" className="btn btn-primary">Get Started</button></div>
                                    </form>
                                    <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div> 
                                </div>{/*<!-- ends: .col-lg-7 -->*/}
                            </div>{/*<!-- ends .row -->*/}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Subscribe3;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const Blockquotes = () => {
    return (
        <Fragment>

            <Header pageTitle="Box Shadow" />
            <section className="shadow-types shadow-types2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Box Shadow - 1</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="shadow-sm">
                                        <ul className="list-unstyled">
                                            <li><span>Hoff</span> 0</li>
                                            <li><span>Boff</span> 5</li>
                                            <li><span>Color</span> 10</li>
                                            <li><span>Blur</span> 5afdff</li>
                                            <li><span>Opacity</span> 5%</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>{/* Ends: .showcase-box */}
                        </div>{/* Ends: .col-lg-6 */}
                        <div className="col-lg-6">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Box Shadow - 2</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="shadow">
                                        <ul className="list-unstyled">
                                            <li><span>Hoff</span> 0</li>
                                            <li><span>Boff</span> 5</li>
                                            <li><span>Color</span> 10</li>
                                            <li><span>Blur</span> 5afdff</li>
                                            <li><span>Opacity</span> 5%</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>{/* Ends: .showcase-box */}
                        </div>{/* Ends: .col-lg-6 */}
                    </div>
                </div>
                </section>
                <section className="shadow-types">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="showcase-box">
                                    <div className="showcase-header">
                                        <h4>Box Shadow - 3</h4>
                                    </div>
                                    <div className="showcase-body">
                                        <div className="shadow_lg--bgwhite">
                                            <ul className="list-unstyled">
                                                <li><span>Hoff</span> 0</li>
                                                <li><span>Boff</span> 5</li>
                                                <li><span>Color</span> 10</li>
                                                <li><span>Blur</span> 5afdff</li>
                                                <li><span>Opacity</span> 5%</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>{/* Ends: .showcase-box */}
                            </div>{/* Ends: .col-lg-6 */}
                            <div className="col-lg-6">
                                <div className="showcase-box">
                                    <div className="showcase-header">
                                        <h4>Box Shadow - 4</h4>
                                    </div>
                                    <div className="showcase-body">
                                        <div className="shadow_lg2--bgwhite">
                                            <ul className="list-unstyled">
                                                <li><span>Hoff</span> 0</li>
                                                <li><span>Boff</span> 5</li>
                                                <li><span>Color</span> 10</li>
                                                <li><span>Blur</span> 5afdff</li>
                                                <li><span>Opacity</span> 5%</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>{/* Ends: .showcase-box */}
                            </div>{/* Ends: .col-lg-6 */}
                        </div>
                    </div>
                </section>
            <Footer />
        </Fragment>
    )
}

export default Blockquotes;
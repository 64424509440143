import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import Subscribe2 from '../../content/subscribe/subscribe-2';
import Subscribe3 from '../../content/subscribe/subscribe-3';
import SubscribeWraper from '../../content/subscribe/subscribe-wraper';
import SubscribeFour from '../../content/subscribe/subscribe-5';
import Subscribe7 from '../../content/subscribe/subscribe7';
import Subscribe8 from '../../content/subscribe/subscribe8';


class Subscrib extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Subscribe" />

            <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Subscribe - 1</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Subscribe2 />
                {/* Subscribe - 1 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Subscribe - 2</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Subscribe3 />
                {/* Subscribe - 3 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Subscribe - 3</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <SubscribeWraper />
                {/* Subscribe - 3 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Subscribe - 4</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <SubscribeFour />
                {/* Subscribe - 4 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Subscribe - 5</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Subscribe7   />
                {/* Subscribe - 5 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Subscribe - 6</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Subscribe8  />
                {/* Subscribe - 6 */}

            <Footer />
        </Fragment>
      )
    }
}
export default Subscrib

import React, { Fragment } from 'react';
import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';

var map3Style = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#424b5b"
            },
            {
                "weight": 2
            },
            {
                "gamma": "1"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "weight": 0.6
            },
            {
                "color": "#545b6b"
            },
            {
                "gamma": "0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#545b6b"
            },
            {
                "gamma": "1"
            },
            {
                "weight": "10"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#666c7b"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#545b6b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#424a5b"
            },
            {
                "lightness": "0"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#666c7b"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#2e3546"
            }
        ]
    }
];



const Map3 = (props) => {
    return (
        <Fragment>
            <div className="m-bottom-25">
                <div className="divider divider-simple text-left">
                    <h3>Google Map</h3>
                </div>
            </div>
            <div className="gmap2 tejarat-map" style={props.styles} >
                {/* <div className="map" id="map3"></div> */}
                <Map
                    google={props.google}
                    zoom={13}                    
                    styles={map3Style}
                    initialCenter={{ lat: 40.7128, lng: -73.935242 }}
                >
                    <Marker position={{ lat: 40.7128, lng: -73.935242}} icon= './assets/img/mpc.png' />
                </Map>
                        
            </div>
        </Fragment>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDduF2tLXicDEPDMAtC6-NLOekX0A5vlnY'
  })(Map3);
  
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import {connect} from 'react-redux';
import Slider from '../../container/project/slider';
import FilterItem  from "../../content/element/filter/filter";
import FilterItem2  from "../../content/element/filter/filter-grid-2";
import {CaseItem} from '../../content/case-studies/case-studies-2';

const Gallery = (props) => {
    const { project } = props;
    return (
        <Fragment>

            <Header pageTitle="Gallery" />

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Gallery Images - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <Slider project={{...project}} />
            </div>
            {/* Gallery Images - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Gallery Images - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" data-uk-grid="{controls: '#card_filter'}">
                    {
                        Object.values(project).slice(0, 6).map((value, key) => {
                            const { id, imgSrc, category, title, subtitle } = value;
                            return (
                                <FilterItem
                                    key={ key }
                                    id={ id }
                                    img={ imgSrc }
                                    category={ category }
                                    title={ title }
                                    subtitle={ subtitle }

                                />
                            )
                        })
                    }
                </div>
            </div>
            {/* Gallery Images - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Gallery Images - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" data-uk-grid="{controls: '#card_filter'}">
                    {
                        Object.values(project).slice(0, 6).map((value, key) => {
                            const { id, imgSrc, category, title, subtitle, content } = value;
                            return (
                                <FilterItem2
                                    key={ key }
                                    id={ id }
                                    img={ imgSrc }
                                    category={ category }
                                    title={ title }
                                    subtitle={ subtitle }
                                    content={content}

                                />
                            )
                        })
                    }
                </div>
            </div>
            {/* Gallery Images - 3 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Gallery Images - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container p-bottom-80">
                <div className="row" data-uk-grid="{controls: '#card_filter'}">
                        {
                            Object.values(project).slice(0, 6).map((value, key) => {
                                const {imgSrc, title, id, content} = value;
                                return(
                                    <div className="col-md-4" key={key}>
                                        <CaseItem
                                            imgSrc={imgSrc}
                                            title={title}
                                            content={content}
                                            id={id}
                                        />
                                    </div>
                                )
                            })
                        }
                </div>
            </div>
            {/* Gallery Images - 4 */}

            <Footer />
        </Fragment>
      );
}
const mapStateToProps = (state) => {
    return {
        project : state.project
    }
}
export default connect(mapStateToProps)(Gallery)
import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import SocondaryNav from '../layout/navbar/aboutNavItem';
import Slider from '../container/element/carousel/image-carousel-1';

import Footer from '../layout/footer/footer-5';
import { Tab3 } from '../content/element/tab/tab-2';
import Map1 from '../content/element/map/map-1';


const About = () => {
    return (
        <Fragment>

            <Header pageTitle='Our Locations' />
            <SocondaryNav />
            <section className="p-top-110 p-bottom-70">
                <div className="container">
                    <Slider />
                </div>
            </section>
            <section className="p-bottom-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="m-bottom-65">
                                <div className="divider section-title-simple">
                                    <h2>Our Offices Around the World</h2>
                                    <p className="ml-auto mr-auto m-top-5">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius estre etiam cessus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Tab3 />
            </section>
            <section className="google_map">
                <div className="map" id="map1" >
                    <Map1 />
                </div>{/* end: .map */}
            </section>{/* ends .google_map */}
            <Footer />
        </Fragment>
    )
}

export default About;
import React, { Component } from 'react'
import {NavLink} from 'react-router-dom';
const noAction = (e) => {
    e.preventDefault();
}
class RightSideBar extends Component {
    render() {
        const blog = this.props;
        
        return (
            <div className="col-lg-4">
                    <div className="sidebar">
                        {/* <!-- search widget --> */}
                        <div className="widget-wrapper">
                            <div className="search-widget">
                                <form action="#">
                                    <div className="input-group">
                                        <input type="text" className="fc--rounded" placeholder="Search" />
                                        <button type="submit"><i className="la la-search"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>{/*<!-- ends: .widget-wrapper -->*/}
                        {/* <!-- category widget --> */}
                        <div className="widget-wrapper">
                            <div className="widget-default">
                                <div className="widget-header">
                                    <h6 className="widget-title">Categories</h6>
                                </div>
                                <div className="widget-content">
                                    <div className="category-widget">
                                        <ul>
                                            <li className="arrow-list4"><NavLink to="/" onClick={noAction}>Business</NavLink></li>
                                            <li className="arrow-list4"><NavLink to="/" onClick={noAction}>Finance</NavLink></li>
                                            <li className="arrow-list4"><NavLink to="/" onClick={noAction}>Industry Reports</NavLink></li>
                                            <li className="arrow-list4"><NavLink to="/" onClick={noAction}>Strategy</NavLink></li>
                                            <li className="arrow-list4"><NavLink to="/" onClick={noAction}>Technology</NavLink></li>
                                            <li className="arrow-list4"><NavLink to="/" onClick={noAction}>Marketing</NavLink></li>
                                            <li className="arrow-list4"><NavLink to="/" onClick={noAction}>Strategy</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .widget-wrapper -->*/}
                        {/* <!-- popular post --> */}
                        <div className="widget-wrapper">
                            <div className="widget-default">
                                <div className="widget-header">
                                    <h6 className="widget-title">Popular Post</h6>
                                </div>
                                <div className="widget-content">
                                    <div className="sidebar-post">
                                    {
                                        Object.values(blog).map((value, key) => {
                                            return (
                                                key <= 2 &&  
                                                <div className="post-single" key={value.id}>
                                                    <div className="d-flex align-items-center">
                                                        <NavLink to={`/news-details${value.id}`}  ><img src={value.imgSrc} alt="" /></NavLink>
                                                        <p><span>Jan 25, 2018</span> <span>by <NavLink to='/' onClick={noAction}>Aazztech</NavLink></span></p>
                                                    </div>
                                                    <NavLink to={`/news-details${value.id}`} className="post-title">{value.title}</NavLink>
                                                </div>                                                    
                                            )
                                        })
                                    }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .widget-wrapper -->*/}
                        {/* <!-- popular post --> */}
                        <div className="widget-wrapper">
                            <div className="widget-default">
                                <div className="widget-header">
                                    <h6 className="widget-title">Recent Post</h6>
                                </div>
                                <div className="widget-content">
                                    <div className="sidebar-post">

                                        {
                                            Object.values(blog).map((value, key) => {
                                                return (
                                                key >= 3 &&
                                                    <div className="post-single" key={value.id}>
                                                        <div className="d-flex align-items-center">
                                                            <NavLink to={`/news-details${value.id}`}  ><img src={value.imgSrc} alt="" /></NavLink>
                                                            <p><span>Jan 25, 2018</span> <span>by <NavLink to='/' onClick={noAction}>Aazztech</NavLink></span></p>
                                                        </div>
                                                        <NavLink to={`/news-details${value.id}`} className="post-title">{value.title}</NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .widget-wrapper -->*/}
                        {/*<!-- tags -->*/}
                        <div className="widget-wrapper">
                            <div className="widget-default">
                                <div className="widget-header">
                                    <h6 className="widget-title">Popular Tags</h6>
                                </div>
                                <div className="widget-content">
                                    <div className="tags-widget">
                                        <ul>
                                            <li><NavLink to="/" onClick={noAction}>Business</NavLink></li>
                                            <li><NavLink to="/" onClick={noAction}>Finance</NavLink></li>
                                            <li><NavLink to="/" onClick={noAction}>Strategy</NavLink></li>
                                            <li><NavLink to="/" onClick={noAction}>Global</NavLink></li>
                                            <li><NavLink to="/" onClick={noAction}>Marketing</NavLink></li>
                                            <li><NavLink to="/" onClick={noAction}>Technology</NavLink></li>
                                            <li><NavLink to="/" onClick={noAction}>Wordpress</NavLink></li>
                                            <li><NavLink to="/" onClick={noAction}>Solution</NavLink></li>
                                            <li><NavLink to="/" onClick={noAction}>Bizillion</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .widget-wrapper -->*/}
                        {/* <!-- subscribe --> */}
                        <div className="widget-wrapper">
                            <div className="widget-default">
                                <div className="widget-header">
                                    <h6 className="widget-title">Stay Updated</h6>
                                </div>
                                <div className="widget-content">
                                    <div className="subscribe-widget">
                                        <form action="#">
                                            <input type="email" className="form-control m-bottom-20" placeholder="Enter email" />
                                            <button className="btn btn-sm btn-primary shadow-none" type="submit">Subscribe</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- ends: .widget-wrapper --> */}
                        {/* <!-- Social Connect --> */}
                        <div className="widget-wrapper">
                            <div className="widget-default">
                                <div className="widget-header">
                                    <h6 className="widget-title">Connect &amp; Follow</h6>
                                </div>
                                <div className="widget-content">
                                    <div className="social social--small social--gray ">
                                        <ul className="d-flex flex-wrap">
                                            <li><NavLink to="/" onClick={noAction} className="facebook"><span className="fab fa-facebook-f"></span></NavLink></li>
                                            <li><NavLink to="/" onClick={noAction} className="twitter"><span className="fab fa-twitter"></span></NavLink></li>
                                            <li><NavLink to="/" onClick={noAction} className="linkedin"><span className="fab fa-linkedin-in"></span></NavLink></li>
                                            <li><NavLink to="/" onClick={noAction} className="gplus"><span className="fab fa-google-plus-g"></span></NavLink></li>
                                        </ul>
                                    </div>{/*<!-- ends: .social -->*/}
                                </div>
                            </div>
                        </div>{/*<!-- ends: .widget-wrapper -->*/}
                    </div>{/*<!-- ends: .sidebar -->*/}
                </div>
        )
    }
}
export default RightSideBar;
import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

const SubscribeFour = () => {
    return (
        <section className="p-top-50 p-bottom-50 subscribe--five gradient-overlay">
            <div className="container content_above">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <img src="./assets/img/sb3_img.png" alt="" />
                    </div>
                    <div className="col-lg-6 offset-lg-1">
                        <h2>Get Subscribed Now!</h2>
                        <p>Subscribe to get update and information. Don&#39;t worry, we won&#39;t send spam!</p>
                        <NavLink to="/at_demo" onClick={noAction} className="btn btn-primary">Request Now</NavLink>
                    </div>
                </div>{/*<!-- ends .row -->*/}
            </div>
        </section>
    )
}
export default SubscribeFour;
import $ from "jquery";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 3,
    margin: 30,
    rtl: falsy,
    nav: false,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 3
        }
    }
};
class Slider extends Component {
    render() {
        const {blog} = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <OwlCarousel options={options} className="blog-carousel-one owl-carousel">
                            {
                                Object.values(blog).map((value, key) => {
                                    const {id, imgSrc, title, date, content} = value;
                                    return (
                                        <div className="carousel-single" key={id}>
                                            <div className="post-single">
                                                <div className="post-meta">
                                                    <ul>
                                                        <li><span className="bg-primary">{date}</span></li>
                                                        <li>in <a href="fdfd" onClick={noAction} className="color-secondary">Finance News</a></li>
                                                    </ul>
                                                </div>
                                                <h6><NavLink to={"/news-details"+id} className="color-dark">{title}</NavLink></h6>
                                                <p>{content}</p>
                                                <div className="post-bottom">
                                                    <img src={imgSrc} style={{width: '30px', height: '30px'}} alt="" className="rounded-circle" />
                                                    <a href="fdf" onClick={noAction}><span>Robert Miller</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        blog : state.blog
    }
}
export default connect(mapStateToProps)(Slider);
import React, { Component, Fragment } from 'react'

import {ProjectInfo} from '../../content/project/project-info';
import {ProjectChalange} from '../../content/project/project-chalange';
import {ProjectResult} from './project-result';
export default class Project extends Component {
    render() {
        const {content} = this.props;
        return (
            <Fragment>
                <section className="project-single p-top-100 p-bottom-70 border-bottom">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="m-bottom-70">
                                    <div className="video-single video-overlay">
                                        <figure>
                                            <img src="./assets/img/v1.jpg" alt="" />
                                            <figcaption className="d-flex justify-content-center align-items-center">
                                                <a href="https://www.youtube.com/watch?v=omaTcIbwt9c" className="video-iframe play-btn play-btn--lg play-btn-light"><img src="./assets/img/svg/play-btn.svg" alt="" className="svg" /></a>
                                            </figcaption>
                                        </figure>
                                    </div>{/*<!-- ends: .video-single -->*/}
                                </div>
                            </div>{/*<!-- ends: .col-lg-8 -->*/}

                            <div className="col-lg-12">
                                <ProjectInfo content={content}/>
                            </div>
                        </div>
                    </div>
                </section>
                <ProjectChalange />
                <ProjectResult />
            </Fragment>
        )
    }
}

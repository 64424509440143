import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { IconBlock } from '../../content/element/addressBlock';
import { IconBoxOne, IconBoxTwo, IconBoxThree, IconBoxFour, IconBoxSeven, IconBoxEight, IconBoxTeen, IconBoxEleven, IconBoxThirteen, IconBoxFifteen, IconBoxSixteen } from '../../content/element/iconBox/iconBox';



const IconBox = (props) => {

    return (
        <Fragment>

            <Header pageTitle="Icon Box" />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <IconBlock />
            </div>
            {/* Icon box - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <IconBoxOne padding="p-0" />
            </div>
            {/* Icon box - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <IconBoxTwo />
            </div>
            {/* Icon box - 3 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <IconBoxThree />
            {/* Icon box - 4 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <IconBoxFour />
            </div>
            {/* Icon box - 5 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <IconBoxSeven />
            {/* Icon box - 6 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <IconBoxEight />
            {/* Icon box - 7 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 8</h3>
                        </div>
                    </div>
                </div>
            </div>
            <IconBoxTeen padding="p-0" />
            {/* Icon box - 8 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 9</h3>
                        </div>
                    </div>
                </div>
            </div>
            <IconBoxEleven />
            {/* Icon box - 9 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 10</h3>
                        </div>
                    </div>
                </div>
            </div>
            <IconBoxThirteen />
            {/* Icon box - 10 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 11</h3>
                        </div>
                    </div>
                </div>
            </div>
            <IconBoxFifteen />
            {/* Icon box - 11 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Icon box - 12</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <IconBoxSixteen />
            </div>
            {/* Icon box - 12 */}

            <section className="p-top-30 p-bottom-50"></section>
            <Footer />
        </Fragment>
      );
}

export default IconBox
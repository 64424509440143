import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { Process1, Process2 } from '../../content/element/process/process';


const Process = (props) => {
      return (
        <Fragment>

            <Header pageTitle="Processes" />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Process - 1</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Process1 />
                </div>
                {/* Process - 1 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Process - 2</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-bg p-top-95">
                    <Process2 />
                </section>
                {/* Process - 2 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Process - 3</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="process-wrapper process--one">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="process-one">
                                    <div className="process-single text-center">
                                        <div className="icon gray-bg">
                                            <span className="step">1</span>
                                            <span className="icon-la"><i className="la la-sun-o"></i></span>
                                        </div>
                                        <h6>Initial Concept</h6>
                                        <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                                    </div>{/*<!-- ends: .process-single -->*/}
                                    <div className="process-single text-center">
                                        <div className="icon gray-bg">
                                            <span className="step">2</span>
                                            <span className="icon-la"><i className="la la-bar-chart"></i></span>
                                        </div>
                                        <h6>Market Analysis</h6>
                                        <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                                    </div>{/*<!-- ends: .process-single -->*/}
                                    <div className="process-single text-center">
                                        <div className="icon gray-bg">
                                            <span className="step">3</span>
                                            <span className="icon-la"><i className="la la-rocket"></i></span>
                                        </div>
                                        <h6>Grow Business</h6>
                                        <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                                    </div>{/*<!-- ends: .process-single -->*/}
                                    <div className="process-single text-center">
                                        <div className="icon gray-bg">
                                            <span className="step">4</span>
                                            <span className="icon-la"><i className="la la-headphones"></i></span>
                                        </div>
                                        <h6>Fast Support</h6>
                                        <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                                    </div>{/*<!-- ends: .process-single -->*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/*<!-- ends: .process-wrapper -->*/}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Process - 4</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-bg p-top-95">
                    <div className="process-wrapper section-bg process--two">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="process-one">
                                        <div className="process-single text-center">
                                            <div className="icon light-bg">
                                                <span className="step">1</span>
                                                <span className="icon-la"><i className="la la-sun-o"></i></span>
                                            </div>
                                            <h6>Initial Concept</h6>
                                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                                        </div>{/*<!-- ends: .process-single -->*/}
                                        <div className="process-single text-center">
                                            <div className="icon light-bg">
                                                <span className="step">2</span>
                                                <span className="icon-la"><i className="la la-bar-chart"></i></span>
                                            </div>
                                            <h6>Market Analysis</h6>
                                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                                        </div>{/*<!-- ends: .process-single -->*/}
                                        <div className="process-single text-center">
                                            <div className="icon light-bg">
                                                <span className="step">3</span>
                                                <span className="icon-la"><i className="la la-rocket"></i></span>
                                            </div>
                                            <h6>Grow Business</h6>
                                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                                        </div>{/*<!-- ends: .process-single -->*/}
                                        <div className="process-single text-center">
                                            <div className="icon light-bg">
                                                <span className="step">4</span>
                                                <span className="icon-la"><i className="la la-headphones"></i></span>
                                            </div>
                                            <h6>Fast Support</h6>
                                            <p>Investig ationes demons trage vunt lectores legere lyrus qudk legunt saepius claritas.</p>
                                        </div>{/*<!-- ends: .process-single -->*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/*<!-- ends: .process-wrapper -->*/}
                </section>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Process - 5</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="process-wrapper section-bg py-5 process--three">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="process-three">
                                    <span>01</span>
                                    <h6>Business Consulting</h6>
                                    <p>Investig atones demon travg vunt is ar lecto is kres legere lrus quod leg unt an sae pius clar itasest demons tavg vunt won sharing quod training.</p>
                                </div>{/*<!-- end: .process-three -->*/}
                            </div>{/*<!-- ends: .col-lg-4 -->*/}
                            <div className="col-lg-4">
                                <div className="process-three">
                                    <span>02</span>
                                    <h6>Market Analysis</h6>
                                    <p>Investig atones demon travg vunt is ar lecto is kres legere lrus quod leg unt an sae pius clar itasest demons tavg vunt won sharing quod training.</p>
                                </div>{/*<!-- end: .process-three -->*/}
                            </div>{/*<!-- ends: .col-lg-4 -->*/}
                            <div className="col-lg-4">
                                <div className="process-three">
                                    <span>03</span>
                                    <h6>Online Consulting</h6>
                                    <p>Investig atones demon travg vunt is ar lecto is kres legere lrus quod leg unt an sae pius clar itasest demons tavg vunt won sharing quod training.</p>
                                </div>{/*<!-- end: .process-three -->*/}
                            </div>{/*<!-- ends: .col-lg-4 -->*/}
                        </div>
                    </div>
                </div>{/*<!-- ends: .process-wrapper -->*/}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Process - 6</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content-block block-full-width2">
                    <div className="img bgimage">
                        <div className="bg_image_holder">
                            <img src="./assets/img/cb4.png" alt="" />
                        </div>
                    </div>{/*<!-- ends:.img -->*/}
                    <div className="container content_above">
                        <div className="row">
                            <div className="col-lg-5">
                                <ul className="process-list process--list2">
                                    <li className="d-flex justify-content-center">
                                        <div className="number gray-bg">
                                            <span>01</span>
                                        </div>
                                        <div className="content">
                                            <h6>Reliability</h6>
                                            <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes lectores.</p>
                                        </div>
                                    </li>
                                    <li className="d-flex justify-content-center">
                                        <div className="number gray-bg">
                                            <span>02</span>
                                        </div>
                                        <div className="content">
                                            <h6>Industry Experience</h6>
                                            <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes lectores.</p>
                                        </div>
                                    </li>
                                    <li className="d-flex justify-content-center">
                                        <div className="number gray-bg">
                                            <span>03</span>
                                        </div>
                                        <div className="content">
                                            <h6>Professional Team</h6>
                                            <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes lectores.</p>
                                        </div>
                                    </li>
                                </ul>{/*<!-- ends:.process-list -->*/}
                            </div>{/*<!-- ends:.col-lg-5 -->*/}
                        </div>
                    </div>{/*<!-- ends: .container -->*/}
                </section>{/*<!-- ends: .content-block -->*/}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Process - 7</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="process-wrapper p-bottom-110 process--five">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 margin-md-60">
                                <img src="./assets/img/cb4.png" alt="" />
                            </div>
                            <div className="col-lg-5 offset-lg-1">
                                <ul className="process-list process--list2">
                                    <li className="d-flex justify-content-center">
                                        <div className="number gray-bg">
                                            <span>01</span>
                                        </div>
                                        <div className="content">
                                            <h6>Reliability</h6>
                                            <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes lectores.</p>
                                        </div>
                                    </li>
                                    <li className="d-flex justify-content-center">
                                        <div className="number gray-bg">
                                            <span>02</span>
                                        </div>
                                        <div className="content">
                                            <h6>Industry Experience</h6>
                                            <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes lectores.</p>
                                        </div>
                                    </li>
                                    <li className="d-flex justify-content-center">
                                        <div className="number gray-bg">
                                            <span>03</span>
                                        </div>
                                        <div className="content">
                                            <h6>Professional Team</h6>
                                            <p>Investig ationes demons trave fhunt lectores legere lius quod getons trave atnes lectores.</p>
                                        </div>
                                    </li>
                                </ul>{/*<!-- ends:.process-list -->*/}
                            </div>
                        </div>
                    </div>
                </div>{/*<!-- ends: .process-wrapper -->*/}
            <Footer />
        </Fragment>
      );
}
export default Process
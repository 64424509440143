import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { PricingOne, PricingTwo } from '../../content/element/pricing/pricing';


const PricingTable = (props) => {
      return (
        <Fragment>

            <Header pageTitle="Pricing Tables" />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Pricing Table - 1</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <PricingOne />
                {/* Pricing Table - 1 */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Pricing Table - 2</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <PricingTwo />
                {/* Pricing Table - 2 */}
            <Footer />
        </Fragment>
      );
}

export default PricingTable
import React, {Fragment} from 'react';


const ComingSoon = () => {
    return (
        <Fragment>

            <section className="coming-soon bgimage biz_overlay overlay--secondary2">
              <div className="bg_image_holder">
                <img src="./assets/img/image3.jpg" alt="" />
              </div>
              <div className="container content_above">
                <div className="row">
                  <div className="col-lg-6 offset-lg-3 text-center">
                    <div className="cs-contents">
                      <img src="./assets/img/logo-white.png" alt="" />
                      <span className="cs-text">Coming Very Soon</span>
                      <div className="d-flex justify-content-center m-bottom-60">
                        <ul className="countdown" />
                      </div>
                      <p>You can subscribe to get notified once it’s launched. Find us and get in touch.</p>
                      <form action="#">
                        <div className="subscribe-form d-flex">
                          <div className="input-group input-group-light">
                            <span className="icon-left" id="basic-addon7"><i className="la la-envelope" /></span>
                            <input type="text" className="form-control" placeholder="Enter your email" aria-label="Username" />
                          </div>{/* End: .input-group */}
                          <button className="btn btn-warning m-left-20" type="submit">Subscribe Now</button>
                        </div>
                      </form>
                    </div>
                  </div>{/* ends: .col-lg-8 */}
                </div>
              </div>
            </section>
        </Fragment>
      )
}

export default ComingSoon;
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Index from './page/index';
import Index2 from './page/index-2';
import Index3 from './page/index-3';
import Index4 from './page/index-4';
import Index5 from './page/index-5';
import Index6 from './page/index-6';
import Index7 from './page/index-7';
import Index8 from './page/index-8';
import Index9 from './page/index-9';
import Index10 from './page/index-10';
import Index11 from './page/index-11';
import Index12 from './page/index-12';
import Index13 from './page/index-13';
import Index14 from './page/index-14';
import Index15 from './page/index-15';

import PageNotFound from './page/404';

import About from './page/about';
import About2 from './page/about-two';
import History from './page/history';
import Values from './page/values';
import BsConduct from './page/bs-conduct';
import Clients from './page/clients';
import Locations from './page/locations';
import sStory from './page/success-story';

import BlogRight from './page/blog-right-sidebar';
import BlogLeft from './page/blog-left-sidebar';
import BlogMasonry from './page/blog-masonry';
import BlogMasonry2 from './page/blog-masonry-2';
import BlogGrid from './page/blog-grid';
import BlogFull from './page/blog-full-width';
import NewsDetails from './page/news-details';
import BlogDetailsAudio from './page/blog-details-audio';
import BlogDetailsGallery from './page/blog-details-gallery';
import BlogDetailsVideo from './page/blog-details-video';
import BlogDetailsSlider from './page/blog-details-slider';
import BlogDetailsLink from './page/blog-details-link';
import BlogDetailsFull from './page/blog-details-full';

import Service1 from './page/service-one';
import Service2 from './page/service-two';
import Service3 from './page/service-three';
import ServiceImg from './page/service-image';
import ServiceIcon from './page/service-icon';

import ProjectGrid1 from './page/project-grid-1';
import ProjectGrid2 from './page/project-grid-2';
import ProjectGrid3 from './page/project-grid-3';
import ProjectGridFilter from './page/project-grid-filter';
import ProjectGridFilter2 from './page/project-grid-filter-2';
import ProjectMasonry from './page/project-masonry';
import ProjectDetails from './page/project-details';
import ProjectDetails2 from './page/project-2-details';
import ProjectDetails3 from './page/project-3-details';

import TeamGrid from './page/team-grid';
import TeamList from './page/team-list';
import TeamDetails from './page/team-details';

import EventGrid from './page/envent-grid';
import EventList from './page/event-list';
import EventDetails from './page/event-details';
import Upcoming from './page/upcoming';
import Expired from './page/expired';

import Contact from './page/contact-2';

import Faqs from './page/faq';
import Login from './page/login';
import Registration from './page/registration';
import Pricing from './page/pricing-plan';
import Testimonial from './page/testimonial';
import workingPro from './page/working-pro';
import Careers from './page/careers';
import CareersSingle from './page/career-single';
import Apply from './page/apply-form';
import ComingSoon from './page/coming-soon';

import ShopHome from './page/shop-home';
import SingleProduct from './page/single-product';
import ShopProduct from './page/shop-product';
import cartAdd from './page/cart-add';
import CheckOut from './page/checkout';
import CheckOutConfirm from './page/checkout-confirm';

import Alerts from './page/elements/alerts';
import Accordion from './page/elements/accordion';
import Blockquote from './page/elements/blockquotes';
import BoxShadow from './page/elements/box-shadow';
import Bredcrumb from './page/elements/breadcrumb';
import Buttons from './page/elements/buttons';
import CallToAction from './page/elements/call-to-action';
import Cards from './page/elements/cards';
import Carousels from './page/elements/carousel';
import Client from './page/elements/clients';
import Colors from './page/elements/colors';
import ContactBlock from './page/elements/contact-block';
import ContentBlock from './page/elements/content-block';
import ContactForms from './page/elements/contact-forms';
import Counter from './page/elements/counter';
import Dividers from './page/elements/dividers';
import Dropdowns from './page/elements/dropdowns';
import EmbedVideo from './page/elements/embed-videos';
import Filters from './page/elements/filters';
import FlipBox from './page/elements/flip-box';
import Footers from './page/elements/footers';
import FormElements from './page/elements/forms';
import Gallery from './page/elements/gallery';
import Graphs from './page/elements/graphs';
import IconBox from './page/elements/iconBox';
import Lists from './page/elements/list';
import Maps from './page/elements/maps';
import Modal from './page/elements/modal';
import Pagination from './page/elements/pagination';
import Parallax from './page/elements/parallax';
import PricingTable from './page/elements/pricing-table';
import Processes from './page/elements/processes';
import ProgressBar from './page/elements/progress-bar';
import RangeSlider from './page/elements/range-slider';
import Ratings from './page/elements/rating';
import Sliders from './page/elements/slider';
import Splitted from './page/elements/splitted-banners';
import Subscrib from './page/elements/subscribe';
import Typography from './page/elements/typography';
import Tables from './page/elements/tables';
import Tabs from './page/elements/tabs';
import Teams from './page/elements/teams';
import TestimonialStyles from './page/elements/testimonial-style';
import Timeline from './page/elements/timelines';
import TwitterFeed from './page/elements/twitter-feeds';
import VideoBg from './page/elements/video-bg';
import Videos from './page/elements/videos';
import Headers from './page/elements/header';

function App(props) {
  return (
    <Router basename={process.env.PUBLIC_URL} >
        <Switch>
          <Route exact path = '/' component = { Index10 } />

          <Route path = '/about' component = { About } />
          <Route path = '/history' component = { History } />
          <Route path = '/values' component = { Values } />
          <Route path = '/business-conduct' component = { BsConduct } />
          <Route path = '/clients' component = { Clients } />
          <Route path = '/locations' component = { Locations } />
          <Route path = '/success-story' component = { sStory } />

          <Route path = '/blog-right' component = { BlogRight } />
          <Route path = '/blog-left' component = { BlogLeft } />
          <Route path = '/blog-masonry' component = { BlogMasonry } />
          <Route path = '/blog-masonry2' component = { BlogMasonry2 } />
          <Route path = '/blog-grid' component = { BlogGrid } />
          <Route path = '/blog-full-width' component = { BlogFull } />
          <Route path = '/news-details:id' component = { NewsDetails } />
          <Route path = '/blog-details-audio:id' component = { BlogDetailsAudio } />
          <Route path = '/blog-details-gallery:id' component = { BlogDetailsGallery } />
          <Route path = '/blog-details-video:id' component = { BlogDetailsVideo } />
          <Route path = '/blog-details-slider:id' component = { BlogDetailsSlider } />
          <Route path = '/blog-details-link:id' component = { BlogDetailsLink } />
          <Route path = '/blog-details-full:id' component = { BlogDetailsFull } />

          <Route path = '/services' component = { Service1 } />
          <Route path = '/service2' component = { Service2 } />
          <Route path = '/service3' component = { Service3 } />
          <Route path = '/service-image' component = { ServiceImg } />
          <Route path = '/service-icon' component = { ServiceIcon } />

          <Route path = '/team-grid' component = { TeamGrid } />
          <Route path = '/team-list' component = { TeamList } />
          <Route path = '/team-details:id' component = { TeamDetails } />

          <Route path = '/event-grid' component = { EventGrid } />
          <Route path = '/event-List' component = { EventList } />
          <Route path = '/event-details:id' component = { EventDetails } />
          <Route path = '/upcoming' component = { Upcoming } />
          <Route path = '/expired' component = { Expired } />

          <Route path = '/contact' component = { Contact } />

          <Route path = '/faqs' component = { Faqs } />
          <Route path = '/login' component = { Login } />
          <Route path = '/register' component = { Registration } />
          <Route path = '/pricing' component = { Pricing } />
          <Route path = '/testimonial' component = { Testimonial } />
          <Route path = '/Working-process' component = { workingPro } />
          <Route path = '/careers' component = { Careers } />
          <Route path = '/career-single' component = { CareersSingle } />
          <Route path = '/apply' component = { Apply } />
          <Route path = '/coming-soon' component = { ComingSoon } />

          <Route path = '/project-grid-1' component = { ProjectGrid1 } />
          <Route path = '/project-grid-2' component = { ProjectGrid2 } />
          <Route path = '/project-grid-3' component = { ProjectGrid3 } />
          <Route path = '/project-grid-filter' component = { ProjectGridFilter } />
          <Route path = '/project-grid-2-filter' component = { ProjectGridFilter2 } />
          <Route path = '/project-masonry' component = { ProjectMasonry } />
          <Route path = '/project-details:id' component = { ProjectDetails } />
          <Route path = '/project-2-details:id' component = { ProjectDetails2 } />
          <Route path = '/project-3-details:id' component = { ProjectDetails3 } />

          <Route path = '/shop-home' component = { ShopHome } />
          <Route path = '/shop-products' component = { ShopProduct } />
          <Route path = '/shopping-cart' component = { cartAdd } />
          <Route path = '/single-product:id' component = { SingleProduct } />

          <Route path = '/checkout' component = { CheckOut } />
          <Route path = '/checkout-confirm' component = { CheckOutConfirm } />

          <Route path = '/alerts' component = { Alerts } />
          <Route path = '/accordion' component = { Accordion } />
          <Route path = '/blockquotes' component = { Blockquote } />
          <Route path = '/box-shadow' component = { BoxShadow } />
          <Route path = '/breadcrumb' component = { Bredcrumb } />
          <Route path = '/buttons' component = { Buttons } />
          <Route path = '/call-to-action' component = { CallToAction } />
          <Route path = '/cards' component = { Cards } />
          <Route path = '/carousels' component = { Carousels } />
          <Route path = '/client' component = { Client } />
          <Route path = '/colors' component = { Colors } />
          <Route path = '/contact-blocks' component = { ContactBlock } />
          <Route path = '/content-blocks' component = { ContentBlock } />
          <Route path = '/contact-forms' component = { ContactForms } />
          <Route path = '/counter' component = { Counter } />
          <Route path = '/dividers' component = { Dividers } />
          <Route path = '/dropdowns' component = { Dropdowns } />
          <Route path = '/embed-videos' component = { EmbedVideo } />
          <Route path = '/filters' component = { Filters } />
          <Route path = '/flip-box' component = { FlipBox } />
          <Route path = '/footers' component = { Footers } />
          <Route path = '/form-elements' component = { FormElements } />
          <Route path = '/gallery' component = { Gallery } />
          <Route path = '/graphs' component = { Graphs } />
          <Route path = '/icon-box' component = { IconBox } />
          <Route path = '/Lists' component = { Lists } />
          <Route path = '/maps' component = { Maps } />
          <Route path = '/modal' component = { Modal } />
          <Route path = '/pagination' component = { Pagination } />
          <Route path = '/parallax' component = { Parallax } />
          <Route path = '/pricing-tables' component = { PricingTable } />
          <Route path = '/processes' component = { Processes } />
          <Route path = '/progress-bar' component = { ProgressBar } />
          <Route path = '/range-slider' component = { RangeSlider } />
          <Route path = '/ratings' component = { Ratings } />
          <Route path = '/sliders' component = { Sliders } />
          <Route path = '/splitted-banners' component = { Splitted } />
          <Route path = '/subscribe' component = { Subscrib } />
          <Route path = '/typography' component = { Typography } />
          <Route path = '/tables' component = { Tables } />
          <Route path = '/tabs' component = { Tabs } />
          <Route path = '/teams' component = { Teams } />
          <Route path = '/testimonial-styles' component = { TestimonialStyles } />
          <Route path = '/timelines' component = { Timeline } />
          <Route path = '/twitter-feed' component = { TwitterFeed } />
          <Route path = '/video-background' component = { VideoBg } />
          <Route path = '/videos' component = { Videos } />
          <Route path = '/headers' component = { Headers } />

          <Route path = '' component = { PageNotFound } />
        </Switch>
    </Router>
  );
}
export default App;

import React, {Fragment} from 'react';
import Header from '../layout/header/login-register';
import { SignUp } from '../content/element/form/registration';


const Registration = () => {
    return (
        <Fragment>

            <Header>
               <SignUp />
            </Header>

        </Fragment>
    )
}

export default Registration;
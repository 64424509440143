import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import TeamWrap from '../../container/team/team-wrap';
import Team2 from '../../container/team/team-skiled';
import Team3 from '../../container/team/expert';
import Team4 from '../../container/team/team-4';
import Team5 from '../../container/element/carousel/team-carousel-one';
import Team6 from '../../container/element/card/team-card-3';


class Teams extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Teams" />

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Teams - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <TeamWrap />
            {/* Team - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Teams - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <Team2 padding="p-0"/>
            </div>
            {/* Team - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Teams - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <Team3 padding="p-0"/>
            </div>
            {/* Team - 3 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Teams - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Team4 />
            {/* Team - 4 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Teams - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <Team5 />
            </div>
            {/* Team - 5 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Teams - 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-top-110 p-bottom-80 section-bg">
                <Team6 />
            </div>

            {/* Team - 6 */}

            <Footer />
        </Fragment>
      )
    }
}
export default Teams

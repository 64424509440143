import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';

const SinglePage = (props) => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar /> 
            </section>
            {/* <!-- ends: .intro-area --> */}
            <section className="breadcrumb_area breadcrumb3 bgimage biz_overlay">
                <div className="bg_image_holder">
                    <img src="./assets/img/breadbg2.jpg" alt="" />
                </div>
                <div className="container content_above">
                <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb_wrapper d-flex flex-column align-items-center">
                            <span className="subtext">Careers</span>
                            <h4 className="page_title">Build a Rewarding Career at Tejarat</h4>
                        </div>
                    </div>
                </div>{/* ends: .row */}
                </div>
            </section>
        </Fragment>                      
    )   
}
export default SinglePage;
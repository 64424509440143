import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import SocondaryNav from '../layout/navbar/aboutNavItem';



import Footer from '../layout/footer/footer-5';
import { IconBoxThirteen } from '../content/element/iconBox/iconBox';
const About = () => {
    return (
        <Fragment>

            <Header pageTitle='Our Values' />
            <SocondaryNav />
            <section className="values-info p-top-105 p-bottom-80">
                <div className="m-bottom-50">
                    <div className="divider text-center m-bottom-50">
                        <h2 className="color-dark m-0">Our Core Values</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 margin-md-60">
                            <div className="p-box shadow_lg--bgwhite">
                                <h4>Six basic beliefs that guide and encourage us to achieve lasting success for our clients and firm.</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 value-desc">
                            <p className="p-left-30 m-bottom-0">Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes demons trave huerunt lectores legere liusry. Investiga saepius. <br /><br /> Clarias est Investig ationes demonslectores legere liusry squitur mutaety tionem consum etudium. Investig ationes.</p>
                        </div>
                    </div>
                </div>{/* ends: .container */}
                <hr className="m-top-105 m-bottom-110 border-top" />

                <IconBoxThirteen />
            </section>
            <Footer />
        </Fragment>
    )
}

export default About;
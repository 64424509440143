import React from 'react';

export const ImageBlock = () => {
    return (
        <div className="address-blocks">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="adress">
                            <img src="./assets/img/ukf.png" alt="img/ukf.png" />
                            <p className="nam">United Kingdom</p>
                            <p>49 Featherstone Street London, United Kingdom +88 468 888 800</p>
                        </div>{/*<!-- end: .adress -->*/}
                    </div>{/*<!-- ends: .col-lg-3 -->*/}
                    <div className="col-lg-3 col-md-6">
                        <div className="adress">
                            <img src="./assets/img/gerf.png" alt="img/gerf.png" />
                            <p className="nam">Germany</p>
                            <p>32 Neuwe Doelenstraat Amsterdam, Germany +44 647 888 400</p>
                        </div>{/*<!-- end: .adress -->*/}
                    </div>{/*<!-- ends: .col-lg-3 -->*/}
                    <div className="col-lg-3 col-md-6">
                        <div className="adress">
                            <img src="./assets/img/engf.png" alt="img/engf.png" />
                            <p className="nam">Australia</p>
                            <p>96 South Park Avenue Melbourne, Australia +44 647 888 400</p>
                        </div>{/*<!-- end: .adress -->*/}
                    </div>{/*<!-- ends: .col-lg-3 -->*/}
                    <div className="col-lg-3 col-md-6">
                        <div className="adress">
                            <img src="./assets/img/usaf.png" alt="img/usaf.png" />
                            <p className="nam">USA</p>
                            <p>49 Featherstone Street London, United Kingdom +88 468 888 800</p>
                        </div>{/*<!-- end: .adress -->*/}
                    </div>{/*<!-- ends: .col-lg-3 -->*/}
                </div>
            </div>{/*<!-- ends: .container -->*/}
        </div>
    )
}

export const IconBlock = (padding) => {
    return (
        <div className="list-inline-wrapper p-top-80 p-bottom-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="icon-list--three d-flex list--inline">
                            <li className="d-flex align-items-center">
                                <div className="icon"><span><i className="la la-phone"></i></span></div>
                                <div className="contents">
                                    <h6>(215)-534-4054</h6>
                                </div>
                            </li>
                            <li className="d-flex align-items-center">
                                <div className="icon"><span><i className="la la-envelope"></i></span></div>
                                <div className="contents">
                                    <h6>info@antheapro.com</h6>
                                </div>
                            </li>
                            <li className="d-flex align-items-center">
                                <div className="icon"><span><i className="la la-map-marker"></i></span></div>
                                <div className="contents">
                                    <h6>Telford, PA</h6>
                                </div>
                            </li>
                        </ul>{/*<!-- ends: .icon-list--three -->*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

import $ from "jquery";
import React, { Component } from 'react';
import TestimonialFiveCont from '../../content/testimonial/testimonial-5';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}


const options = {
    items: 2,
    nav: false,
    rtl: falsy,
    loop: false,
    /*navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    navContainerClass: "nav-square nav-square-dark",*/
    dots: false,
    autoplay: false,
    responsive: {
        0: {
            items: 1
        },
        992: {
            items: 2
        }
    }
}

class TestimonialFive extends Component {
    render() {
        const {testimonial} = this.props

        return (

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <OwlCarousel options={options} className="testimonial-carousel-five owl-carousel">
                            {
                                Object.values(testimonial).map((value, key) => {
                                    return <TestimonialFiveCont name={value.name}
                                    subtitle={value.subtitle}
                                    content={value.content}
                                    img={value.imgSrc}
                                    key={key+1}
                                    />
                                })
                            }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        testimonial : state.testimonial
    }
}
export default connect(mapStateToProps)(TestimonialFive);
import React, {Fragment} from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import UpcomingEvent from '../container/element/card/upcoming-event-card';


class Event extends React.Component {

    render () {
        return (
            <Fragment>

                <Header pageTitle='Event List'/>
                <section className="section-padding2 section-bg">
                    <UpcomingEvent />
                </section>
                <Footer />
            </Fragment>
        )
    }
}

export default Event;
import React from 'react';
import { NavLink } from 'react-router-dom';
const Filter = props => {
    const {id, title, img, subtitle, category} = props;
    return (
        <div data-uk-filter={category} className="col-lg-4 col-md-6">
            <div className="card card-shadow card-one">
                <figure>
                    <img src={ img } alt="" />
                    <figcaption>
                        <NavLink to={ "/project-details"+id }><i className="la la-link"></i></NavLink>
                    </figcaption>
                </figure>
                <div className="card-body">
                    <p className="card-subtitle color-secondary">{ subtitle }</p>
                    <h6><NavLink to="/">{ title }</NavLink></h6>
                </div>
            </div>{/*<!-- Ends: .card -->*/}
        </div>
        
    )
}

export default Filter;
import React from 'react';

const ContentBlock15 = (props)=> {
    return (
        <section className={props.padding ? props.padding : "content-block content-block--15"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 section-title">
              <h2>Amazing Business Solution <br /> Whatever Your Needs</h2>
              <p>Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet consectetur adipisicing dolore.</p>
            </div>{/* ends: .section-title */}
            <div className="col-lg-12 m-bottom-20">
              <div className="row align-items-center">
                <div className="col-lg-5 order-lg-0 order-1">
                  <div className="content-desc">
                    <h4>Insurance And Finance</h4>
                    <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veri tatis et quasi archi. Totam rem aperiam, eaque ipsa quae abillo. Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi.
                      <br /><br /> On the other hand we denounce with righteous indignation and dislike men who are so beguiled.</p>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 order-lg-1 order-0">
                  <img src="./assets/img/service1.png" alt="" className="img-fluid" />
                </div>
              </div>
            </div>{/* ends: .col-lg-12 */}
            <div className="col-lg-12 m-bottom-20">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <img src="./assets/img/service2.png" alt="" className="img-fluid" />
                </div>
                <div className="col-lg-5 offset-lg-1">
                  <div className="content-desc">
                    <h4>Business And Consulting</h4>
                    <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veri tatis et quasi archi. Totam rem aperiam, eaque ipsa quae abillo. Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi.
                      <br /><br /> On the other hand we denounce with righteous indignation and dislike men who are so beguiled.</p>
                  </div>
                </div>
              </div>
            </div>{/* ends: .col-lg-12 */}
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-5 order-lg-0 order-1">
                  <div className="content-desc">
                    <h4>Strategy Advisory</h4>
                    <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veri tatis et quasi archi. Totam rem aperiam, eaque ipsa quae abillo. Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi.
                      <br /><br /> On the other hand we denounce with righteous indignation and dislike men who are so beguiled.</p>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 order-lg-1 order-0 margin-md-60">
                  <img src="./assets/img/service3.png" alt="" className="img-fluid" />
                </div>
              </div>
            </div>{/* ends: .col-lg-12 */}
          </div>
        </div>
      </section>
    )
}
export default ContentBlock15;
import React from 'react';
import {NavLink} from 'react-router-dom';
import FooterItem from './footerItem';
const noAction = (e) => {
    e.preventDefault();
}

const Footer5 = () => {
    return (
        <footer className="footer5 footer--bw">
            <div className="footer__big">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget text_widget">
                                <img className="footer_logo" src="./assets/img/logo-white.png" alt="logo" />
                                <p>Nunc placerat mi id nisi interdum they mtolis. Praesient is pharetra justo ught scel
                                    erisque the mattis lhreo quam nterdum mollisy.</p>
                                <NavLink to="/" onClick={noAction}>Read More About <span className="la la-chevron-right"></span></NavLink>
                            </div>{/*<!-- ends: .widget -->*/}
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <FooterItem color="black"/>
                    </div>
                </div>
            </div>
                        
            <div className="footer__small text-center">
                <p>©2019 Tejarat. All rights reserved. Created by <NavLink onClick={noAction} to="/">AazzTech</NavLink></p>
            </div>{/*<!-- ends: .footer__small -->*/}
        </footer>
    )
}
export default Footer5;
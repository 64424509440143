import React from 'react';
const ContentBlockService = ()=> {
    return (
        <section className="p-top-50 p-bottom-110">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 margin-md-60">
                        <div className="m-bottom-30">
                            <div className="divider text-left">
                            <h2>Our Extra-ordinary Services</h2>
                            <p className="mx-auto" />
                            </div>
                        </div>
                        <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutationem consuetudium sed imperdiet.</p>
                        <ul className="bullet--list1 m-top-25">
                            <li className="bullet_list"><strong className="color-dark">Professional -</strong> Delivers solutions that help drive</li>
                            <li className="bullet_list"><strong className="color-dark">Business -</strong> Human capital research analytics</li>
                            <li className="bullet_list"><strong className="color-dark">Services -</strong> Complex problems bringing an approach</li>
                        </ul>
                    </div>{/* ends: .col-lg-6 */}
                    <div className="col-lg-6">
                        <div className="cardify text-box bg-gradient m-top-15">
                            <p>Our focus areas are on global standardization and harmonization of business processes, reorganization of marketing and customer. We help all stakeholders in Postal, Transportation.</p>
                        </div>
                    </div>{/* ends: .col-lg-6 */}
                </div>
            </div>
        </section>
    )
}
export default ContentBlockService;
import $ from "jquery";
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 3,
    rtl: falsy,
    nav: true,
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    navContainerClass: "nav-square nav-square-dark",
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        768:{
            items:2
        },
        1000: {
            items: 3
        }
    },
    navElement: 'button'
};
class Team_wrap extends Component {
    render() {
        return (
          <div className="row align-items-center justify-content-center">
            {
                [
                  {
                      "imgSrc": "./assets/img/Francis2.jpeg",
                      "id": "20",
                      "name": "Francis Boero",
                      "designation": "General Manager",
                      linkedIn: "https://www.linkedin.com/in/fboero/",
                  },
                  {
                      "imgSrc": "./assets/img/Dan2.jpeg",
                      "id": "21",
                      "name": "Dan Dingman",
                      "designation": "Director, Digital Product",
                      linkedIn: "https://www.linkedin.com/in/danieldingman/",
                  },
                  {
                      "imgSrc": "./assets/img/Bob2.jpeg",
                      "id": "22",
                      "name": "Bob Driggers",
                      "designation": "Director, Intelligent Track & Trace",
                      linkedIn: "https://www.linkedin.com/in/bobdriggers/",
                  },
                  // {
                  //     "imgSrc": "./assets/img/Brad2.jpeg",
                  //     "id": "23",
                  //     "name": "Brad Elrod",
                  //     "designation": "Director, Operations & Security",
                  //     linkedIn: "https://www.linkedin.com/in/brad-elrod/",
                  // },
                  {
                      "imgSrc": "./assets/img/Mike2.jpeg",
                      "id": "23",
                      "name": "Mike Huth",
                      "designation": "Director, Brand Integrity",
                      linkedIn: "https://www.linkedin.com/in/mike-huth-608b276/",
                  }
                ].map((value, key) => {
                    const {imgSrc, id, name, designation, linkedIn} = value;
                    return (
                      <div className="col-xs-12 col-md-6 col-lg-4 margin-md-60">
                        <div className="carousel-single" key={id}>
                            <div className="card card-shadow card--team1">
                                <div className="card-body text-center">
                                    <img src={imgSrc} alt="" className="rounded-circle" style={{height:  '200px', width: '200px'}} />
                                    <h6><a href="team-single.html">{name}</a></h6>
                                    <span className="team-designation">{designation}</span>
                                    <ul className="team-social d-flex justify-content-center">
                                        <li><a target="_blank" href={linkedIn}><i className="fab fa-linkedin-in" rel="noopener noreferrer"></i></a></li>
                                    </ul>
                                </div>
                            </div>{/*<!-- End: .card -->*/}
                        </div>
                        </div>
                    )
                })
            }
            </div>
        )
    }
}

export default Team_wrap;

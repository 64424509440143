import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import { ServiceTab } from '../content/element/tab/tab-2';
import CTA from '../content/cta/cta-8';
import Footer from '../layout/footer/footer-5';


const Service = () => {
    return (
        <Fragment>

            <Header pageTitle='Our Services'/>
            <ServiceTab />
            <CTA />
            <Footer />
        </Fragment>
    )
}

export default Service;
import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader-2';
import ContentBlock from '../content/contentBlock/contentBlock-service';
import { IconBoxFour } from '../content/element/iconBox/iconBox';
import Tab4 from '../content/service/insights-impacts';
import Team from '../container/element/carousel/team-carousel-one';
import CTA from '../content/cta/cta-3';
import Subscribe from '../content/subscribe/subscribe8';
import Footer2 from '../layout/footer/footerCurvedLight';
import ContentBlock3 from '../content/contentBlock/contentBlock-3';
import {AccordianTwo} from '../content/element/accordians/accordians';
import { CountUp7 } from '../content/element/counter/count';


const Service = () => {
    return (
        <Fragment>

            <Header />
            <ContentBlock />
            <section className="section-bg">
            <Tab4 />
            </section>
            <section className="p-top-100 p-bottom-55">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="m-bottom-50">
                                <div className="divider text-center">
                                    <h1 className="color-dark">Anthea Consulting Services</h1>
                                    <p className="mx-auto">We bring over 100 years of experience to the Cannabis industry to partner you to sustainably grow your business in a predictable way.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <IconBoxFour />
            </section>
            <section className="section-bg p-top-105 p-bottom-65">
                <div className="m-bottom-40">
                    <div className="divider text-center">
                        <h1>The Team</h1>
                        <p className="mx-auto"></p>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Team />
                        </div>
                    </div>
                </div>
            </section>
            <CountUp7 />
            <Subscribe />
            <Footer2 />
        </Fragment>
    )
}

export default Service;

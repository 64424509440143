import React from 'react';
import {BarChart} from '../../content/element/chart/line-chart';
export const ProjectResult = () => {
    return (
        <section className="content-block content--block8 p-bottom-100 p-top-60">
            <div className="container">
                <div className="row d-flex align-items-end">
                    <div className="col-lg-5 margin-md-60">
                        <h4>Project Result</h4>
                        <p>Investiga tiones demonstr averunt lectres legere me lius quod ii qua legunt saepius. Clarias est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudiu.</p>
                        <div className="mt-4">
                            
                            <ul className="bullet--list1">
                                
                            <li className="bullet_list"><strong className="color-dark">Professional -</strong> Delivers solutions that help drive</li>
                        
                                
                            <li className="bullet_list"><strong className="color-dark">Business -</strong> Human capital research analytics</li>

                                
                            <li className="bullet_list"><strong className="color-dark">Services -</strong> Complex problems bringing an approach</li>

                                
                            <li className="bullet_list"><strong className="color-dark">Strategy -</strong> Works with senior executives to help them</li>

                            </ul>{/*<!-- ends: .bullet--list1 -->*/}

                        </div>
                    </div>{/*<!-- ends: .col-lg-5 -->*/}
                    <div className="col-lg-6 offset-lg-1">
                        <BarChart />                        
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
            </div>
        </section>
    )
}
import React, {Fragment} from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import ProjectContent from '../container/project/project-3';
import {connect} from 'react-redux';



const Index = (props) => {
    const id = props.match.params.id;
    const filter = Object.values(props.project).filter((value) => {
        return value.id === id;
    })

    return (
        <Fragment>

            <Header pageTitle={filter[0].title}/>
                <ProjectContent content={filter[0]} project={props.project} />
            <Footer />
        </Fragment>
    )
}
const mapStateToProps = (state) => {

    return {
        project : state.project
    }
}
export default connect(mapStateToProps)(Index);
import React, {Fragment} from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footerCurvedLight';
import TeamContent from '../container/team/details';
import {connect} from 'react-redux';



const TeamDetails = (props) => {
    const id = props.match.params.id;
    const filter = Object.values(props.team).filter((value) => {
        return value.id === id;
    })

    return (
        <Fragment>

            <Header pageTitle={filter[0].name}/>
                <TeamContent content={filter[0]} team={props.team} />
            <Footer />
        </Fragment>
    )
}
const mapStateToProps = (state) => {

    return {
        team : state.team
    }
}
export default connect(mapStateToProps)(TeamDetails);

import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import Slider from '../slider/shop-slider';

const FullHeightHeader2 = () => {      
    return (
        <Fragment>                             
            <section className="header header--2">
               <TopHeader />
                <Navbar />                
            </section>
            <section className="intro-area-two">
                <Slider />                    
            </section>
        </Fragment>       
                      
    )   
}


export default FullHeightHeader2;




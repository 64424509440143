import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import StarRatingComponent from 'react-star-rating-component';

class Ratings extends Component {
    state = {
        rating: 1
      };

    onStarClick(nextValue, prevValue, name) {
        this.setState({rating: nextValue});
    }

    render() {
        const { rating } = this.state;

      return (
        <Fragment>

            <Header pageTitle="Ratings " />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Font Awesome Rating</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="ratings p-bottom-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rating d-flex justify-content-center">
                                <span>Your Rating: {rating} </span>
                                <div className="br-wrapper br-theme-fontawesome-stars m-left-15">
                                <StarRatingComponent
                                    name="rate1"
                                    starCount={5}
                                    value={rating}
                                    onStarClick={this.onStarClick.bind(this)}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
      )
    }
}
export default Ratings

import React from 'react';
import {NavLink} from 'react-router-dom';
const noAction = e => e.preventDefault();
const Cta4 = () => {
    return (
        <section className="cta-wrapper cta--four bgimage biz_overlay overlay--secondary">
            <div className="bg_image_holder" style={{backgroundImage: 'url(&quot;img/cbg2.jpg&quot;)', opacity: 1}}>
                <img src="./assets/img/cbg2.jpg" alt="img/cbg2.jpg" />
            </div>
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="cta-content text-center">
                            <h1 className="display-3">Improve Your Business With Us</h1>
                        </div>
                    </div>
                    <div className="col-lg-8 offset-lg-2">
                        <div className="cta-content text-center">
                            <p>If you have any questions regarding the purchase of business policy &amp; consulting conditions please call us any time.</p>
                            <span className="phone"><i className="la la-headphones"></i> 800-567 890 576</span>
                            <NavLink to="/demo" onClick={noAction} className="btn btn-primary btn-lg btn-icon icon-left"><i className="la la-envelope"></i> Send Message</NavLink>
                        </div>
                    </div>
                </div>{/*<!-- end: .row -->*/}
            </div>
        </section>
    )
}
export default Cta4;
import React, {Fragment} from 'react';

import Header from '../layout/header/single-product';
import Footer from '../layout/footer/footer-5';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Cart } from '../../Store/action/cartActions';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import StripeCheckout from 'react-stripe-checkout';
import Modal from 'react-responsive-modal';
import { toast, ToastContainer } from 'react-toastify';

const noAction  = e => e.preventDefault();
const style = {
    maxWidth: '400px',
    Width: '100%',
    padding: '15px'
}
class ShopProduct extends React.Component {
    constructor (props) {
        super (props)
        this.state = {
            payment:'',
            pay_mess: false,
            first_name:'',
            last_name:'',
            phone:'',
            email:'',
            country:'',
            present_address:'',
            permanet_address:'',
            city:'',
            state:'',
            zip:'',
            customRadioInline1: '',
            message : false,
            product: this.props.cart.product,
            cart : null,
            total : null,
            cartCount : null,
            open: false
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onToken = (token) => {
        fetch('/save-stripe-token', {
            method: 'POST',
            body: JSON.stringify(token),
        }).then(response => {
            response.json().then(data => {
            alert(`We are in business, ${data.email}`);
            });
        });
    }

    onOpenModal = () => {
    this.setState({ open: true });
    };

    onCloseModal = () => {
    this.setState({ open: false });
    };

    render () {
        const { open } = this.state;

        // address controll
        const Shipping_Address = () => {
            if (this.validator.allValid()) {
                var success = true;
                return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }

        const Shipping_method = () => {
            if (this.state.customRadioInline1 !== '') {
               var success = true;
               return success;
            } else {
                this.setState({
                    message: true
                })
                var success2 = false;
                return success2;
            }
        }

        const payment = () => {
            if (this.state.payment !== '') {
               var success = true;
               return success;
            } else {
                this.setState({
                    pay_mess: true
                })
                var success2 = false;
                return success2;
            }
        }

        const confirm = (e) => {
            e.preventDefault();
        }

        //Paypal API
        const client = {
            sandbox:    'paypal_sandbox_key',
            production: 'paypal_production_key',
        }

        // tab controle panel start
        $(document).ready(function () {
            // tab increment
            $('body').on('click', '.next-item2', (e) => {
                e.preventDefault()

                var content = $(e.currentTarget).closest('.tab-pane').attr('aria-labelledby')
                if(content === "tab6_nav1") {
                    if(Shipping_Address()) {
                        $('.tab-pane').removeClass('active');
                        $('#'+content).closest('.nav-item').next().find('.nav-link').addClass('active');
                        $(e.currentTarget).closest('.tab-pane').next().addClass('active show')
                    }
                } else if(content === "tab6_nav2") {
                    if(Shipping_method()) {
                        $('.tab-pane').removeClass('active');
                        $('#'+content).closest('.nav-item').next().find('.nav-link').addClass('active');
                        $(e.currentTarget).closest('.tab-pane').next().addClass('active show')
                    }
                } else if(content === "tab6_nav3") {
                    if(payment()) {
                        $('.tab-pane').removeClass('active');
                        $('#'+content).closest('.nav-item').next().find('.nav-link').addClass('active');
                        $(e.currentTarget).closest('.tab-pane').next().addClass('active show')
                    }
                }

            });

            // tab decrement
            $('body').on('click', '.prev-item', (e) => {
                e.preventDefault();
                $('.tab-pane').removeClass('active');
                var content = $(e.currentTarget).closest('.tab-pane').attr('aria-labelledby')
               $('#'+content).removeClass('active');
               $(e.currentTarget).closest('.tab-pane').prev().addClass('active show')
            })

        })
        // tab controle panel end

        setInterval(() => {
            var match = JSON.stringify({product: this.state.product});
            var match2 = localStorage.getItem('cart')
            if(match !== match2) {
                if(this._isMounted) {
                    this.setState({
                        product: JSON.parse(match2).product
                    }, () => this.props.addToCart({product: this.state.product}))
                }
            }

        }, 100)

        // cart item controle panel
        var cart_item = [];

        Object.values(this.state.product).map((value, key) => {
            return cart_item.push({ ['item'+key] : 1 })
        })
        // total price start
        var total_price = 0;
        Object.values(this.state.product).map((value, key) => {
            return total_price += parseInt( value[0].price) * parseInt( value[0].quantity);
        });

        // cart item increment
        const plus = (e) => {
            const plus_id  = e.target.getAttribute('plus_id');
            if(parseInt(this.state.product[plus_id][0].quantity) < 5)
            this.setState(prevState => {
                let product = Object.assign({}, prevState.product);
                product[plus_id][0].quantity = parseInt(this.state.product[plus_id][0].quantity)+1
                return { product }
            }, () => this.props.addToCart({product: this.state.product}));

        }
        // cart item increment end

        // cart item decrement
        const minus = (e) => {
            const minus_id  = e.target.getAttribute('minus_id');

            if(parseInt(this.state.product[minus_id][0].quantity) > 1)
            this.setState(prevState => {
                let product = Object.assign({}, prevState.product);
                product[minus_id][0].quantity = parseInt(this.state.product[minus_id][0].quantity)-1
                return { product }
            }, () => this.props.addToCart({product: this.state.product}));
        }

        // cart item decrement end
        const change = e => {
            e.preventDefault()
        }

        //cart item remove
        const remove = (e) => {
            e.preventDefault();
            this.setState({
                cartCount : e.currentTarget.getAttribute('data')
            }, () => {
                const code_item = Object.values(this.state.product).filter((item, key) => {
                    return key !== parseInt(this.state.cartCount);
                });
                this.setState(
                    { product: [...code_item] },
                    () => this.props.addToCart(this.state)
                )
                toast.error("Cart Item deleted!");
            })
        }
        // cart item controle panel end
        const grand_total = this.state.customRadioInline1.split('-').pop() === 'Free'? parseInt(total_price) + parseInt(7) :  parseInt(total_price) + parseInt(7) + parseInt(this.state.customRadioInline1.split('-').pop().split('$').pop())
        return (
            <Fragment>

                <ToastContainer />
                <Header pageTitle="Checkout"/>
                <section className="checkout-wrapper p-top-90 p-bottom-110">
                    <div className="tab tab--6">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className="tab_nav2">
                                        <ul className="nav justify-content-around" id="tab6" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="tab6_nav1" onClick={noAction} href="#tab6_content1" role="tab" aria-controls="tab6_content1" aria-selected="true">1. Billing Details</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="tab6_nav2" onClick={noAction} href="#tab6_content2" role="tab" aria-controls="tab6_content2" aria-selected="false">2. Shipping</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="tab6_nav3" onClick={noAction} href="#tab6_content3" role="tab" aria-controls="tab6_content3" aria-selected="false">3. Payment</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="tab6_nav4" onClick={noAction} href="#tab6_content4" role="tab" aria-controls="tab6_content4" aria-selected="false">4. Order Review</a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tab-content tab--content2" id="tabcontent6">

                                        <div className="tab-pane fade show active" id="tab6_content1" role="tabpanel" aria-labelledby="tab6_nav1">
                                            <form action="#">
                                                <h4>Shipping Address</h4>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="fname">First Name</label>
                                                            <input type="text" name="first_name" value={this.state.first_name} onChange={this.setStateFromInput} className="form-control" id="fname" />
                                                            <div className="text-danger">{this.validator.message('first_name', this.state.first_name, 'required|alpha')}</div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-md-6 -->*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="lname">Last Name</label>
                                                            <input type="text" name="last_name"
                                                            value={this.state.last_name} onChange={this.setStateFromInput} className="form-control" id="lname" />
                                                            <div className="text-danger">{this.validator.message('last_name', this.state.last_name, 'required|alpha')}</div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-md-6 -->*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="eaddress">Email Address</label>
                                                            <input type="email" name="email"
                                                            value={this.state.email} onChange={this.setStateFromInput}className="form-control" id="eaddress" />
                                                            <div className="text-danger">{this.validator.message('email', this.state.email, 'required|email')}</div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-md-6 -->*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="kochu">Phone Number</label>
                                                            <input type="text" name="phone"
                                                            value={this.state.phone} onChange={this.setStateFromInput}className="form-control" id="kochu" />
                                                            <div className="text-danger">{this.validator.message('phone', this.state.phone, 'required|phone')}</div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-md-6 -->*/}

                                                    {/*<!-- ends: .col-md-6 -->*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="countr">Country</label>
                                                            <div className="form-group">
                                                                <div className="select-basic">
                                                                    <select name="country" value={this.state.country} onChange={this.setStateFromInput} className="form-control" id="countr">
                                                                        <option>Select Country</option>
                                                                        <option value="bangladesh">Bangladesh</option>
                                                                        <option value="india">India</option>
                                                                        <option value="australia">Australia</option>
                                                                        <option value="england">England</option>
                                                                    </select>
                                                                    <div className="text-danger">{this.validator.message('country', this.state.country, 'required|alpha')}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-md-6 -->*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="form-group">
                                                                <label htmlFor="city">City</label>
                                                                <div className="form-group">
                                                                    <div className="select-basic">
                                                                        <select name="city"
                                                                        value={this.state.city} onChange={this.setStateFromInput} className="form-control" id="city">
                                                                            <option>Select City</option>
                                                                            <option value="dhaka">Dhaka</option>
                                                                            <option value="mumbia">Mumbai</option>
                                                                            <option value="sydney">Sydney</option>
                                                                            <option value="london">London</option>
                                                                        </select>
                                                                        <div className="text-danger">{this.validator.message('city', this.state.city, 'required|alpha')}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-md-6 -->*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="zip">ZIP Code</label>
                                                            <input type="text" name="zip"
                                                            value={this.state.zip} onChange={this.setStateFromInput}className="form-control" id="zip" />
                                                            <div className="text-danger">{this.validator.message('zip', this.state.zip, 'required|alpha_num')}</div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-md-6 -->*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="add1">Shipping Address</label>
                                                            <input type="text" name="present_address"
                                                            value={this.state.present_address} onChange={this.setStateFromInput}className="form-control" id="add1" />
                                                            <div className="text-danger">{this.validator.message('present_address', this.state.present_address, 'required|string')}</div>
                                                        </div>
                                                    </div>{/*<!-- ends: .col-md-6 -->*/}
                                                </div>

                                                <div className="action-btns d-flex m-top-60">
                                                    <NavLink to="/shopping-cart" className="btn btn-outline-secondary btn-icon icon-left"><i className="la la-angle-double-left"></i> Back to cart</NavLink>
                                                    <NavLink to="/at_demo" className="next-item2 btn btn-primary ml-auto btn-icon icon-right">Continue <i className="la la-angle-double-right"></i></NavLink>
                                                </div>
                                            </form>
                                        </div>{/*<!-- end ./tab-pane -->*/}

                                        <div className="tab-pane fade" id="tab6_content2" role="tabpanel" aria-labelledby="tab6_nav2">
                                            <h4>Choose Shipping Method</h4>
                                            <div className="table-responsive">
                                                <form>
                                                <table className="table table-two">
                                                    <thead className="table-gray">
                                                        <tr>
                                                            <th scope="col">Shipping Method</th>
                                                            <th scope="col">Delivery Time</th>
                                                            <th scope="col">Handling Fee</th>
                                                        </tr>
                                                    </thead>{/*<!-- ends: thead -->*/}
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" onChange={this.setStateFromInput} id="select-one" value="Fedex Courier - $20" name="customRadioInline1" className="custom-control-input" />
                                                                    <label className="custom-control-label" htmlFor="select-one"><span>Fedex Courier</span></label>
                                                                </div>{/*<!-- End: .custom-control -->*/}
                                                            </th>
                                                            <td>2 - 4 days</td>
                                                            <td>$20</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" onChange={this.setStateFromInput} id="select-two" value="International Shipping - $25" name="customRadioInline1" className="custom-control-input" />
                                                                    <label className="custom-control-label" htmlFor="select-two"><span>International Shipping</span></label>
                                                                </div>{/*<!-- End: .custom-control -->*/}
                                                            </th>
                                                            <td>1 - 3 days</td>
                                                            <td>$25</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" onChange={this.setStateFromInput} id="select-three" value="Local Pickup from Store - Free" name="customRadioInline1" className="custom-control-input" />
                                                                    <label className="custom-control-label" htmlFor="select-three"><span>Local Pickup from Store</span></label>
                                                                </div>{/*<!-- End: .custom-control -->*/}
                                                            </th>
                                                            <td>1 -2 days</td>
                                                            <td>Free</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" onChange={this.setStateFromInput} id="select-four" value="Local Shipping - $10" name="customRadioInline1" className="custom-control-input" />
                                                                    <label className="custom-control-label" htmlFor="select-four"><span>Local Shipping</span></label>
                                                                </div>{/*<!-- End: .custom-control -->*/}
                                                            </th>
                                                            <td>Up to One Week</td>
                                                            <td>$10</td>
                                                        </tr>
                                                        <tr><td colSpan="4"><div className="text-danger">{this.state.message ? "Please Choose Shipping Method" : this.state.customRadioInline1}</div></td></tr>
                                                    </tbody>{/*<!-- ends: tbody -->*/}
                                                </table>
                                                </form>
                                            </div>{/*<!-- ends: .table-responsive -->*/}
                                            <div className="action-btns d-flex m-top-60">
                                                <NavLink to="/at_demo" className="prev-item btn btn-outline-secondary btn-icon icon-left"><i className="la la-angle-double-left"></i> Back</NavLink>
                                                <NavLink to="/at_demo" className="next-item2 btn btn-primary ml-auto btn-icon icon-right">Continue <i className="la la-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>{/*<!-- end ./tab-pane -->*/}

                                        <div className="tab-pane fade" id="tab6_content3" role="tabpanel" aria-labelledby="tab6_nav3">
                                            <h4>Choose Payment Method</h4>
                                            <div className="method-1">
                                                <div className="header">
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" id="customRadio3" name="payment" value="Stripe" onChange={this.setStateFromInput} className="custom-control-input" />
                                                        <label className="custom-control-label" htmlFor="customRadio3">Pay With Credit Card</label>
                                                    </div>
                                                </div>
                                            </div>{/*<!-- ends: .method-1 -->*/}
                                            <div className="method-1">
                                                <div className="header">
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" id="customRadio4" name="payment" onChange={this.setStateFromInput} value="payPal" className="custom-control-input" />
                                                        <label className="custom-control-label" htmlFor="customRadio4">Pay With Paypal</label>
                                                    </div>
                                                </div>
                                            </div>{/*<!-- ends: .method-1 -->*/}
                                            <div className="method-2">
                                                <div className="header">
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" id="customRadio5" name="payment" onChange={this.setStateFromInput} value="Bank" className="custom-control-input" />
                                                        <label className="custom-control-label" htmlFor="customRadio5">Direct Bank Transfer</label>
                                                    </div>
                                                </div>
                                            </div>{/*<!-- ends: .method-2 -->*/}
                                            <div className="method-2">
                                                <div className="header">
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" id="customRadio6" name="payment" onChange={this.setStateFromInput} value="Cash" className="custom-control-input" />
                                                        <label className="custom-control-label" htmlFor="customRadio6">Cash On Delivery</label>
                                                    </div>
                                                </div>
                                            </div>{/*<!-- ends: .method-2 -->*/}
                                            <div className="text-danger">{this.state.pay_mess ? "Please Choose Shipping Method" : this.state.payment}</div>
                                            <div className="action-btns d-flex m-top-60">
                                                <NavLink to="/at_demo" className="prev-item btn btn-outline-secondary btn-icon icon-left"><i className="la la-angle-double-left"></i> Back</NavLink>
                                                <NavLink to="/at_demo" className="next-item2 btn btn-primary ml-auto btn-icon icon-right">Continue <i className="la la-angle-double-right"></i></NavLink>
                                            </div>
                                        </div>{/*<!-- end ./tab-pane -->*/}
                                        <div className="tab-pane fade" id="tab6_content4" role="tabpanel" aria-labelledby="tab6_nav4">
                                            <h4>Review Your Order</h4>
                                            <div className="cart-table cart-table--2 table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"><span>Product Name</span></th>
                                                            <th scope="col"><span>Quantity</span></th>
                                                            <th scope="col" colSpan="3"><span>Subtotal</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        Object.values(this.state.product).map((value, key) => {
                                                            const {imgSrc, title, price, id, quantity} = value[0]

                                                            return (
                                                                this.state.product ?
                                                                <tr key={key+1} className="cart-single ">
                                                                    <th scope="row">
                                                                        <div className="name">
                                                                            <img src={imgSrc} alt="" />
                                                                            <NavLink to={"/single-product"+id}>{title}</NavLink>
                                                                        </div>
                                                                    </th>
                                                                    <td >
                                                                        <div className="quantity text-right">
                                                                            <div className="total-item">
                                                                                <span className="minus" onClick={minus} minus_id={key} >-</span>
                                                                                <input type="text" onChange={change} value={ quantity } />
                                                                                <span className="plus" onClick={plus} plus_id={key}>+</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td colSpan="2">
                                                                        <div className="subtotal">
                                                                            <span>{'$'+ quantity * price}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="remove text-center item_remove" ><NavLink to="/at_demo" onClick={remove} data={key}><i className="la la-close"></i></NavLink></div>
                                                                    </td>
                                                                </tr>

                                                                : <tr><td colSpan="4"><center><h1>Data not found!</h1></center></td></tr>
                                                            )
                                                        })
                                                    }

                                                        <tr className="cart-bottom">
                                                            <td colSpan="1">
                                                                <div className="total-amount">
                                                                    Shipping: <span>{this.state.customRadioInline1.split('-').pop()}</span>
                                                                </div>
                                                            </td>
                                                            <td colSpan="3">
                                                                <div className="total-amount">
                                                                    Tax: <span>$7.00</span>
                                                                </div>
                                                            </td>
                                                            <td colSpan="1">
                                                                <div className="total-amount text-right">
                                                                    Total: <span>$ { grand_total }</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>{/*<!-- ends: .table -->*/}
                                            </div>{/*<!-- ends: .table-responsive -->*/}
                                            <div className="m-top-40">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="cardify order-info">
                                                            <h6>Quick Info</h6>
                                                            <ul>
                                                                <li><span>Client:</span> <span>{this.state.first_name} {this.state.last_name}</span></li>
                                                                <li><span>Shipping Address:</span> <span>{this.state.present_address}</span></li>
                                                                <li><span>Shipping Method:</span> <span>{this.state.customRadioInline1}</span></li>
                                                                <li><span>Payment Method</span> <span>{this.state.payment}</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>{/*<!-- ends: .cart-table -->*/}

                                            <div className="action-btns d-flex m-top-60">

                                                <NavLink to="/at_demo" className="prev-item btn btn-outline-secondary btn-icon icon-left"><i className="la la-angle-double-left"></i> Back</NavLink>
                                            {
                                                this.state.payment === 'payPal' ?
                                                <NavLink to="/at_demo" style={{position: 'relative'}} onClick={confirm} className="btn btn-primary ml-auto">
                                                Confirm Order
                                                <div style={{position: 'absolute', right: 0, top: 0, opacity: 0.01}}>
                                                    <PaypalExpressBtn
                                                    client={client}
                                                    currency={'USD'}
                                                    style={{height: 50}}
                                                    total={ grand_total } />
                                                </div> </NavLink> :
                                                this.state.payment === 'Stripe' ?
                                                <NavLink to="/at_demo" style={{position: 'relative'}} onClick={confirm} className="btn btn-primary ml-auto">
                                                Confirm Order

                                                {/* Stripe Config */}
                                                <div style={{position: 'absolute', right: 0, visibility: 'hidden', top: 0, opacity: 0}}>
                                                    <StripeCheckout
                                                        token={this.onToken}
                                                        stripeKey="pk_test_1llLXi1jGBLQFKoFgSlBJYiv" //Change this API key
                                                        amount={ grand_total * 100 } // cents
                                                        currency="USD"
                                                    />
                                                </div>

                                                </NavLink>
                                                :
                                                this.state.payment === 'Bank' ?
                                                <button onClick={this.onOpenModal} className="confirm btn btn-primary ml-auto">
                                                Confirm Order</button>
                                                :
                                                <NavLink to="/checkout-confirm" className="confirm btn btn-primary ml-auto">
                                                Confirm Order</NavLink>
                                            }

                                            </div>
                                        </div>{/*<!-- end ./tab-pane -->*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<!--end ./container -->*/}
                    </div>
                    {/*<!--end ./tab -->*/}
                </section>{/*<!-- ends: .checkout-wrapper -->*/}

                <Modal open={open} onClose={this.onCloseModal} center>
                    <article style={style}>
                        <p>
                            Please make your payment directly to our bank account.
                            Our bank account information is given below.
                        </p>
                        <h4>Account details:</h4>
                        <p>
                            Account Name: e.g. Devid Warnar <br />
                            Account Number:  e.g. 12345678 <br />
                            Bank Name: e.g. Bank of UK
                        </p>
                    </article>
                </Modal>

                <Footer />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        product : state.product,
        cart : state.cart
    }
}

const mapDispatchToProp = dispatch => {
    return {
        addToCart : (prod) => dispatch(Cart(prod))
    }
}
export default connect(mapStateToProps, mapDispatchToProp)(ShopProduct);
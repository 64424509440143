import React, { Component } from 'react'
import Content from '../../content/team/team-4';
import {connect} from 'react-redux';

class Team_four extends Component {
    render() {
        const {team} = this.props;
        return (
            <section className="p-top-100 p-bottom-110 section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="section_title text-center">
                                <h1>Financial Experts</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {
                            Object.values(team).slice(6, 10).map((value, key) => {
                                const {imgSrc, id, name, speach, designation} = value;
                                return (                                    
                                    <Content img={imgSrc}
                                        key={id}
                                        name={name}
                                        designation={designation}
                                        speach={speach}
                                        id={id}
                                        />
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        team : state.team
    }
}
export default connect(mapStateToProps)(Team_four);
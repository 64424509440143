import React from 'react';
import { BarChart } from '../element/chart/line-chart';
const Report = ()=> {
    return (
        <section className="content-block content--block8 p-top-110 p-bottom-110">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-5 margin-md-60">
                        <div className="divider text-left">
                            <span className="subtext">Anthea's Impact</span>
                            <h3>Laying the framework for growth</h3>
                            <p>We help you strengthen the foundation you stand on as a cannabis producer, setting your business up for consistent long-term growth. We work with you to secure your supply chain and protect your brand integrity through a variety of services and technologies.</p>
                        </div>
                        <div className="m-top-30">
                            <ul className="bullet--list1">
                                <li className="bullet_list"><strong className="color-dark">Trumarks<sup>TM</sup> -</strong> Cloud based software service that provides direct producer to consumer communication and product authentication.</li>
                                <li className="bullet_list"><strong className="color-dark">Anthea Consulting -</strong> Solution focused consulting around recall processes, supply chain integrity, and food and process safety.</li>
                                <li className="bullet_list"><strong className="color-dark">NanoMarkers<sup>TM</sup> by Anthea -</strong> Mico-taggant technologies that can be used to enhance product authentication and prevent counterfeit.</li>
                            </ul>{/*<!-- ends: .bullet--list1 -->*/}
                        </div>
                    </div>{/*<!-- ends: .col-lg-5 -->*/}

                    <div className="col-lg-6 offset-lg-1">
                        <img src="./assets/img/undraw_Growing_qwt2.png"  />
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
            </div>
        </section>
    )
}
export default Report;

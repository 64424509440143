import React, {Fragment} from 'react';

import Header from '../layout/header/single-product';
import Footer from '../layout/footer/footer-5';
import { NavLink } from 'react-router-dom';

class ShopProduct extends React.Component {
    render() {
        const getValue = e => e.target.value;
        return (
            <Fragment>

                <Header pageTitle="Checkout"/>
                <section className="checkout-confirm p-top-150 p-bottom-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 text-center">
                                <h2>Thank you for your order!</h2>
                                <p>Your order has been placed and will be processed as soon as possible. You will be receiving an email shortly with confirmation of your order.</p>
                                <form action="#">
                                    <div className="custom-control custom-checkbox checkbox-secondary">
                                        <input type="checkbox" onChange={getValue} className="custom-control-input" id="customCheck111" defaultChecked />
                                        <label className="custom-control-label" htmlFor="customCheck111">Notify me when order is delivered</label>
                                    </div>
                                </form>
                                <NavLink to="/shop-products"  className="btn btn-primary">Back to shopping</NavLink>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        )
    }
}

export default ShopProduct
import React from 'react';

export const ProjectChalange = () => {
    return (
        <section className="project-desc p-top-65 p-bottom-65 border-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 margin-md-60">
                        <h4>Project Challenges</h4>
                        <p>Investiga tiones demonstr averunt lectores legere me lius quod qua legunt saepius. Claritas est etiam pro cessus dynamicus.</p>
                        <div className="m-top-25">
                            <ul className="bullet--list3">
                                
                                <li className="bullet_list bullet_list--three d-flex flex-column"><strong className="color-dark m-bottom-10">Improve sales and operations planning</strong> <p>Ketiam pro cessus dynamicus, qui sequitur mutationem consu etudium. Sed imperdiet, ante in porta ultrices.</p></li>                         
                                                        
                                <li className="bullet_list bullet_list--three d-flex flex-column"><strong className="color-dark m-bottom-10">Improve sales and operations planning</strong> <p>Ketiam pro cessus dynamicus, qui sequitur mutationem consu etudium. Sed imperdiet, ante in porta ultrices.</p></li>

                            </ul>{/*<!-- ends: .bullet--list3 -->*/}
                        </div>
                    </div>{/*<!-- ends: .col-md-6 -->*/}
                    <div className="col-md-6">
                        <h4>Project Solution</h4>
                        <p>Investiga tiones demonstr averunt lectores legere me lius quod qua legunt saepius. Claritas est etiam pro cessus.</p>
                        <div className="m-top-25">
                            <ul className="bullet--list3">
                                
                                <li className="bullet_list bullet_list--three d-flex flex-column"><strong className="color-dark m-bottom-10">Improve sales and operations planning</strong> <p>Ketiam pro cessus dynamicus, qui sequitur mutationem consu etudium. Sed imperdiet, ante in porta ultrices.</p></li>
                                <li className="bullet_list bullet_list--three d-flex flex-column"><strong className="color-dark m-bottom-10">Improve sales and operations planning</strong> <p>Ketiam pro cessus dynamicus, qui sequitur mutationem consu etudium. Sed imperdiet, ante in porta ultrices.</p></li>

                            </ul>{/*<!-- ends: .bullet--list3 -->*/}
                        </div>
                    </div>{/*<!-- ends: .col-md-6 -->*/}
                </div>
            </div>
        </section>
    )
}
import $ from "jquery";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}

const options = {
    items: 4,
    dots: true,
    rtl: falsy,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:4
        }
    }
};
class AddressCarousel extends Component {
    render() {

        return (
            <OwlCarousel options={options} className="addresses_carousel owl-carousel" >
                <div className="addr">
                    <div className="adress">
                        <img src="./assets/img/ukf.png" alt="img/ukf.png" />
                        <p className="nam">United Kingdom</p>
                        <p>49 Featherstone Street London, United Kingdom +88 468 888 800</p>
                    </div>
                </div>
                <div className="addr">
                    <div className="adress">
                        <img src="./assets/img/gerf.png" alt="img/gerf.png" />
                        <p className="nam">Germany</p>
                        <p>32 Neuwe Doelenstraat Amsterdam, Germany +44 647 888 400</p>
                    </div>{/*<!-- end: .adress -->*/}
                </div>
                <div className="addr">
                    <div className="adress">
                        <img src="./assets/img/engf.png" alt="img/engf.png" />
                        <p className="nam">Australia</p>
                        <p>96 South Park Avenue Melbourne, Australia +44 647 888 400</p>
                    </div>{/*<!-- end: .adress -->*/}
                </div>
                <div className="addr">
                    <div className="adress">
                        <img src="./assets/img/usaf.png" alt="img/usaf.png" />
                        <p className="nam">USA</p>
                        <p>49 Featherstone Street London, United Kingdom +88 468 888 800</p>
                    </div>{/*<!-- end: .adress -->*/}
                </div>
                <div className="addr">
                    <div className="adress">
                        <img src="./assets/img/ukf.png" alt="img/ukf.png" />
                        <p className="nam">United Kingdom</p>
                        <p>49 Featherstone Street London, United Kingdom +88 468 888 800</p>
                    </div>{/*<!-- end: .adress -->*/}
                </div>
                <div className="addr">
                    <div className="adress">
                        <img src="./assets/img/gerf.png" alt="img/gerf.png" />
                        <p className="nam">Germany</p>
                        <p>32 Neuwe Doelenstraat Amsterdam, Germany +44 647 888 400</p>
                    </div>{/*<!-- end: .adress -->*/}
                </div>
                <div className="addr">
                    <div className="adress">
                        <img src="./assets/img/engf.png" alt="img/engf.png" />
                        <p className="nam">Australia</p>
                        <p>96 South Park Avenue Melbourne, Australia +44 647 888 400</p>
                    </div>{/*<!-- end: .adress -->*/}
                </div>
                <div className="addr">
                    <div className="adress">
                        <img src="./assets/img/usaf.png" alt="img/usaf.png" />
                        <p className="nam">USA</p>
                        <p>49 Featherstone Street London, United Kingdom +88 468 888 800</p>
                    </div>{/*<!-- end: .adress -->*/}
                </div>
            </OwlCarousel>
        )
    }
}

export default AddressCarousel;
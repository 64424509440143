
import React, {Fragment} from 'react';
import Navbar from '../navbar/transNav';
import Slider from '../slider/revollutionSlider';

const FullHeightHeader1 = () => {      
    return (
        <Fragment>
            <section className="intro-area">
                <div className="">
                    <div className="intro-area-11">
                        <Navbar />
                        <Slider />                        
                    </div>
                    {/* <!-- ends: .intro-area-11 --> */}
                </div>
            </section>
            {/* <!-- ends: .intro-area --> */}
        </Fragment>       
                      
    )   
}


export default FullHeightHeader1;




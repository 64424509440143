import React from 'react';

const TopHeader = () => {
    return (
        <div className="top_bar top--bar2 d-flex align-items-center bg-primary">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex topbar_content justify-content-between">
                            <div className="top_bar--info order-0 d-none d-lg-block align-self-center" style={{marginLeft: 'auto'}}>
                                <ul>
                                    <li><span className="la la-envelope"></span>
                                        <p>info@antheapro.com</p>
                                    </li>
                                    <li><span className="la la-phone"></span>
                                        <p>(215)-534-4054</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeader;

import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import SocondaryNav from '../layout/navbar/aboutNavItem';
import { TimelineOne } from '../content/element/timeline/timeline';


import Footer from '../layout/footer/footer-5';
const About = () => {
    return (
        <Fragment>

            <Header pageTitle='Our History' />
            <SocondaryNav />
            <section className="p-top-105 p-bottom-90">
                <div className="m-bottom-65">
                    <div className="divider text-center">
                        <h1>History Overview</h1>
                        <p className="mx-auto" />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <p>pInvestiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes demons trave huerunt lectores legere liusry. Investiga tiones demonstr averunt
                            lectres legere me liullys quod kedqua legunt saepius.<br /><br />
                            Clarias est Investig ationes demonslectores legere liusry squitur mutaety tionem consum etudium. Investig ationes demons trave huerunt lectores legere liusry. Investiga tiones demonstr.<br /><br />
                            Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro cessus dynamicus squitur mutaety tionem consum etudium.</p>
                        </div>
                        <div className="col-lg-6">
                            <p>pInvestiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes demons trave huerunt lectores legere liusry. Investiga tiones demonstr averunt
                            lectres legere me liullys quod kedqua legunt saepius.<br /><br />
                            Clarias est Investig ationes demonslectores legere liusry squitur mutaety tionem consum etudium. Investig ationes demons trave huerunt lectores legere liusry. Investiga tiones demonstr.<br /><br />
                            Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro cessus dynamicus squitur mutaety tionem consum etudium.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p-top-105 p-bottom-110 section-bg">
                <div className="m-bottom-65">
                    <div className="divider text-center">
                        <h1>Company History</h1>
                        <p className="mx-auto" />
                    </div>
                </div>
                <TimelineOne />
            </section>
            <Footer />
        </Fragment>
    )
}

export default About;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import Cta1 from '../../content/cta/cta-1';
import Cta2 from '../../content/cta/cta-2';
import Cta3 from '../../content/cta/cta-3';
import Cta4 from '../../content/cta/cta-4';
import CtaSm from '../../content/cta/cta-sm-2';
import Cta6 from '../../content/cta/cta-6';
import Cta8 from '../../content/cta/cta-8';
import Cta9 from '../../content/cta/cta-1';


const Blockquotes = () => {
    return (
        <Fragment>

            <Header pageTitle="Call To action" />

            {/* Call To Action - 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Cta1 />
            {/* Call To Action - 1 */}

            {/* Call To Action - 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Cta2 />
            {/* Call To Action - 2 */}

            {/* Call To Action - 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Cta3 />
            {/* Call To Action - 3 */}

            {/* Call To Action - 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Cta4 />
            {/* Call To Action - 4 */}

            {/* Call To Action - 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CtaSm />
            {/* Call To Action - 5 */}

            {/* Call To Action - 6 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Cta6 />
            {/* Call To Action - 6 */}

            {/* Call To Action - 7 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CtaSm />
            {/* Call To Action - 7 */}

            {/* Call To Action - 8 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 8</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Cta8 />
            {/* Call To Action - 8 */}

            {/* Call To Action - 9 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Call To Action - 9</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Cta9 />
            {/* Call To Action - 9 */}
            <Footer />
        </Fragment>
    )
}

export default Blockquotes;
import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { Tab2, Tab3, Tab4 } from '../../content/element/tab/tab-2';


class Tabs extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Tabs" />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Tab - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <Tab2 padding="p-0" />
            </div>
            {/* tab - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Tab - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <Tab3 />
            </div>
            {/* tab - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Tab - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container m-bottom-110">
                <Tab4 />
            </div>
            {/* tab - 3 */}
            <Footer />
        </Fragment>
      )
    }
}
export default Tabs

import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import Card2 from '../../container/element/card/card-2';
import Card3 from '../../container/element/card/card-3';
import Card9 from '../../container/element/card/card-9';
import Card11 from '../../container/element/card/card-11';
import CardStyle from '../../container/element/card/card-style-10';
import CardGrid1 from '../../container/element/card/card-grid-1';
import CardGrid2 from '../../container/element/card/card-grid-2';
import CardGrid3 from '../../container/element/card/card-grid-3';
import Team1 from '../../container/element/card/team-card-2';
import CardProduct from '../../container/element/card/card-product';


const Blockquotes = () => {
    return (
        <Fragment>

            <Header pageTitle="Cards" />

            {/* Card Style - 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <Card2 />
            </section>
            {/* Card Style - 1 */}

             {/* Card Style - 2 */}
             <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <div className="container">
                    <Card3 />
                </div>
            </section>
            {/* Card Style - 2 */}

             {/* Card Style - 3 */}
             <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <Card9 />
            </section>
            {/* Card Style - 3 */}

            {/* Card Style - 4 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <Card11 padding="p-top-30 p-bottom-0" />
            </section>
            {/* Card Style - 4 */}

            {/* Card Style - 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <div className="container">
                    <div className="row">
                        <CardStyle />
                    </div>
                </div>
            </section>
            {/* Card Style - 5 */}

            {/* Card Style - 6 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <div className="container">
                    <div className="row">
                        <CardGrid1 />
                    </div>
                </div>
            </section>
            {/* Card Style - 6 */}

            {/* Card Style - 7 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <div className="container">
                    <div className="row">
                        <CardGrid2 />
                    </div>
                </div>
            </section>
            {/* Card Style - 7 */}

            {/* Card Style - 8 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 8</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <div className="container">
                    <div className="row">
                        <CardGrid3 />
                    </div>
                </div>
            </section>
            {/* Card Style - 8 */}

            {/* Card Style - 9 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 9</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <div className="container">
                    <Team1 />
                </div>
            </section>
            {/* Card Style - 9 */}

            {/* Card Style - 10 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Card Style - 10</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="card-styles">
                <div className="container p-bottom-90">
                    <CardProduct />
                </div>
            </section>
            {/* Card Style - 10 */}



            <Footer />
        </Fragment>
    )
}

export default Blockquotes;
import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Card from '../container/element/card/card-team-4';

const teamList = () => {
    return (
        <Fragment>

            <Header pageTitle='Global Management Team'/>
            <section className="section-padding2 section-bg">
                <div className="card-style team--card4">
                    <div className="container">
                        <Card />
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default teamList;
import React from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';

const data = {
    labels: ["2013", "2014", "2015", "2016", "2017", "2018"],
    datasets: [{
        label: "Business",
        backgroundColor: 'rgba(65,58,164,0.1)',
        borderColor: 'rgb(65,58,164)',
        pointBackgroundColor: '#fff',
        data: [18, 51, 30, 18, 24, 50],
        borderWidth: 2
    },{
        label: "Others",
        backgroundColor: 'rgba(6,181,144,0.1)',
        borderColor: 'rgb(6,181,144)',
        pointBackgroundColor: '#fff',
        data: [6, 25, 13, 8, 15, 7],
        borderWidth: 2
    }]
};

var pie_data  =  {
    datasets: [{
        data: [40,120,120],
        backgroundColor: [
            '#ff9f40',
            '#36a2eb',
            '#ff6384'
        ]
    }],
    labels: [
        "Google",
        "Website",
        "Other"
    ]
}

export const LineChart = () => {
    return (
        <div className="line-chart_wrap cardify border">
            <h6>Analytical Report</h6>
                <Line data={data} />                
            <div className="line-legend legend"></div>
        </div>            
    )
}

export const RTLLineChart = () => {
    return (
        <div className="line-chart_wrap cardify border">
            <h6>تقرير تحليلي</h6>
                <Line data={data} />                
            <div className="line-legend legend"></div>
        </div>            
    )
}

const bar_data = {
    labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul'],
    datasets: [
        {
            label: "Business",
            data:[40,60,34,30,32,43,62],
            backgroundColor: '#2196f3'
        },{
            label: "Others",
            data:[30,20,60,40,65,15,40],
            backgroundColor: '#2196f3'
        }
    ],
    options: {
        scales:{
            yAxes: [{
                ticks:{
                    beginAtZero: true,
                    suggestedMax: 60
                }
            }],
            xAxes: [{
                barThickness: 18
            }]
        },
        legend:{
            display: false
        }
    }
}

export const BarChart = () => {
    return (
        <div className="bar-chart_wrap cardify"><div style={{position: 'absolute', inset: '0px', overflow: 'hidden', pointerEvents: 'none', visibility: 'hidden', zIndex: -1}} className="chartjs-size-monitor" ><div className="chartjs-size-monitor-expand" style={{position:'absolute',left:0,top:0,right:0,bottom:0,overflow:'hidden',pointerEvents:'none',visibility:'hidden',zIndex:-1}}><div style={{position:'absolute',width:'1000000px',height:'1000000px',left:0,top:0}}></div></div><div className="chartjs-size-monitor-shrink" style={{position:'absolute',left:0,top:0,right:0,bottom:0,overflow:'hidden',pointerEvents:'none',visibility:'hidden',zIndex:-1}}><div style={{position:'absolute',width:'200%',height:'200%',left:0, top:0}}></div></div></div>
            <Bar data={bar_data} />
            <div className="bar-legend legend"></div>
        </div>        
    )
}

export const PieChart = () => {
    return (        
        <div className="pie-chart_wrap">
            <Pie data={pie_data} />
            <div className="legend pie-legend legend-list"></div>
        </div>        
    )
}

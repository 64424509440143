import React from 'react';
import { CountUp } from '../../content/element/counter/count';
import BgHalf from '../../container/clients/bg-half-secondary';
const ContentBlock14 = ()=> {
    return (
        <section>            
            <div className="content-block content-block--14">
                <CountUp /> 
                <BgHalf />
            </div>{/*<!-- ends: .content-block -->*/}
        </section>
    )
}
export default ContentBlock14;
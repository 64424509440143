import React from 'react';
import { Tab4 } from '../element/tab/tab-2';
const ServiceIcon4 = () => {
    return (
        <section className="p-top-100 p-bottom-110">
            <div className="m-bottom-50 p-left-15 p-right-15">
                <div className="divider text-center">
                    <h1>Security and Risk solutions for the Cannabis Industry</h1>
                    <p className="mx-auto"></p>
                </div>
            </div>
            <Tab4 />
        </section>
    )
}

export default ServiceIcon4;

import React, { Fragment } from 'react';

import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import { Process2 } from '../content/element/process/process';


const Testimonial = () => {
    return (
        <Fragment>

            <Header pageTitle='Working Process'/>
            <section className="p-top-100 p-bottom-35 section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="m-bottom-65">
                                <div className="divider text-center">
                                    <h1>Our Way of Working</h1>
                                    <p className="mx-auto">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius Clarias estre etiam pro cessus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Process2 />
            </section>
            <Footer />
        </Fragment>
    )
}

export default Testimonial;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import $ from 'jquery';

import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/slider.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/slider';
const RangeSlider = (props) => {
    $(document).ready(() => {
        //Range slider light
        $("#slider-range1").slider({
                range: true,
                min: 0,
                max: 500,
                values: [0, 500],
                slide: function (event, ui) {
                    $("#amount1").val("$" + ui.values[0] + " - $" + ui.values[1]);
                }
            });

        $("#amount1").val("$" + $("#slider-range1").slider("values", 0) +
                " - $" + $("#slider-range1").slider("values", 1));
    });
      return (
        <Fragment>

            <Header pageTitle="Range Slider" />
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Range Slider Light</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="range-slider-wrapper p-bottom-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="range-slider text-center">
                                    <div id="slider-range1" className="slider-range m-auto" />
                                    <p>
                                        <button type="submit" className="btn filter-btn btn--rounded btn-secondary">Filter</button>
                                        <input type="text" id="amount1" className="amounts" readOnly />
                                    </p>
                                </div>{/* end: .range-slider */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </Fragment>
      );
}
export default RangeSlider
import React, {Fragment} from 'react';
import Header from '../layout/header/shop-header';
import Footer from '../layout/footer/footer-5';
import Fetures from '../content/fetures/shop-fetures';
import Card from '../container/element/card/card-product';
import TestimonialSix from '../container/testimonial/testimonial-6';
import Subscriber from '../content/subscribe/subscribe-4';

const Shop = () => {
    return (
        <Fragment>

            <Header />
            <Fetures />
            <section className="shop-products p-top-100 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="m-bottom-50">
                                <div className="divider text-center">
                                    <h1>New Products</h1>
                                    <p className="mx-auto"></p>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .col-lg-12 -->*/}
                        <Card />
                    </div>
                </div>
            </section>
            <TestimonialSix />
            <Subscriber />

            <Footer />
        </Fragment>
    )
}

export default Shop;
import React, {Fragment} from 'react';
import $ from 'jquery';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { Cart } from '../../../Store/action/cartActions';
import { toast, ToastContainer } from 'react-toastify';

$('body').on('click', '.search_trigger', function (e) {
    $(this).toggleClass('la-search la-close');
    $(this).parent('.search_module').children('.search_area').toggleClass('active');
});
const noAction = e => e.preventDefault();
class NavbarItem extends React.Component {
    _isMounted = false;
    state = {
        product: this.props.cart.product
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        setInterval(() => {
            var match = JSON.stringify(this.state);
            var match2 = localStorage.getItem('cart');
            if(match2 !== match) {
                console.log('ok');
                if(this._isMounted) {
                    this.setState({
                        product: JSON.parse(match2).product
                    }, () => this.props.addToCart(this.state))
                }
            }
        }, 100)

        //cart item viwe on notification popup
        var total_price = 0;
        Object.values(this.state.product).map((value, key) => {
            return total_price += parseInt( value[0].price);
        })

        //cart item remove
        const remove = (e) => {
            e.preventDefault();
            const cartCount = e.currentTarget.getAttribute('data')
            const code_item = Object.values(this.state.product).filter((item, key) => {
                return key !== parseInt(cartCount);
            });
            this.setState(
                { product: [...code_item] },
                () => this.props.addToCart(this.state)
            )
            toast.error("Cart Item deleted!");
        }
        // cart item controle panel end

        return (
            <Fragment>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent22" aria-controls="navbarSupportedContent22" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="la la-bars"></span>
                </button>
                <div className="collapse navbar-collapse order-md-1 justify-content-start" id="navbarSupportedContent22">
                    <div className="m-right-15" style={{margin: 'auto'}}>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                              <NavLink className="nav-link" to="/services">Services</NavLink>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="https://trumarks.org">Trumarks<sup>TM</sup></a>
                            </li>
                            <li className="nav-item">
                              <NavLink className="nav-link" to="/about">About</NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink className="nav-link" to="/contact">Contact</NavLink>
                            </li>
                            {/* <!-- end: .navbar-nav --> */}
                        </ul>
                    </div>
                </div>



            </Fragment>

        )
    }
}

const mapStateToProps = state => {
    return {
        product : state.product,
        cart : state.cart
    }
}

const mapDispatchToProp = dispatch => {
    return {
        addToCart : (prod) => dispatch(Cart(prod))
    }
}

export default connect(mapStateToProps, mapDispatchToProp)(NavbarItem);

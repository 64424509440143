import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';

function sendEmail(source, data) {
  return fetch("/.netlify/functions/" + source, {
    method: "post",
    body: JSON.stringify(data)
  })
}

export class ContactForm5 extends Component {
    constructor (props) {
        super (props)
        this.state = {
            email:'',
            name: '',
            phone: '',
            message: ''
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state)

                var success = true;
                return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }
        return (
            <section className={this.props.padding ? this.props.padding : "p-top-100 p-bottom-110"}>
                <section className="form-wrapper contact--from5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="divider text-center m-bottom-50">
                                    <h1 className="color-dark m-0">Request A Call Back</h1>
                                </div>
                                <div className="form-wrapper">
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 m-bottom-30">
                                                <input type="text" value={this.state.name} onChange={this.setStateFromInput} placeholder="Your Name" name="name" className="form-control" />
                                                <div className="text-danger">{this.validator.message('Name', this.state.name, 'required|string')}</div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 m-bottom-30">
                                                <input type="text" placeholder="Phone Number" value={this.state.phone} onChange={this.setStateFromInput} name="phone" className="form-control" />
                                                <div className="text-danger">{this.validator.message('Phone', this.state.phone, 'required|phone')}</div>
                                            </div>
                                            <div className="col-lg-4 m-bottom-30">
                                                <input type="email" placeholder="Email" value={this.state.email} onChange={this.setStateFromInput} name="email" className="form-control" />
                                                <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div>
                                            </div>
                                            <div className="col-lg-12 m-bottom-20">
                                                <textarea className="form-control" value={this.state.message} onChange={this.setStateFromInput} name="message" rows="7" placeholder="Message" required></textarea>
                                            <div className="text-danger">{this.validator.message('Message', this.state.message, 'required|string')}</div>
                                            </div>
                                            <div className="col-lg-12 text-center m-top-30">
                                                <button onClick={Shipping_Address} className="btn btn-primary">Request Now</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>{/*<!-- end: .form-wrapper -->*/}
                            </div>
                        </div>
                    </div>
                </section>{/*<!-- ends: .form-wrapper -->*/}
            </section>
        )
    }
}


export class ContactForm extends Component {
    constructor (props) {
        super (props)
        this.state = {
            email:'',
            name: '',
            phone: '',
            message: ''
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                sendEmail('sendEmail', this.state)
                this.setState({
                  success: true
                })
                var success = true;
                return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }
    return (
        <div className="form-wrapper">
            <div className="m-bottom-10">
                <div className="divider divider-simple text-left">
                    <h3>Let&#39;s Get In Touch</h3>
                </div>
            </div>
            <p className="m-bottom-30">Questions about our services? Send us a note and we'll be in touch soon.</p>
            {this.state.success ? (
              <h3>Your note was sent to us. Thank you!</h3>
            ) : (
              <form action="#" id="contact" onSubmit={Shipping_Address}>
                  <input type="text" name="name" value={this.state.name} onChange={this.setStateFromInput} className="form-control form-outline mb-4" placeholder="Name" />
                  <div className="text-danger">{this.validator.message('Name', this.state.name, 'required|string')}</div>
                  <input type="email" name="email" value={this.state.email} onChange={this.setStateFromInput} className="form-control form-outline mb-4" placeholder="Email" />
                  <div className="text-danger">{this.validator.message('Email', this.state.name, 'required|string')}</div>
                  <textarea name="message" value={this.state.message} onChange={this.setStateFromInput} className="form-control form-outline mb-4" placeholder="Message" required></textarea>
                  <div className="text-danger">{this.validator.message('Message', this.state.message, 'required|string')}</div>
                  <button  className="btn btn-primary" form="contact">Submit Now</button>
              </form>
            )}
        </div>
    )
}
}


export class GetInTouch extends Component {
        constructor (props) {
            super (props)
            this.state = {
                email:'',
                name: '',
                phone: '',
                message: ''
            }
            this.validator = new SimpleReactValidator();
            this.setStateFromInput = this.setStateFromInput.bind(this);
        }

        setStateFromInput = (event) => {
            var obj = {};
            obj[event.target.name] = event.target.value;
            this.setState(obj);
        }

        _isMounted = false;

        componentDidMount() {
            this._isMounted = true;
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render () {
            const Shipping_Address = (e) => {
                e.preventDefault()
                if (this.validator.allValid()) {
                    console.log(this.state);
                    var success = true;
                    return success;

                } else {
                    this.validator.showMessages();
                    this.forceUpdate();
                    var success2 = false;
                    return success2;
                }
            }

    return (
        <div className="container">
            <div className="row">
                <div className={this.props.classes ? this.props.classes : "col-lg-5 offset-lg-7"}>
                    <div className="form-wrapper">
                        <div className="m-bottom-10">
                            <div className="divider divider-simple text-left">
                                <h3>Let&#39;s Get In Touch</h3>
                            </div>
                        </div>
                        <p className="m-bottom-30">Investiga tiones demonstr averunt lectres legere me lius quod ii qua legunt saepius larias est etiam pro cessus.</p>
                        <form action="#">
                            <input type="text" value={this.state.name} onChange={this.setStateFromInput} name="name" className="form-control form-outline mb-4" placeholder="Name" />
                            <div className="text-danger">{this.validator.message('Name', this.state.name, 'required|string')}</div>
                            <input type="email" value={this.state.email} onChange={this.setStateFromInput} name="email" className="form-control form-outline mb-4" placeholder="Email" />
                            <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div>
                            <textarea value={this.state.message} onChange={this.setStateFromInput} className="form-control form-outline mb-4" name="message" placeholder="Messages"></textarea>
                            <div className="text-danger">{this.validator.message('Message', this.state.message, 'required|string')}</div>
                            <button onClick={Shipping_Address} className="btn btn-primary">Submit Now</button>
                        </form>
                    </div>{/*<!-- end: .form-wrapper -->*/}
                </div>
            </div>
        </div>
    )
    }
}

export class ContactForm6 extends Component {
    constructor (props) {
        super (props)
        this.state = {
            plan:'',
            name: '',
            message: ''
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state);
                var success = true;
                return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }
    return (
        <section className="form-wrapper section-bg py-5 bgimage bg-secondary contact--from6">
        <div className="bg_image_holder">
            <img src="./assets/img/contact-bg.png" alt="" />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 py-5">
                    <div className="mb-5 color-light">
                        <div className="divider text-center">
                            <h1 className="color-light">Request A Call Back</h1>
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <form action="#">
                            <div className="row">
                                <div className="col-md-4 m-bottom-20">
                                    <input type="text" value={this.state.name} onChange={this.setStateFromInput} name="name" placeholder="Your Name" className="form-control border-0" required />
                                    <div className="text-danger">{this.validator.message('Name', this.state.name, 'required|string')}</div>
                                </div>
                                <div className="col-md-4 m-bottom-20">
                                    <input type="text" value={this.state.phone} onChange={this.setStateFromInput} name="phone" placeholder="Phone Number" className="form-control border-0" required />
                                    <div className="text-danger">{this.validator.message('Phone', this.state.phone, 'required|phone')}</div>
                                </div>
                                <div className="col-md-4 m-bottom-20">
                                    <div className="form-group">
                                        <div className="select-basic">
                                            <select className="form-control border-0" value={this.state.plan} onChange={this.setStateFromInput} name="plan">
                                                <option>Business Planning</option>
                                                <option>Option 1</option>
                                                <option>Option 2</option>
                                                <option>Option 3</option>
                                                <option>Option 4</option>
                                            </select>
                                        </div>
                                    </div>
                                <div className="text-danger">{this.validator.message('Business Plan', this.state.plan, 'required|string')}</div>
                                </div>
                                <div className="col-lg-12 text-center m-top-0">
                                    <button onClick={Shipping_Address} className="btn btn-primary">Request Now</button>
                                </div>

                            </div>
                        </form>
                    </div>{/*<!-- end: .form-wrapper -->*/}
                </div>
            </div>
        </div>
    </section>
    )
    }
}

export const ContactForm3 = () => {
    return (
        <section className="form-wrapper section-bg contact--from3 p-top-100 p-bottom-110">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="m-bottom-65">
                        <div className="divider text-center">
                            <h1 className="color-dark">Request A Call Back</h1>
                            <p className="mx-auto d-none"></p>
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 m-bottom-20">
                                    <input type="text" placeholder="Your Name" className="form-control fc--rounded border-0" required />
                                </div>
                                <div className="col-lg-4 col-md-6 m-bottom-20">
                                    <input type="text" placeholder="Phone Number" className="form-control fc--rounded border-0" required />
                                </div>
                                <div className="col-lg-4 m-bottom-20">
                                    <div className="form-group">
                                        <div className="select-basic">
                                            <select className="form-control fc--rounded border-0">
                                                <option>Business Planning</option>
                                                <option>Option 1</option>
                                                <option>Option 2</option>
                                                <option>Option 3</option>
                                                <option>Option 4</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 text-center m-top-30">
                                    <button className="btn btn-primary btn--rounded">Request Now</button>
                                </div>
                            </div>
                        </form>
                    </div>{/*<!-- end: .form-wrapper -->*/}
                </div>
            </div>
        </div>
    </section>
    )
}

export const FormBox = (props) => {
    return (
        <div className={props.padding ? props.padding : "col-lg-4 offset-lg-1"}>
            <div className="form-box">
                <h4 className="color-dark">Have Any Question?</h4>
                <p className="m-0">Investig ationes demons trave rurnt lectores legere lius quod thlegunt saeps.</p>
                <form action="#" className="m-top-30">
                    <input type="text" className="form-control mb-4" placeholder="Your Name" />
                    <input type="email" className="form-control mb-4" placeholder="Your Email" />
                    <input type="text" className="form-control mb-4" placeholder="Your Question" />
                    <button className="btn btn-primary mt-3">Submit Now</button>
                </form>
            </div>
        </div>
    )
}

import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footerCurvedLight';
import { IconBlock } from '../content/element/addressBlock'
import { ContactForm } from '../content/element/form/contact-form';


const Contact = () => {
    return (
        <Fragment>

            <Header pageTitle='Contact Us'/>
            <section className="contact--four p-bottom-70">
                <IconBlock />
                <div className="container p-top-100 p-bottom-110">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xs-12">
                            <ContactForm />
                        </div>{/*<!-- ends: .col-lg-6 -->*/}
                    </div>
                </div>
            </section>{/*<!-- ends: .contact--four -->*/}
            <Footer />
        </Fragment>
    )
}

export default Contact;

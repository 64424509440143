import React from 'react';
import {NavLink} from 'react-router-dom';
const noAction = (e) => {
    e.preventDefault();
}

const FooterDark3 = () => {
    
    return (

        <footer className="footer3 footer--dark">
          <div className="footer__big">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget text_widget">
                    <img className="footer_logo" src="./assets/img/logo-white.png" alt="logo" />
                    <p>Nunc placerat mi id nisi interdum they mtolis. Praesient is pharetra justo ught scel
                      erisque the mattis lhreo quam nterdum mollisy.</p>
                    <div className="widget__social">
                      <div className="social social--small  ">
                        <ul className="d-flex flex-wrap">
                          <li><NavLink to="/demo" onClick={noAction} className="facebook"><span className="fab fa-facebook-f" /></NavLink></li>
                          <li><NavLink to="/demo" onClick={noAction} className="twitter"><span className="fab fa-twitter" /></NavLink></li>
                          <li><NavLink to="/demo" onClick={noAction} className="linkedin"><span className="fab fa-linkedin-in" /></NavLink></li>
                          <li><NavLink to="/demo" onClick={noAction} className="gplus"><span className="fab fa-google-plus-g" /></NavLink></li>
                        </ul>
                      </div>{/* ends: .social */}
                    </div>
                  </div>{/* ends: .widget */}
                </div>{/* ends: .col-lg-3 */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget--contact_info">
                    <h4 className="widget__title">contact info</h4>
                    <ul>
                      <li><span className="la la-headphones" />
                        <div className="info">
                          <p>800 567.890.576</p>
                          <small>Mon-Sat 8am - 18pm</small>
                        </div>
                      </li>
                      <li><span className="la la-envelope" />
                        <div className="info">
                          <p>support@Tejarat.com</p>
                          <small>Free enquiry</small>
                        </div>
                      </li>
                      <li><span className="la la-map-marker" />
                        <div className="info">
                          <p>Melbourne, Australia</p>
                          <small>95 South Park Avenue</small>
                        </div>
                      </li>
                    </ul>
                  </div>{/* ends: .widget */}
                </div>{/* ends: .col-lg-3 */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget--links">
                    <h4 className="widget__title">quick links</h4>
                    <ul className="links">
                      <li><NavLink to="/demo" onClick={noAction}>Business</NavLink></li>
                      <li><NavLink to="/demo" onClick={noAction}>Marketing</NavLink></li>
                      <li><NavLink to="/demo" onClick={noAction}>Management</NavLink></li>
                      <li><NavLink to="/demo" onClick={noAction}>Accounting</NavLink></li>
                      <li><NavLink to="/demo" onClick={noAction}>Training</NavLink></li>
                      <li><NavLink to="/demo" onClick={noAction}>Consultation</NavLink></li>
                    </ul>
                  </div>{/* ends: .widget */}
                </div>{/* ends: .col-lg-3 */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget--latest-news">
                    <h4 className="widget__title">Latest News</h4>
                    <ul>
                      <li>
                        <small>April 28, 2019</small>
                        <NavLink to="/demo" onClick={noAction}>Global Automative Market Grow to $600 Billion</NavLink></li>
                      <li>
                        <small>April 28, 2019</small>
                        <NavLink to="/demo" onClick={noAction}>Global Automative Market Grow to $600 Billion</NavLink></li>
                    </ul>
                  </div>{/* ends: .widget */}
                </div>{/* ends: .col-lg-3 */}
              </div>
            </div>
          </div>
          <div className="footer__small text-center">
            <p>©2019 Tejarat. All rights reserved. Created by <NavLink onClick={noAction} to="/demo">AazzTech</NavLink></p>
          </div>
        </footer>
      );
}
export default FooterDark3;